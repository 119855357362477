import React, { useEffect, useState, useRef } from 'react';
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { TemplateContainer } from "./Marketing.styled";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { EmailTemplateWrapper } from "../../BlastCampaigns/BlastCampaignConfiguration.styled";
import { SuccessfulModal } from '../../../../elements';

const Message = observer(() => {
  const { authStore, templateEditorStore, marketingStore, contactSegmentStore } = useStore();
  const [exportTemplate, setExportTemplate] = useState();
  const initialLoadRef = useRef(false);
  const emailEditorRef = useRef();

  // Load initial template content
  useEffect(() => {
    const loadInitialContent = async () => {
      try {
        if (!initialLoadRef.current && templateEditorStore.templateId) {
          const emailPayload = marketingStore.getEmailPayload();
          const bodyField = emailPayload.find(item => item.field === "body");
          
          if (bodyField?.value) {
            const content = await marketingStore.getEmailTemplateContent(bodyField.value);
            if (content && !marketingStore.mainEmailContent) {
              marketingStore.setMainEmailContent(content);
            }
            initialLoadRef.current = true;
          }
        }
      } catch (error) {
        // Keeping error handling but removing console.error
      }
    };

    loadInitialContent();
  }, [templateEditorStore.templateId, marketingStore]);

  // Handle template editor initialization
  useEffect(() => {
    if (templateEditorStore.templateId) {
      templateEditorStore.loadTemplate();
    }

    return () => {
      templateEditorStore.updateGeneralField("isLoading", false);
      templateEditorStore.updateGeneralField("initialLoading", true);
    };
  }, [templateEditorStore.templateId]);

  useEffect(() => {
    if (exportTemplate) {
      marketingStore.setEmailTemplateExportAction(exportTemplate);
    }
  }, [exportTemplate, marketingStore]);

  const onExportEditor = async (htmlContent) => {
    try {
      // Store both the content and design first
      marketingStore.setMainEmailContent(htmlContent);
      
      // Get and store the current design
      if (emailEditorRef.current?.editor) {
        const design = await emailEditorRef.current.editor.saveDesign();
        marketingStore.setCurrentDesign(design);
      }

      // First save to backend
      marketingStore.updateBindingsAndPayload(
        templateEditorStore.payload.payload,
        templateEditorStore.payload.binding,
      );

      await marketingStore.save(
        authStore.selectedAccount.id,
        contactSegmentStore.advancedFilters,
        contactSegmentStore.totalRowCount
      );
      
      // Force reload with the saved content
      if (emailEditorRef.current?.editor) {
        await emailEditorRef.current.editor.loadDesign({
          html: marketingStore.mainEmailContent,
          design: marketingStore.currentDesign
        });
      }

      // Reset loading states
      templateEditorStore.updateGeneralField("isLoading", false);
      templateEditorStore.updateGeneralField("initialLoading", false);

    } catch (error) {
      marketingStore.setError(`Error saving template:', ${error}`);
    }
  };

  return (
    <TemplateContainer>
      <EmailTemplateWrapper>
        <TemplateEditor
          ref={emailEditorRef}
          isPopup={false}
          onExport={onExportEditor}
          exportAction={setExportTemplate}
          initialContent={marketingStore.mainEmailContent}
          design={marketingStore.currentDesign || {
            body: {
              rows: [{
                cells: [1],
                columns: [{
                  contents: [{
                    type: "text",
                    values: {
                      text: marketingStore.mainEmailContent || ''
                    }
                  }]
                }],
                values: {}
              }],
              values: {
                backgroundColor: "#ffffff",
                width: "600px",
                padding: "20px"
              }
            }
          }}
          skipTemplateLoad={!!marketingStore.mainEmailContent}
          key={`main-message-${templateEditorStore.templateId}-${!!marketingStore.mainEmailContent}`}
          onLoad={() => {}}
        />
      </EmailTemplateWrapper>
      {marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.clearError()}
          title="Error"
          subtitle={marketingStore.errorMessage}
          subtitle2="Please try again later."
        />
      )}
    </TemplateContainer>
  );
});

export default Message;

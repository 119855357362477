import { useState } from 'react';
import { FIELD_VALIDATIONS, VALIDATION_MESSAGES } from "../../../../../../utils/enums";

export const useContactValidation = (contactSegmentStore) => {
  const [validationErrors, setValidationErrors] = useState([]);

  const validateFields = () => {
    const errors = [];
    const parsedData = contactSegmentStore.parsedContactList;
    const systemFields = contactSegmentStore.defaultSystemFields;
    const fileFields = contactSegmentStore.defaultFileFields;

    if (!systemFields?.length || !fileFields?.length) {
      errors.push(VALIDATION_MESSAGES.NO_MAPPINGS);
      return errors;
    }

    if (!parsedData?.length) {
      errors.push(VALIDATION_MESSAGES.NO_DATA);
      return errors;
    }

    // Create a map of the mapped columns
    const mappedFields = fileFields.reduce((acc, fileField) => {
      const systemField = systemFields.find(sys => sys.index === fileField.index);
      if (systemField && fileField.value) {
        acc[systemField.mapping] = {
          csvColumn: fileField.value,
          displayName: systemField.name
        };
      }
      return acc;
    }, {});

    // Check for columns with headers but no data
    const columnsWithData = new Set();
    parsedData.forEach(row => {
      Object.keys(row).forEach(column => {
        if (row[column] && row[column].trim() !== '') {
          columnsWithData.add(column);
        }
      });
    });

    // Filter out columns that have no data
    const filteredMappedFields = Object.keys(mappedFields).reduce((acc, fieldType) => {
      const fieldInfo = mappedFields[fieldType];
      if (columnsWithData.has(fieldInfo.csvColumn)) {
        acc[fieldType] = fieldInfo;
      }
      return acc;
    }, {});

    if (!filteredMappedFields.email) {
      errors.push(VALIDATION_MESSAGES.EMAIL_REQUIRED);
      return errors;
    }

    // Use Maps and Sets to keep track of unique errors
    const uniqueFieldErrors = new Map();
    const requiredFieldErrors = new Set();

    // Filter empty rows before validating
    const validRows = parsedData.filter(row => {
      return Object.values(row).some(value => value && value.trim && value.trim() !== '');
    });

    // Check for empty values and invalid formats
    validRows.forEach((row) => {
      const emailValue = row[filteredMappedFields.email.csvColumn];
      // Only validate if the row has data
      if (!emailValue || emailValue.trim() === "") {
        requiredFieldErrors.add(filteredMappedFields.email.displayName);
      } else if (!FIELD_VALIDATIONS.email.pattern.test(emailValue)) {
        uniqueFieldErrors.set(filteredMappedFields.email.displayName, {
          name: filteredMappedFields.email.displayName,
          error: FIELD_VALIDATIONS.email.error
        });
      }

      // Validate the rest of the mapped fields
      Object.entries(filteredMappedFields).forEach(([fieldType, fieldInfo]) => {
        if (fieldType === 'email') return; // Skip email as it's already validated

        const validation = FIELD_VALIDATIONS[fieldType];
        if (!validation) return;

        const value = row[fieldInfo.csvColumn];

        // Validate patterns only if there is a value
        if (value && value.trim() !== "" && validation.pattern && !validation.pattern.test(value)) {
          uniqueFieldErrors.set(fieldInfo.displayName, {
            name: fieldInfo.displayName,
            error: validation.error
          });
        }
      });
    });

    // Only add errors if there are valid rows with problems
    if (requiredFieldErrors.size > 0 && validRows.length > 0) {
      errors.push({
        type: 'required',
        fields: Array.from(requiredFieldErrors)
      });
    }

    if (uniqueFieldErrors.size > 0) {
      errors.push({
        type: 'invalid',
        fields: Array.from(uniqueFieldErrors.values())
      });
    }

    return errors;
  };

  const clearValidationErrors = () => {
    setValidationErrors([]);
  };

  return {
    validationErrors,
    setValidationErrors,
    validateFields,
    clearValidationErrors
  };
};

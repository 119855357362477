import { useState } from "react";
import Icon from "../../../../../../elements/icon/Icon";
import SimpleSelect from "../../../../../../elements/v2/Selects/SimpleSelect"
import { 
    PermissionCardWrapper,
    PermissionSubtitle,
    PermissionText,
    PermissionTextHeader,
    PermissionCardContainer,
    PermissionLevelsContainer,
    PermissionDescription,
    PermissionLevel,
    PermissionTag
} from "./PermissionCard.styled"
import { observer } from "mobx-react";
import useStore from '../../../../../../hooks/useStore';
import { ChildPermissionWrapper } from "../PermissionNavbar/PermissionNavbar.styled";
import { PermissionsScopes } from "../../../../../../store/users/PermissionsSetupStore";
import { useMediaQueries } from "../../../../../../utils/mediaQueries";
import { CapitalizeFirstLetter } from "../UserComponents.Styled";


const PermissionCard = observer(({data, reviewMode=true}) => {

    const { permissionsSetupStore } = useStore();
    const { isMobile, isTablet, isDesktop } = useMediaQueries();

    const [showNested, setShowNested] = useState({});
    const toggleNested = (name) => {
      setShowNested({ ...showNested, [name]: !showNested[name] });
    };

    const accessLabel = (permissions) => {

        if(!permissions) return
        const permissionsValues = Object.values(permissions)
        if(permissionsValues.every(permission => permission === 'none')){
            return (<PermissionTag $access="N">No Access</PermissionTag>)
        } else if(permissionsValues.includes('none')) {
            return (<PermissionTag $access="P">Partial Access</PermissionTag>)
        }

        return (<PermissionTag $access="F">Full Access</PermissionTag>)
    }

    return (
        <>
        {permissionsSetupStore.permissionsLoaded && data?.map((permission, index) => (
            <>
                <PermissionCardWrapper key={index} $reviewMode={reviewMode} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
                    <PermissionCardContainer>
                        <PermissionDescription onClick={() => toggleNested(permission.name)} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
                            {permission.children.length > 0 && 
                                <Icon name={!showNested[permission.name] ? 'arrowDown' : 'arrowUp'}  width="24px" height="24px"/>
                            }
                            <PermissionText>
                                    <PermissionTextHeader isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}><CapitalizeFirstLetter>{permission.name}</CapitalizeFirstLetter>
                                        {Object.values(permissionsSetupStore.intermediatePermissionsStructure).length && 
                                            accessLabel(permissionsSetupStore.intermediatePermissionsStructure[permission.code]["set_permissions"]) 
                                        }
                                    </PermissionTextHeader>
                                {!reviewMode && <PermissionSubtitle isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>{permission.description}</PermissionSubtitle>}
                            </PermissionText>
                        </PermissionDescription>
                        <PermissionLevelsContainer $reviewMode={reviewMode}>
                            {permission.permission_levels.map(level => (
                                <PermissionLevel>
                                    <span>{level.toLowerCase()} {reviewMode && `(${permissionsSetupStore.intermediatePermissionsStructure[permission.code]["set_permissions"][level]})`}</span>
                                    {!reviewMode && <SimpleSelect 
                                        placeholder="None" 
                                        options={permissionsSetupStore.intermediatePermissionsStructure[permission.code]["available_scopes"]?.[level] || PermissionsScopes} 
                                        handleSelect={(name, value) => permissionsSetupStore.setPermissionLevels(permission, level, value, true)} 
                                        selectedDefault={permissionsSetupStore.intermediatePermissionsStructure[permission.code]["set_permissions"][level]}
                                    />}
                                </PermissionLevel>
                            ))}
                        </PermissionLevelsContainer>
                    </PermissionCardContainer>
                </PermissionCardWrapper>
                {permission.children &&
                    <ChildPermissionWrapper $display={!showNested[permission.name]}>
                        <PermissionCard data={permission.children} reviewMode={reviewMode}/>
                    </ChildPermissionWrapper>
                }
            </>
        ))}
        </>
    );
})

export default PermissionCard
import  NotificationList from "./NotificationList";
import { Route, Routes } from "react-router-dom";

const NotificationPage = () => {

  return (
    <Routes>
      <Route index element={<NotificationList />} />
    </Routes>
  );
};

export default NotificationPage;

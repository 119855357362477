import styled from "styled-components";

const RuleSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background: ${(props) => props.theme.colors.primaryWhite};
  padding: 0 8px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 35px;
    padding: 0 6px;
  }
`;

const RuleSelectedOptionWrapper = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-weight: bold;
    margin-left: 5px;
    color: ${(props) => props.theme.colors.black};
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;

    span {
      font-size: 0.75rem;
    }
  }
`;

const RuleSelectorClearIcon = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.gray};
  cursor: pointer;
  margin-left: 2px;

  &:hover {
    color: ${(props) => props.theme.colors.darkGray};
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin-left: 1px;
  }
`;

const RuleSelectorIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: ${(props) => props.theme.colors.darkGray};
  }

  @media (max-width: 768px) {
    margin-left: 3px;
  }
`;

const RuleSelectorOptionsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;

  @media (max-width: 768px) {
    max-height: 150px;
  }
`;

const RuleSelectorOptionsList = styled.div`
  padding: 8px 0;

  p {
    padding: 8px 12px;
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.darkGray};
    cursor: pointer;

    &:hover {
      background: ${(props) => props.theme.colors.lightGray};
    }

    @media (max-width: 768px) {
      padding: 6px 10px;
      font-size: 0.75rem;
    }
  }
`;

export {
  RuleSelectWrapper,
  RuleSelectedOptionWrapper,
  RuleSelectorClearIcon,
  RuleSelectorIconWrapper,
  RuleSelectorOptionsWrapper,
  RuleSelectorOptionsList,
};

import React, { useState } from "react";
import { useStore } from "../../../hooks";
import { observer } from "mobx-react";
import UserData from "./components/UserData";
import Tabs from "../../../elements/v2/Tabs/Tabs";
import GroupData from "./components/GroupData";
import { Icon, RoundedButton } from "../../../elements";
import ModalFilter from "./components/ModalFilter/ModalFilter";
import {
  CustomGrid, CustomSearchInput,
  InputField, SearchIcon,
  StyledIconButton, DataContainer,
  CustomGenericListStyle,
  DividerLine, VerticalDividerline,
  MainContainer, MainTitleContainer
} from "./components/UserData.styled";
import {useNavigate} from "react-router-dom";
import GroupCreateModal from "../GroupsPage/components/GroupCreateModal/GroupCreateModal";
import GroupsTable from "../GroupsPage/components/GroupsTable/GroupsTable";

const UserList = observer(() => {
  const { userStore } = useStore();
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const [role, setRole] = useState('');
  

  userStore.setPage('list');


  const tabs = [
    {
      label: 'Users',
      content: (
        <UserData searchValue={searchValue} searchByRole={role}/>
      ),
    },
    // {
    //   label: 'Groups',
    //   content: (
    //     <GroupsTable searchValue={searchValue} />
    //   ),
    // },
  ];

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handleCloseModalFilter = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };


  const handleClickTuneIcon = (event) => {
    if (openFilter) {
      handleCloseModalFilter();
    } else {
      setAnchorEl(event.currentTarget);
      setOpenFilter(true);
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const handleRoleChangeInModal = (selectedRole) => {
    setRole(selectedRole);
  };
  return (
    <div>
      {selectedTab === 0 && (
        <MainContainer>
          <MainTitleContainer><h4>Users</h4></MainTitleContainer>
          <CustomGrid>
            <CustomSearchInput width='50px'>
              <InputField
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
              />
              <SearchIcon>
                <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
              </SearchIcon>
            </CustomSearchInput>

            <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" onClick={handleClickTuneIcon}>
              <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
            </StyledIconButton>

            <ModalFilter
              openFilter={openFilter}
              anchorEl={anchorEl}
              handleCloseModalFilter={handleCloseModalFilter}
              onRoleChange={handleRoleChangeInModal}
              title="Filter"
              modalWidth="250"
            />

            <VerticalDividerline/>

            <RoundedButton width={'150px'}
                           onClick={() => {navigate('/v2/users/new')}}>
              New User
            </RoundedButton>
            <CustomGenericListStyle />
          </CustomGrid>
        </MainContainer>
      )}

      {selectedTab === 1 && (
        <MainContainer>
          <MainTitleContainer><h4>Users</h4></MainTitleContainer>
          <CustomGrid>
          <CustomSearchInput width='50px'>
            <InputField
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            />
            <SearchIcon>
              <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
            </SearchIcon>
          </CustomSearchInput>

          <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" >
            <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
          </StyledIconButton>

          <VerticalDividerline/>

          <RoundedButton width={'150px'} onClick={handleOpenModal}>
            New Group
          </RoundedButton>

          <CustomGenericListStyle />
        </CustomGrid>
        </MainContainer>
      )}

      <CustomGenericListStyle />

      <DataContainer id="user-table-container">
        <Tabs
          tabs={tabs}
          onToggleTab={index => handleTabChange(index)}
          showBorder={true}
        />
      </DataContainer>

      <DividerLine />

      <GroupCreateModal open={openModal} handleClose={handleCloseModal} />
    </div>
  );
});

export default UserList;

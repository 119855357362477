import React, { useState, useMemo } from "react";
import { observer } from 'mobx-react';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useStore } from "../../../../../hooks";
import { CardContainer, PaginationContainer, AgentListWrapper, PageSizeContainer, DisplayRecordsWrapper,NoAgentsContainer } from "./AgentTabs.styled";
import { Loading } from "../../../../../components/loading";
import ModalAgentsDetail from "../../components/ModalAgentsDetail/ModalAgentsDetail";
import LogoAnn from "../../../../../elements/icon/assets/logo_ann_customer.svg";
import AgentCard from "../../../AgentsList/components/AgentCard";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { useMediaQueries }  from "../../../../../utils/mediaQueries"
import Spinner from "../../../../../components/common/Spinner";
import { Icon } from "../../../../../elements/icon"



const AgentsTabs = observer(() => {
    const navigate = useNavigate();
    const { agentStore } = useStore();
    const { viewAgentStore } = agentStore;
    const { isMobile, isTablet } = useMediaQueries();
    const hasStatusFilter = viewAgentStore.statusFilter && viewAgentStore.statusFilter.length > 0;
    const hasScopeFilter = viewAgentStore.scopeFilter && viewAgentStore.scopeFilter.length > 0;
    const hasModifiedFilter = viewAgentStore.modifiedFilter && viewAgentStore.modifiedFilter.length > 0;

  const isWithinDateRange = (modifiedDate, filterOption) => {
    const now = dayjs();
    const lastModified = dayjs(modifiedDate);
    const dateFilters = {
      'last 7 days': () => now.subtract(7, 'day'),
      'last 30 days': () => now.subtract(30, 'day'),
      'last 90 days': () => now.subtract(90, 'day'),
      'this year': () => now.startOf('year')
    };

    return dateFilters[filterOption]
      ? lastModified.isAfter(dateFilters[filterOption]())
      : true;
  };

  const agentsFiltered = useMemo(() => {
    let filteredAgents = agentStore.agents
      .filter(assistant => assistant.provider === 'openai');

    if (viewAgentStore.searchValue) {
      filteredAgents = filteredAgents.filter(assistant =>
        assistant.name.toLowerCase().includes(viewAgentStore.searchValue.toLowerCase())
      );
    }

    if (hasStatusFilter) {
      filteredAgents = filteredAgents.filter(assistant =>
          viewAgentStore.statusFilter.includes(assistant.status)
      );
    }

    if (hasScopeFilter) {
      filteredAgents = filteredAgents.filter(assistant =>
          viewAgentStore.scopeFilter.includes(assistant.scope)
      );
    }

    if (hasModifiedFilter) {
      filteredAgents = filteredAgents.filter(assistant =>
        viewAgentStore.modifiedFilter.some(filterOption =>
          isWithinDateRange(assistant.last_modified, filterOption.toLowerCase())
        )
      );
    }

    return filteredAgents;
    }, [
      agentStore.agents,
      viewAgentStore.searchValue,
      viewAgentStore.statusFilter,
      viewAgentStore.scopeFilter,
      viewAgentStore.modifiedFilter
    ]);

    const totalPages = Math.ceil(agentsFiltered.length / viewAgentStore.itemsPerPage);

    const currentAgents = useMemo(() => {
        const startIndex = (viewAgentStore.currentPage - 1) * viewAgentStore.itemsPerPage;
        const endIndex = startIndex + viewAgentStore.itemsPerPage;
        return agentsFiltered.slice(startIndex, endIndex);
    }, [agentsFiltered, viewAgentStore.currentPage, viewAgentStore.itemsPerPage]);

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (assistant) => {
        const starters = assistant.conversation_starters.filter((starter)=>starter);
        const content = {
            id: assistant.id,
            image: assistant?.photo_url || LogoAnn,
            name: assistant.name,
            type: "Agent",
            description1: assistant.job_title,
            description2: assistant.prompt,
            conversations: 4,
            members: 1,
            starters: [],
            knowledgeBase: assistant.knowledge_base.map((filePath) =>
                filePath.replace("s3://", "").split("/").splice(-1).pop()),
            conversation_starters: starters || [],
            visibility: "draft",
            actions: assistant.action || [],
            original: assistant
        }
        agentStore.onSelectedAgent(content);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleEdit = (assistant) => {
        navigate({
            pathname: "/v2/assistants/playground",
            search: `agent=${assistant.id}&customer=${assistant.customer_id}`,
        });
    };

    const handleUse = (assistant) => {
        navigate({
            pathname: "/home",
            search: `agent=${assistant.id}&customer=${assistant.customer_id}`,
        });
    };

    return (
        <>

            <AgentListWrapper>
                <p>Agents {`(${agentsFiltered.length})`}</p>
            </AgentListWrapper>
            {agentStore.isLoading && <Spinner />}
            {!agentStore.isLoading && (
              agentsFiltered.length > 0 ? (
                <CardContainer isMobile={isMobile}>
                  {currentAgents.map((assistant, index) => (
                    <AgentCard
                      key={index}
                      avatar={assistant?.photo_url || LogoAnn}
                      title={assistant.name}
                      description={assistant.prompt || "..."}
                      updatedBy={"System"}
                      date={assistant.insert_date || "Unknown"}
                      onClick={() => handleOpenModal(assistant)}
                      onEdit={() => handleEdit(assistant)}
                      onUse={() => handleUse(assistant)}
                    />
                  ))}
                </CardContainer>
              ) : (
                <NoAgentsContainer>
                  <p>No Agents Found</p>
                  <p>Your filters returned no matches. Try adjusting or resetting them</p>
                  <Icon name={"noAgents"} width={'90px'} height={"88px"}/>
                </NoAgentsContainer>
              )
            )}
            {agentStore.selectedAgent && (
                <ModalAgentsDetail open={isModalOpen} onClose={handleCloseModal} />
            )}
            {totalPages > 0 && (
                <PaginationContainer isMobile={isMobile} isTablet={isTablet}>
                    <PageSizeContainer>
                        <>
                          <span>Show</span>
                          <Select
                            value={viewAgentStore.itemsPerPage}
                            onChange={(e) => viewAgentStore.onChangeItemsPerPage(e.target.value)}
                            size="small"
                            sx={{
                                mx: 1,
                                minWidth: 80,
                                height: '32px'
                            }}>
                            {viewAgentStore.availablePageSizes.map((size) => (
                                <MenuItem key={size} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                          </Select>
                          <span>Entries</span>
                        </>
                        <DisplayRecordsWrapper>
                          Displaying {viewAgentStore.currentPage} through {agentsFiltered.length - viewAgentStore.itemsPerPage * (viewAgentStore.currentPage - 1)} out of {agentsFiltered.length} Records
                        </DisplayRecordsWrapper>
                    </PageSizeContainer>

                    <Pagination
                        count={totalPages}
                        page={viewAgentStore.currentPage}
                        onChange={viewAgentStore.onChangePage}
                        color="primary"
                        size="large"
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        defaultPage={1}
                        siblingCount={0}
                        boundaryCount={0}
                    />
                </PaginationContainer>
            )}
        </>
    );
});

export default AgentsTabs;

import React from 'react'
import { DataAvaliableWrapper } from "./Widget.styled";
import {Icon} from "../../../../../elements";
const NoDataAvailable = ({text='data'}) => {
    return (
        <DataAvaliableWrapper id="dataAvaliableWrapper">
            <p>No {text} available</p>
            <p>
                No {text} is available for this card at the moment. Please check
                again later.
            </p>
            <p>
                <Icon name={"noDataAvailable"} width={"70px"} height={"68px"} />
            </p>
        </DataAvaliableWrapper>
    )
}

export default NoDataAvailable

import React from "react";

import { useStore } from "../../../../../hooks";
import ContactTable from "./ContactTable/ContactTable";

const ContactPage = () => {
  const { contactStore } = useStore();

  return (
    <>
      <ContactTable contactStore={contactStore} />
    </>
  );
};

export default ContactPage;

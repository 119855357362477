import styled from "styled-components";

export const ImageFiltered = styled.div`
  background-image: url(${(props) => props.srcImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 248px;
  height: 198px;
  border-radius: 8px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const ImagesFilteredContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 420px;
  flex-wrap: wrap;
  overflow-y: scroll;

  & > * {
    flex: 0 0 calc(20% - 10px);
    box-sizing: border-box;
  }
`;

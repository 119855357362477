import React from "react";
import {
  UnifiedContainer,
  Breadcrumb,
  ChatContainer,
  ChatWrapper,
  WidgetChatWrapperTablet,
  CustomGenericListStyle,
  WidgetContainerTablet,
} from "../BaseUnifiedProfilePage.styled";
import BaseUnifiedProfilePage from "../BaseUnifiedProfilePage";
import Assistance from "../components/Assistance/Assistance";
import GeneralInfo from "../components/Cards/GeneralInfo";
import GarageCard from "../components/Cards/GarageCard";
import Appointments from "../components/Cards/Appointments";
import Notification from "../components/Cards/Notification";
import RepairOrders from "../components/Cards/RepairOrders";
import Notes from "../components/Cards/Notes";

const UnifiedProfilePageTablet = () => {
  return (
    <BaseUnifiedProfilePage>
      <UnifiedContainer>
        <Breadcrumb>Contacts</Breadcrumb>
        <WidgetChatWrapperTablet>
          <WidgetContainerTablet>
            <GeneralInfo />
            <GarageCard />
            <Appointments />
            <RepairOrders />
            <Notification />
            <Notes/>
          </WidgetContainerTablet>
          <ChatContainer>
            <ChatWrapper>
              <Assistance />
            </ChatWrapper>
          </ChatContainer>
        </WidgetChatWrapperTablet>
        <CustomGenericListStyle />
      </UnifiedContainer>
    </BaseUnifiedProfilePage>
  );
};

export default UnifiedProfilePageTablet;

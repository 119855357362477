import { useEffect, useState } from "react";
import Icon from "../../../../../../elements/icon/Icon";
import { 
    PermissionCardWrapper,
    PermissionCardContainer,
    ChildPermissionWrapper
} from "./PermissionNavbar.styled"
import { observer } from "mobx-react";
import useStore from '../../../../../../hooks/useStore';
import { CapitalizeFirstLetter } from "../UserComponents.Styled";

const PermissionNavbar = observer(({data}) => {

    const { permissionsSetupStore } = useStore();

    const [showNested, setShowNested] = useState({});
    const toggleNested = (name) => {
      setShowNested({ ...showNested, [name]: !showNested[name] });
    };

    const clickPermissionCard = (permission) => {
        permissionsSetupStore.filterPermissionDisplayed(permission);toggleNested(permission.name)
    }

    return (
        <>
        {permissionsSetupStore.permissionsLoaded && data?.map((permission, index) => (
            <>
                <PermissionCardWrapper key={index} tabIndex={0} onClick={() => clickPermissionCard(permission)}>
                    <PermissionCardContainer>
                        <CapitalizeFirstLetter>{permission.name}</CapitalizeFirstLetter> 
                        {permission.children.length > 0 && 
                            <Icon name={!showNested[permission.name] ? 'arrowDown' : 'arrowUp'} height="14px" width="14px"/>
                        }
                    </PermissionCardContainer>
                </PermissionCardWrapper>
                {permission.children &&
                    <ChildPermissionWrapper $display={!showNested[permission.name]}>
                        <PermissionNavbar data={permission.children}/>
                    </ChildPermissionWrapper>
                }
            </>
        ))}
        </>
    );
})

export default PermissionNavbar
import React from 'react';
import {
    ModalWrapper,
    ModalSubtitle,
    ModalTitle,
    ModalButtonsContainer,
    Backdrop,
    ModalContainer,
    DetailsContainer,
    Divider,
    ModalDescription,
    DetailCell,
    NameContainer,
    DateContainer,
    ModalCloseButton
} from "./DetailModal.styled";
import { RoundedButton } from '../../../button';
import { Icon } from '../../../';
import XIcon from '../../../icon/assets/x_icon.svg';

const DetailModal = ({ isOpen, onClose, selectedDocument }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen}>
            <ModalWrapper>
                <ModalCloseButton src={XIcon} width={12} height={12} onClick={() => handleClose()} />
                <ModalContainer>
                    <Icon height="32px" width="32px" name="fileV2"/>
                    <ModalTitle>{selectedDocument?.document}</ModalTitle>

                    <DetailsContainer>
                        <DetailCell>
                           <ModalSubtitle>Owner</ModalSubtitle> 
                           <NameContainer>
                                <span>{selectedDocument?.owner?.[0]}</span>
                                <p>{selectedDocument?.owner}</p>
                            </NameContainer> 
                        </DetailCell>
                        <DetailCell><Divider/></DetailCell>
                        <DetailCell>
                            <ModalSubtitle>Last update date</ModalSubtitle> 
                            <DateContainer>
                                <Icon height="18px" width="18px" name="calendar"/>
                                <p>{selectedDocument?.date}</p>
                            </DateContainer> 
                        </DetailCell>
                    </DetailsContainer>

                    {/* <ModalButtonsContainer>
                        <RoundedButton
                            weight='400'
                            width={'180px'}
                            borderRadius={'8px'}
                            kind={'primary'}
                            onClick={() => null}
                            margin={'32px 0 0'}
                            icon={{element: <Icon height="16px" width="16px" name="download"/>, position: 'left'}}
                        >
                            Download
                        </RoundedButton>
                    </ModalButtonsContainer> */}
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default DetailModal;

import React from "react";
import dayjs from "dayjs";
import { Avatar, Box } from "@mui/material";
import { StyledCard, TruncatedDescription, Footer, Actions, SemiBoldTitle } from "./AgentCard.styled";
import { RoundedButton } from "../../../../elements";

const AgentCard = ({ avatar, title, description, updatedBy, date, onEdit, onUse, onClick }) => {
  return (
    <StyledCard onClick={onClick}>
      {/* Header */}
      <Box display="flex" alignItems="center">
        <Avatar src={avatar} alt={title} sx={{ width: 30, height: 30, marginRight: "12px" }} />
        <Box display="flex" flexDirection="column">
          <SemiBoldTitle>
            {title}
          </SemiBoldTitle>
        </Box>
      </Box>

      {/* Description */}
      <TruncatedDescription>{description}</TruncatedDescription>

      {/* Footer */}
      <Footer>
        Updated by {updatedBy} | {date ? dayjs(date).format("MM/DD/YYYY") : "Unknown"}
      </Footer>

      {/* Buttons */}
      <Actions>
        <RoundedButton 
          width="58px"
          height="31px"
          kind="edit"
          borderRadius="8px"
          fontSize="14px" 
          fontWeight="0" 
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          Edit
        </RoundedButton>
        <RoundedButton 
          width="100px"
          height="31px"
          borderRadius="8px"
          fontSize="14px"
          fontWeight="0" 
          onClick={(e) => {
            e.stopPropagation();
            onUse();
          }}
        >
          Use Agent
        </RoundedButton>
      </Actions>
    </StyledCard>
  );
};

export default AgentCard;

import { StatusWrapper } from "../../TriggersTab/TriggerTab.styled";

export const columns = [
  {
    accessorKey: "contact_name",
    header: "Name",
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_company_name",
    header: "Company",
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_address",
    header: "Address",
    muiTableHeadCellProps: {
      align: 'center'
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_city",
    header: "City",
    muiTableHeadCellProps: {
      align: 'center'
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_postal_code",
    header: "Postal code",
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_phone",
    header: "Phone",
    muiTableHeadCellProps: {
      align: 'center'
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        fontSize: '12px'
      }
    },
  },
  {
    accessorKey: "contact_status",
    header: "Status",
    Cell: ({ cell }) => (
      <StatusWrapper className={cell.getValue()?.toLowerCase()}>
        {cell.getValue()}
      </StatusWrapper>
    ),
  },
];

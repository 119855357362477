import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
`;

export const LeftPanel = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? "99%" : "50%")};
  height: 100%;
  transition: width 0.3s ease-in-out;
`;

export const RightPanel = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? "0" : "50%")};
  height: 100%;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
`;

export const Divider = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ isCollapsed }) => (isCollapsed ? "99%" : "50%")};
  width: 2px;
  background-color: ${(props) => props.theme.colors.highClearGray};
  transition: left 0.3s ease-in-out;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  left: ${({ isCollapsed }) => (isCollapsed ? "calc(99% - 15px)" : "calc(50% - 15px)")};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: left 0.3s ease-in-out;
`;

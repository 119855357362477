import styled, { css } from "styled-components"

const PermissionCardWrapper = styled.div`
    padding: 8px;
    height: ${ props => props.$reviewMode ? '56px' : '122px'};
    background-color: ${props => props.theme.colors.silverGrayThree};
    border-bottom: 1px solid ${props => props.theme.colors.clearGray};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Inter", sans-serif;
`;

const PermissionCardContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-left: 10px;
    justify-content: space-between;
`;

const PermissionText = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

const PermissionTitle = styled.p`
    font-size: 1.125em;
    font-weight: 600;
    margin: 0;
    line-height: normal;
`;

const PermissionSubtitle = styled.p`
    font-size: 0.875em;
    font-weight: 400;
    margin: 0;
    line-height: normal;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 0.65em;
  `}

  ${(props) =>
    props.isTablet &&
    css`
      font-size: 0.70em;
  `}

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: 0.72em;
  `}
`;

const PermissionTextHeader = styled.div`
    display: flex;
    gap: 16px;

    ${(props) =>
      props.isMobile &&
      css`
        font-size: 0.93em;
    `}

    ${(props) =>
      props.isTablet &&
      css`
        font-size: 0.93em;
    `}

    ${(props) =>
      props.isDesktop &&
      css`
        font-size: 0.95em;
    `}
`;

const PermissionLevelsContainer = styled.div`
    display: flex;
    gap: ${ props => props.$reviewMode ? '24px' : '5px'};
    text-transform: capitalize;
    font-size: 0.875em;
`;

const PermissionDescription = styled.div`
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    ${(props) =>
      props.isMobile &&
      css`
        min-width: 220px;
    `}

    ${(props) =>
      props.isTablet &&
      css`
        min-width: 235px;
    `}

    ${(props) =>
      props.isDesktop &&
      css`
        min-width: 240px;
    `}
`;

const PermissionLevel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PermissionTag = styled.p`
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    line-height: normal;

    ${(props) => props.$access === "F" &&
    css`
        color: ${props => props.theme.colors.greenPrimary};
    `}
    ${(props) => props.$access === "P" &&
    css`
        color: ${props => props.theme.colors.clearBlue};
    `}
    ${(props) =>  props.$access === "N" &&
    css`
        color: ${props => props.theme.colors.clearGray};
    `}
`;

export {
    PermissionCardWrapper,
    PermissionTitle,
    PermissionSubtitle,
    PermissionText,
    PermissionTextHeader,
    PermissionCardContainer,
    PermissionLevelsContainer,
    PermissionDescription,
    PermissionLevel,
    PermissionTag
}
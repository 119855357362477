import {action, flow, makeObservable, observable} from "mobx";
import {API} from "aws-amplify";
import {getAlert, getContacts, getUCPData, getVehicleVinPictures,} from "../../graphql/queries";

class UnifiedCustomerProfileStore {
  ucp = {};
  typeUrl = "";
  notifications = [];
  garage = [];
  selectedCar = null;
  scheduledAppointments = [];
  orders = [];
  parts = [];
  contactInfo = [];
  garageInfo = [];
  repairOrdersInfo = [];
  appointmentsInfo = [];
  initialLoad = false;
  isLoading = false;
  isError = false;
  scrollPosition = 0;
  alerts = [];
  imageGallery = [];
  vehicleVinPictures = [];
  isLoadingVehicleImages = false;

  constructor() {
    makeObservable(this, {
      ucp: observable,
      initialLoad: observable,
      notifications: observable,
      garage: observable,
      selectedCar: observable,
      scheduledAppointments: observable,
      orders: observable,
      parts: observable,
      isLoading: observable,
      isError: observable,
      contactInfo: observable,
      garageInfo: observable,
      repairOrdersInfo: observable,
      appointmentsInfo: observable,
      typeUrl: observable,
      scrollPosition: observable,
      alerts: observable,
      imageGallery: observable,
      vehicleVinPictures: observable,
      isLoadingVehicleImages: observable,
      getUCP: flow,
      fetchUCPData: flow,
      fetchAlerts: flow,
      fetchVehicleVinPictures: flow,
      onClearUCP: action,
      getScheduledAppointments: action,
      getNotifications: action,
      getGarage: action,
      getOrders: action,
      getParts: action,
      setSelectedCar: action,
      setScrollPosition: action,
      changeUrl: action,
    });
  }

  onClearUCP() {
    this.ucp = {};
    this.notifications = [];
    this.garage = [];
    this.selectedCar = null;
    this.scheduledAppointments = [];
    this.orders = [];
    this.parts = [];
    this.contactInfo = [];
    this.garageInfo = [];
    this.initialLoad = false;
    this.isError = false;
    this.alerts = [];
    this.isLoadingVehicleImages = false;
    this.vehicleVinPictures = [];
  }

  *getUCP(customer, segment_id) {
    try {
      this.isLoading = true;
      const response = yield API.graphql({
        query: getContacts,
        variables: {
          input: { customer: customer, segment_id },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const data = JSON.parse(response.data?.getContacts?.body);
      if (!data.hasOwnProperty(segment_id)) throw new Error();

      this.ucp = data[segment_id];
      this.initialLoad = true;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  *fetchUCPData(customer_id, contact_id, card_name) {
    try {
      this.isLoading = true;
      const response = yield API.graphql({
        query: getUCPData,
        variables: {
          input: {
            customer_id: customer_id,
            contact_id: contact_id,
            cards: JSON.stringify([
              {
                card_name,
              },
            ]),
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const data = JSON.parse(response.data?.getUCPData?.body);
      if (!data.hasOwnProperty(card_name)) throw new Error();
      if (card_name === "card_contact_info") {
        this.contactInfo = data[card_name];
      } else if (card_name === "card_garage") {
        this.garageInfo = data[card_name];
        this.selectedCar = this.garageInfo[0];
      } else if (card_name === "card_orders") {
        this.repairOrdersInfo = data[card_name];
      } else if (card_name === "card_appointments") {
        this.appointmentsInfo = data[card_name];
      }
      this.initialLoad = true;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  getScheduledAppointments() {
    this.scheduledAppointments = this.selectedCar.appointments;
  }

  getNotifications() {
    this.notifications = this.ucp.notifications.map((notification, idx) => ({
      ...notification,
      id: idx + 1,
    }));
  }

  getGarage() {
    this.garage = this.ucp.cars.map((car) => ({
      ...car,
      complete_car_name: `${car.brand} ${car.model} ${car.year}`,
    }));
    this.selectedCar = this.garage[0];
  }

  getOrders() {
    this.orders = this.selectedCar.orders;
  }

  getParts() {
    if (this.selectedCar.parts) {
      this.parts = this.selectedCar.parts.map((part, idx) => ({
        ...part,
        id: idx + 1,
      }));
    }
  }

  setSelectedCar(idx, isNewVersion = false) {
    if (isNewVersion) {
      this.selectedCar = this.garageInfo[idx];
    } else {
      this.selectedCar = this.garage[idx];
    }
  }

  setScrollPosition(position) {
    this.scrollPosition = position;
  }

  changeUrl(url) {
    this.typeUrl = url;
  }

  *fetchAlerts(customer_id, assignee, status, last_evaluated_key) {
    try {
      this.isLoading = true;

      const response = yield API.graphql({
        query: getAlert,
        variables: {
          input: {
            customer_id: customer_id,
            assignee: "",
            status: status,
            last_evaluated_key: last_evaluated_key && null,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.alerts = JSON.parse(response.data?.getAlert?.body);
      this.initialLoad = true;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  *fetchVehicleVinPictures(vin) {
    try {
      this.isLoadingVehicleImages = true;
      const response = yield API.graphql({
        query: getVehicleVinPictures,
        variables: {
          input: {
            vin: vin,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const images = JSON.parse(response.data?.getVehicleVinPictures?.body);
      const imageUrls = images.map((image) => image.url);
      this.vehicleVinPictures = imageUrls;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoadingVehicleImages = false;
    }
  }
}

export default UnifiedCustomerProfileStore;

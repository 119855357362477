import React, { useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  BellContainer,
  ContentContainer,
  ContentBell,
  RightFunctions,
  NotificationItem,
  NotificationItemContainer,
  NotificationFooter,
  VisualSignaling,
} from "./Notifications.styled";
import Tabs2 from "../Tabs2/Tabs2";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { NameInitialsAvatar } from "react-name-initials-avatar";
import { ItemStatus, NotificationTab } from "../../../utils/enums";
import { Icon } from "../../icon";

const SYSTEM_NOTIFICACION = "annaims_notification";

const tabs = [
  {
    label: "Unread",
    permission: null,
  },
  {
    label: "Read",
    permission: null,
  },
];

const Notifications = observer((props) => {
  const { notificationStore, authStore } = useStore();
  const [currentTab, setCurrentTab] = useState(NotificationTab.UnreadTab);
  const navigate = useNavigate();

  const { setIsActive } = props;

  const users = useMemo(() => authStore.users, [authStore.users]);

  const onToggleTab = (currentTab) => {
    setCurrentTab(currentTab);
  };

  const handleViewAllNotifications = () => {
    navigate("v2/notifications/");
  };

  const redirectByModuleType = async (notification) => {
    const { metadata } = notification || {};
    const { module_type, id } = metadata || {};

    if (!module_type) return false;

    const navigateTo = (pathname, id) => {
      navigate({
        pathname,
        search: id ? createSearchParams({ id }).toString() : undefined,
      });
    };

    const routes = {
      conversation: () => navigateTo("/v2/conversations/marketing", id),
      contact: () =>
        navigateTo(id ? "/unified-profile" : "/v2/conversations", id),
    };

    const routeHandler = routes[module_type];
    if (routeHandler) routeHandler();

    if (notification.status === ItemStatus.unread) {
      await notificationStore.putReadNotification({
        user_id: notification.user,
        notification_id: [notification.id],
        customer_id: authStore.userData.customer,
      });
    }

    setIsActive(false);
  };

  const getItemsByStatus = (status) => {
    return notificationStore
      .getItemsBellByStatus(status)
      .map((notification) => {
        const user = users.find((user) => user.id === notification.user);

        const getFullName = () => {
          const hasFirstname = user?.first_name;
          const hasLastname = user?.last_name;

          if (hasFirstname && hasLastname)
            return `${user?.first_name} ${user?.last_name}`;

          return SYSTEM_NOTIFICACION;
        };

        return (
          <Notifications.Item
            key={notification.id}
            onClick={() => redirectByModuleType(notification)}
            isUnread={notification.status === ItemStatus.unread}
            name={getFullName()}
            description={notification.description}
            time={notification.time}
          />
        );
      });
  };

  return (
    <ContentContainer>
      <ContentBell>
        <Tabs2
          defaultTab={NotificationTab.UnreadTab}
          tabs={tabs}
          onToggleTab={onToggleTab}
        />
        {currentTab === NotificationTab.UnreadTab && (
          <RightFunctions>
            <span>Mark all as read</span>
            <OpenInNewIcon htmlColor="#545454" />
          </RightFunctions>
        )}
      </ContentBell>
      <div>
        <Tabs2.Outlet show={currentTab === NotificationTab.UnreadTab}>
          <NotificationItemContainer>
            {getItemsByStatus(ItemStatus.unread)}
          </NotificationItemContainer>
        </Tabs2.Outlet>

        <Tabs2.Outlet show={currentTab === NotificationTab.ReadTab}>
          <NotificationItemContainer>
            {getItemsByStatus(ItemStatus.read)}
          </NotificationItemContainer>
        </Tabs2.Outlet>
      </div>
      <NotificationFooter onClick={handleViewAllNotifications}>
        View all notifications
      </NotificationFooter>
    </ContentContainer>
  );
});

Notifications.Item = (props) => {
  const { isUnread, name, description, time, onClick } = props;

  const isAnnaims = name === SYSTEM_NOTIFICACION;
  const nameToShow = isAnnaims ? "Annaims Notification" : name;
  return (
    <NotificationItem
      isUnread={isUnread}
      isAnnaims={isAnnaims}
      onClick={onClick}
    >
      <div className="photo">
        {isAnnaims ? (
          <Icon name="iconColored" height={"32px"} width={"32px"} />
        ) : (
          <NameInitialsAvatar
            name={nameToShow}
            size="38px"
            bgColor={isAnnaims ? "#6d6d6d" : "#2173FF"}
            textColor="white"
            borderWidth="0"
          />
        )}
      </div>
      <div className="description">
        <div className="name">{nameToShow}</div>
        <div className="eventDescription">{description}</div>
        <div className="timeAgo">{time}</div>
      </div>
    </NotificationItem>
  );
};

const Bell = observer((props) => {
  const { notificationStore } = useStore();
  const [showVisualSignaling, setShowVisualSignaling] = useState(false);
  const { isActive, setIsActive } = props;
  const hasNotificationsUnreaded = notificationStore.getItemsBellByStatus(
    ItemStatus.unread
  ).length;

  useEffect(() => {
    setShowVisualSignaling(hasNotificationsUnreaded);
  }, [hasNotificationsUnreaded]);

  return (
    <BellContainer isActive={isActive} onClick={() => setIsActive(!isActive)}>
      {showVisualSignaling ? <VisualSignaling /> : ""}
      <NotificationsIcon size="12" color="white" />
    </BellContainer>
  );
});

Notifications.Bell = Bell;

export default Notifications;

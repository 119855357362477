import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

const LoadingCustomAvatar = styled.div`
  position: relative;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;

    transform: translate(-50%, -50%);
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-color: #e0e0e0 transparent #e0e0e0 transparent;
    animation: ${rotate} 1.2s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background-color: #e0e0e0;
    animation: ${pulse} 1.5s ease-in-out infinite;
  }
`;

const CustomAvatarContainer = styled.div`
  position: relative;
`;

const ImageSelectorWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageSelectorSettings = styled.div`
    width: 120px;
    height: 83px;
    background-color: ${props => props.theme.colors.primaryWhite};
    position: absolute;
    top: 80px;
    list-style: none;
    padding: 0;
    transition: all 0.5s;
    z-index: 4;
    font-size: ${props => props.theme.fonts.size.b2};

    li {
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${props => props.theme.colors.clearGray};
    }
`;

const ImageSelectorButton = styled.div`
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 2px dashed;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.highClearGray};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

export {
    ImageSelectorButton,
    ImageSelectorWrapper,
    ImageSelectorSettings,
    HiddenFileInput,
    CustomAvatarContainer,
    LoadingCustomAvatar
};
import {
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationEntriesContainer,
} from "./Pagination.styled";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ArrowDropDown, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { RoundedButton } from "../../../../../../button";

const Paginations = ({
  entries,
  setEntries,
  totalRowCount,
  visiblePages,
  currentPage,
  totalPages,
  handlePrev,
  isLoading,
  selectPage,
  handleNext,
  selectedCurrentPage,
}) => {
  return (
    <PaginationContainer>
      <PaginationEntriesContainer>
        <span>Show</span>
        <FormControl fullWidth sx={{ maxWidth: "100px" }}>
          <Select
            disabled={isLoading || !visiblePages.length}
            value={entries}
            onChange={(e) => setEntries(e.target.value)}
            displayEmpty
            sx={{ borderRadius: 0, padding: 0, height: 40 }}
            IconComponent={ArrowDropDown}
          >
            <MenuItem value={"10"}>10</MenuItem>
            <MenuItem value={"20"}>20</MenuItem>
            <MenuItem value={"30"}>30</MenuItem>
          </Select>
        </FormControl>
        <span>Entries</span>
        <span>
          Showing {entries} of {totalRowCount?.[0]?.count ?? "0"} Entries
        </span>
      </PaginationEntriesContainer>
      <PaginationButtonsContainer>
        {parseInt(totalRowCount?.[0]?.count) >= 1 && (
          <>
            <RoundedButton
              kind="outlined"
              disabled={currentPage === 1 || isLoading}
              onClick={handlePrev}
            >
              <ChevronLeft />
            </RoundedButton>
            {visiblePages?.map((page) => (
              <RoundedButton
                key={page}
                disabled={isLoading}
                outline
                kind={selectedCurrentPage === page ? "primary" : "secondary"}
                onClick={() => selectPage(page)}
              >
                {page}
              </RoundedButton>
            ))}
            <RoundedButton
              disabled={currentPage === totalPages || isLoading}
              onClick={handleNext}
              kind="outlined"
            >
              <ChevronRight />
            </RoundedButton>
          </>
        )}
      </PaginationButtonsContainer>
    </PaginationContainer>
  )
};

export default Paginations;

import { observer } from "mobx-react";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ChatBox from "./ChatBox";
import { AssistantWrapper, ChatInputWrapper } from "./Assistance.styled";
import { useStore } from "../../../../../hooks";
import Spinner from "../../../../../components/common/Spinner";
import ChatInput from "../../../../../elements/input/ChatInput";
import UnifiedProfileTabs from "../UnifiedProfileTabs/UnifiedProfileTabs";
import TimelineBox from "./TimelineBox";
import AISuggestionsPopup from "../Cards/AISuggestionsPopup";

const Assistance = observer(() => {
  const {
    authStore,
    unifiedCustomerProfileStore,
    annUnifiedCustomerProfileStore,
    assistanceUCPStore,
    conversationsStore,
  } = useStore();

  const { runID, threadID } = assistanceUCPStore;
  const { selectedAccount } = authStore;
  const textAreaRef = useRef();
  const suggestionsRef = useRef(null);
  const [activeTab, setActiveTab] = useState('Chat');

  useEffect(() => {
    assistanceUCPStore.setSelectedTab('Chat');
  }, []);

  const contactInfo = useMemo(
    () => unifiedCustomerProfileStore.contactInfo[0] || {},
    [unifiedCustomerProfileStore.contactInfo],
  );

  const isAnnUCP = useMemo(
    () => authStore.selectedAccount.metadata.domain === "ANN",
    [authStore.selectedAccount.metadata.domain],
  );

  const ucpData = useMemo(
    () =>
      isAnnUCP ? annUnifiedCustomerProfileStore : unifiedCustomerProfileStore,
    [isAnnUCP, annUnifiedCustomerProfileStore, unifiedCustomerProfileStore],
  );

  useEffect(() => {
    if (selectedAccount?.id) {
      assistanceUCPStore.cleanupConversation();
      assistanceUCPStore.fetchConciergeAgent(selectedAccount.id);
      conversationsStore.setCustomerId(selectedAccount.id);
    }
  }, [selectedAccount?.id, assistanceUCPStore, conversationsStore]);

  useEffect(() => {
    return () => {
      assistanceUCPStore.cleanupConversation();
    };
  }, [assistanceUCPStore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        assistanceUCPStore.setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assistanceUCPStore]);

  /**
   * Handle tab change event by setting the active tab state and
   * updating the selected tab in the assistance store.
   * @param {string} tabLabel - The label of the tab that was clicked.
   */
  const handleTabChange = useCallback(
    (tabLabel) => {
      setActiveTab(tabLabel);
      assistanceUCPStore.setSelectedTab(tabLabel);
    },
    [assistanceUCPStore],
  );

  /**
   * Sends a message to the Concierge Chat Assistant.
   * @param {string} message The message to send.
   * @returns {Promise<void>}
   */
  const handleSendMessage = useCallback(
    async (message) => {
      await assistanceUCPStore.handleSendMessage(
        message,
        contactInfo,
        selectedAccount,
        isAnnUCP,
        ucpData,
      );
    },
    [assistanceUCPStore, contactInfo, selectedAccount, isAnnUCP, ucpData],
  );

  /**
   * Stops the agent response.
   */
  const handleStopAgentResponse = useCallback(() => {
    conversationsStore.setRunId(runID);
    conversationsStore.setThreadId(threadID);
    if (
      assistanceUCPStore.agentResponseSubscription &&
      conversationsStore.runId &&
      conversationsStore.threadId &&
      conversationsStore.customerId
    ) {
      conversationsStore.stopResponse();
      assistanceUCPStore.agentResponseSubscription.unsubscribe();
      assistanceUCPStore.agentResponseSubscription = null;
      assistanceUCPStore.setAgentIsTyping(false);
      assistanceUCPStore.setShowSpinner(false);
      assistanceUCPStore.setIsSendMessageClicked(false);
      assistanceUCPStore.setShowStopButton(false);
    }
  }, [runID, threadID, assistanceUCPStore, conversationsStore]);

  const handleSuggestionSelect = useCallback(
    async (suggestion) => {
      assistanceUCPStore.setNewMessage(suggestion);
      assistanceUCPStore.setShowSuggestions(false);
      await handleSendMessage(suggestion);
    },
    [assistanceUCPStore, handleSendMessage],
  );

  const handleKeyPress = useCallback(
    (e, callback) => {
      if (e.key === "Enter" && !e.shiftKey && !assistanceUCPStore.showSpinner) {
        e.preventDefault();
        handleSendMessage();
        callback("");
      }
    },
    [assistanceUCPStore.showSpinner, handleSendMessage],
  );

  const handleFileUpload = useCallback(
    (attachment) => {
      assistanceUCPStore.setAttachment(attachment);
    },
    [assistanceUCPStore],
  );

  const handleCleanThread = useCallback(() => {
    assistanceUCPStore.cleanupConversation();
  }, [assistanceUCPStore]);

  const handleMessageChange = useCallback(
    (e) => {
      assistanceUCPStore.setNewMessage(e.target.value);
    },
    [assistanceUCPStore],
  );

  const handleCloseSuggestions = useCallback(() => {
    assistanceUCPStore.setShowSuggestions(false);
  }, [assistanceUCPStore]);

  const chatContent = useMemo(
    () => (
      <AssistantWrapper>
        {assistanceUCPStore.showSpinner && <Spinner className="spinner" />}
        <ChatBox
          messages={assistanceUCPStore.conversationHistory}
          agentIsTyping={assistanceUCPStore.agentIsTyping}
          agentAvatar={assistanceUCPStore?.conciergeAgent?.photo_url}
        />
        <ChatInputWrapper id="chat-wrapper">
          {assistanceUCPStore.showSuggestions && (
            <div ref={suggestionsRef} className="suggestions-container">
              <AISuggestionsPopup
                type={assistanceUCPStore.currentCardType}
                onSelect={handleSuggestionSelect}
                onClose={handleCloseSuggestions}
              />
            </div>
          )}
          <ChatInput
            customerId={selectedAccount.id}
            textAreaRef={textAreaRef}
            onChange={handleMessageChange}
            onKeyPress={handleKeyPress}
            showSpinner={assistanceUCPStore.showSpinner}
            newMessage={assistanceUCPStore.newMessage}
            handleSendMessage={handleSendMessage}
            onFileUploaded={handleFileUpload}
            disableSend={
              assistanceUCPStore.isSendMessageClicked ||
              !assistanceUCPStore.conciergeAgent
            }
            onCleanThread={handleCleanThread}
            stop={assistanceUCPStore.showStopButton}
            onStopClick={handleStopAgentResponse}
          />
        </ChatInputWrapper>
      </AssistantWrapper>
    ),
    [
      assistanceUCPStore.showSpinner,
      assistanceUCPStore.conversationHistory,
      assistanceUCPStore.agentIsTyping,
      assistanceUCPStore.showSuggestions,
      assistanceUCPStore.currentCardType,
      assistanceUCPStore.newMessage,
      assistanceUCPStore.isSendMessageClicked,
      assistanceUCPStore.showStopButton,
      assistanceUCPStore.conciergeAgent,
      selectedAccount.id,
      handleSuggestionSelect,
      handleCloseSuggestions,
      handleKeyPress,
      handleMessageChange,
      handleSendMessage,
      handleStopAgentResponse,
      handleFileUpload,
      handleCleanThread,
    ],
  );

  const timelineContent = useMemo(
    () => (
      <AssistantWrapper>
        <TimelineBox />
      </AssistantWrapper>
    ),
    [],
  );

  const tabs = useMemo(
    () => [
      {
        label: "Chat",
        content: chatContent,
      },
      {
        label: "Timeline",
        content: timelineContent,
      },
    ],
    [chatContent, timelineContent],
  );

  return (
    <UnifiedProfileTabs
      tabs={tabs}
      showBorder={false}
      activeTab={activeTab}
      onTabChange={handleTabChange}
    />
  );
});

export default Assistance;

import styled, { createGlobalStyle } from "styled-components";

const UnifiedContainer = styled.div`
  margin-top: 10px;
  grid-template-areas:
    "breadcrumb breadcrumb"
    "widget-chat widget-chat";
  grid-template-rows: 44px 1fr;
  grid-template-columns: 1fr 1fr;
  height: 100svh;
  width: 100%;
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
`;

const BreadcrumbContainer = styled.div`
  margin-top: 10px;
  margin-left: 20px;
`;

const WidgetChatWrapper = styled.div`
  grid-area: widget-chat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const WidgetChatWrapperMobile = styled.div`
  grid-area: widget-chat;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const WidgetChatWrapperTablet = styled.div`
  grid-area: widget-chat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Breadcrumb = styled.div`
  grid-area: breadcrumb;
  padding: 0px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
`;

const ChatContainer = styled.div`
  padding: 0px 8px 8px 0px;
`;

const ChatContainerMobile = styled.div`
  padding: 0;
`;

const ChatWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-radius: 8px;
  height: calc(100svh - 100px);
  max-height: calc(100svh - 100px);
`;

const WidgetContainer = styled.div`
  height: calc(100svh - 108px);
  max-height: calc(100svh - 108px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding-left: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const WidgetContainerMobile = styled.div`
  height: calc(100svh - 108px);
  max-height: calc(100svh - 108px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`;

const WidgetContainerTablet = styled.div`
  height: calc(100svh - 108px);
  max-height: calc(100svh - 108px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin: 0 5px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
  p{
    margin: 0;
  }
`;

const CustomGenericListStyleMobile = createGlobalStyle`
  p{
    margin: 0;
  }

  #main-content {
    overflow-y: scroll !important;
  }

  #dataAvaliableWrapper {
    gap:2px;
    overflow-y: hidden;
  }

  #dataAvaliableWrapper > p:nth-child(3) > div{
    width: 50px;
    height: auto;
  }

  #main-content > div > div > div > div ul > li,
  {
    padding: 0 !important;
  }
`;

const CustomGenericListStyleTablet = createGlobalStyle`
  #main-content {
    overflow-y: hidden;
    padding: 0 10px;
  }

  #main-content > div > div > div > div > div > div > ul > li{
    padding: 0 !important;
  }

  #main-content > div > div > div > div > div > ul > li{
    padding: 0 !important;
  }

  p{
    margin: 0;
  }

  #dataAvaliableWrapper > p:nth-child(3) > div{
    width: 50px;
    height: auto;
  }

  #dataAvaliableWrapper{
    gap:0px;
    overflow-y: hidden;
  }

  #main-content > div > div > div > div:first-child{
    gap:0px;
  }

`;


const WidgetFirstColumn = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const WidgetSecondColumn = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const DataAvaliableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 5px;

  & p:first-child {
    font-weight: bold;
  }
`;

const MobileWrapper = styled.div`
  padding: 24px;
  height: calc(100svh - 50px);

  & > div > div:first-child {
    border-bottom: none;
    margin-bottom: 4%;
    justify-content: space-between;
  }

  & > div > div:first-child > div {
    padding: 9px 0px;
  }
`;

export {
  UnifiedContainer,
  Breadcrumb,
  WidgetChatWrapperMobile,
  WidgetChatWrapperTablet,
  ChatContainer,
  ChatContainerMobile,
  WidgetContainer,
  WidgetContainerMobile,
  WidgetContainerTablet,
  ChatWrapper,
  WidgetFirstColumn,
  WidgetSecondColumn,
  CustomGenericListStyle,
  CustomGenericListStyleMobile,
  CustomGenericListStyleTablet,
  WidgetChatWrapper,
  DataAvaliableWrapper,
  MobileWrapper,
  BreadcrumbContainer,
};

import React, { useState, useRef, useEffect } from "react";
import {
  ImageSelectorButton,
  ImageSelectorWrapper,
  HiddenFileInput,
  CustomAvatarContainer,
  LoadingCustomAvatar,
} from "./ImageSelector.styled";
import DefaultAvatar from "../../icon/assets/carbon_user_avatar_filled.svg";
import { SuccessfulModal } from "../../../elements";
import Dropdown from "../../../elements/v2/Dropdown/Dropdown";

const ImageSelector = ({ editAgent, customerId }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(DefaultAvatar);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (editAgent?.photo_url) {
      setSelectedImage(editAgent.photo_url);
    } else {
      setSelectedImage(DefaultAvatar);
    }
  }, [editAgent?.photo_url]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      setError("Incorrect format.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      setError(null);

      await editAgent.uploadAgentPhoto(file, customerId);

      if (editAgent?.photo_url) {
        setSelectedImage(editAgent.photo_url);
      }
    } catch (err) {
      setError("Error uploading image. Please try again.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleGeneratePhoto = async () => {
    setShowDropdown(false);
    const response = await editAgent.fetchGenerateAvatar(customerId);
    await editAgent.updateAgent(response?.file_name);
    setPreviewImage(response?.file_url);
    setSelectedImage(response?.file_url);
  };

  const imageToShow = previewImage || selectedImage;

  const dropdownItems = [
    {
      value: "Upload photo",
      divisor: true,
      onClick: handleImageClick,
    },
    {
      value: "Generate photo",
      divisor: false,
      onClick: handleGeneratePhoto,
    },
  ];

  return (
    <>
      <CustomAvatarContainer onClick={() => setShowDropdown(!showDropdown)}>
        <ImageSelectorWrapper>
          <Dropdown
            show={showDropdown}
            setShow={setShowDropdown}
            items={dropdownItems}
          >
            {editAgent.isLoading ? (
              <LoadingCustomAvatar />
            ) : (
              <ImageSelectorButton>
                <img src={imageToShow} alt="Selected avatar" />
                <HiddenFileInput
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                />
              </ImageSelectorButton>
            )}
          </Dropdown>
        </ImageSelectorWrapper>
      </CustomAvatarContainer>

      <SuccessfulModal
        isOpen={!!error}
        title="Error"
        subtitle={error}
        onClose={() => setError(null)}
      />
    </>
  );
};

export default ImageSelector;

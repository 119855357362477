import { makeObservable, observable, action } from "mobx";
import { v4 as uuidv4 } from "uuid";

class RulesStore {
  fields = [];
  operator = "";
  message = "";
  rulesGroups = {};
  showRightPreview = false;

  constructor() {
    makeObservable(this, {
      operator: observable,
      message: observable,
      rulesGroups: observable,
      fields: observable,
      setOperator: action,
      updateRule: action,
      removeRule: action,
      loadExistingRules: action,
      clear: action,
      addGroup: action,
      addRuleToGroup: action,
      removeGroup: action,
      saveRules: action,
      setShowRightPreview: action,
      showRightPreview: observable,
      clearAllRules: action,
    });
  }

  setShowRightPreview(show = false) {
    this.showRightPreview = show;
  }

  loadExistingRules(rules) {
    if (!rules.length) return this.addGroup("AND");
    for (const rule of rules) {
      const newGroupId = uuidv4();
      this.rulesGroups[newGroupId] = rule;
      this.operator = rule.logical_operator;
    }
  }

  setFields(fields) {
    this.fields = fields;
  }

  setOperator(operator) {
    this.operator = operator;
  }

  addGroup(operator) {
    const newGroupId = uuidv4();
    this.rulesGroups[newGroupId] = {
      logical_operator: operator,
      type: "GROUP",
      conditions: [{}],
    };
  }

  removeGroup(groupId) {
    delete this.rulesGroups[groupId];
  }

  addRuleToGroup(groupId) {
    if (!this.rulesGroups[groupId]) {
      this.addGroup("AND");
      groupId = Object.keys(this.rulesGroups)[0];
    }
    this.rulesGroups[groupId].conditions.push({});
  }

  updateRule(index, data, groupdId) {
    return (this.rulesGroups[groupdId].conditions[index] = data);
  }

  removeRule(index, groupdId) {
    return (this.rulesGroups[groupdId].conditions = [
      ...this.rulesGroups[groupdId].conditions.filter((_, id) => id !== index),
    ]);
  }

  saveRules() {
    return Object.values(this.rulesGroups);
  }

  clear() {
    this.fields = [];
    this.operator = "";
    this.message = "";
    this.rulesGroups = {};
    this.addGroup("AND");
    this.message = "";
    this.operator = "AND";
  }

  clearAllRules() {
    const groupKeys = Object.keys(this.rulesGroups);
  
    if (groupKeys.length === 0) {
      this.addGroup("AND");
    } else {
      groupKeys.forEach((groupId, index) => {
        if (index === 0) {
          this.rulesGroups[groupId].conditions = [{}];
        } else {
          delete this.rulesGroups[groupId];
        }
      });
    }
  
    this.message = "";
    this.operator = "AND";
  }
  
  
}

export default RulesStore;

import styled from "styled-components";

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  margin-bottom: 10px;
  & input {
    font-size: 12px;
  }
  & button {
    margin-left: 6px;
  }
  gap: 6px;
  
`;

export const FiltersContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

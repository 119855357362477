import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0 4%;
  width: 100%;
`;

const AgentsContainer = styled.div`
  margin-bottom: 4%
`;

const InputField = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  color: black;
`;

const SearchIcon = styled.div`
  width: 14px;
  height: auto;
`;

const CustomComponent1 = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  min-width: 400px;
  background-color: ${props => props.theme.colors.primaryWhite};
  padding: 10px;
  cursor: pointer
`;

const InfoBoxWrapper = styled.div`
  margin-left: 10px;
  min-height: 100px;
  align-items: flex-start;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin:0;
`;

const AgentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top : 50px;
  gap: 1rem;
`;

const DividerLine = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${props => props.theme.colors.lighterGray};
  margin: 3%;
`;

const DividerLine1 = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${props => props.theme.colors.lighterGray};
  margin: 0 0 3% 0;
`;

const TruncatedDescription = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 60px;
    margin: 3px 0;
`;

const CustomImage = styled.img`
    width: 100px;
    height: auto;
`;

const LoadMoreContainer = styled.div`
  text-align: -webkit-center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

const CustomGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
    
  & button {
    font-family: "Inter", sans-serif;
  }  
`;

const CustomSearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  gap: 1rem;
  border-radius: 10px;
  padding: 6px 8px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.clearGray};

`;

const StyledIconButton = styled.div`
  background-color: ${props => props.theme.colors.primaryWhite};
  border: 1px solid ${props => props.theme.colors.borderGray};
  color: ${props => props.theme.colors.borderGray};
  padding: 8px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  width: 47px;
  height:47px;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
    width: 95%;
    display: flex;
    justify-content: flex-start;
`;


const FilterContainter = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    
    & button {
      font-family: "Inter", sans-serif;
    }
`

const ProgressFilterContainer = styled.div`
  width: 60%;
`

export {
  ThreeColumnGrid,
  AgentsContainer,
  SearchIcon,
  InputField,
  InfoBoxWrapper,
  Title,
  AgentViewContainer,
  DividerLine,
  TruncatedDescription,
  CustomImage,
  CustomComponent1,
  LoadMoreContainer,
  CustomGrid,
  CustomSearchInput,
  StyledIconButton,
  DividerLine1,
  TabsContainer,
  FilterContainter,
  ProgressFilterContainer
};

import React from "react";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";
import Spinner from "../../../../components/common/Spinner";
import General from "../../CampaignPage/EventName/General/General";
import ContactPage from "../components/ContactsView/ContactPage";
import Actions from "./Actions/Actions";
import { TabsContainer } from "./EventName.styled";
import { tabs } from "./Constants/enum.js";

export const ConversationTab = {
  General: 0,
  Rules: 1,
  Actions: 2,
};

export const ContactContent = ({ currentTab, setCurrentTab, viewConversationStore }) => {
  if (!viewConversationStore.finishedInitialLoad || viewConversationStore.isUpdating) {
    return <Spinner />;
  }

  return (
    <TabsContainer>
      <Tabs2 defaultTab={currentTab} tabs={tabs} onToggleTab={setCurrentTab} />
      <Tabs2.Outlet show={currentTab === ConversationTab.General}>
        <General />
      </Tabs2.Outlet>
      <Tabs2.Outlet show={currentTab === ConversationTab.Rules}>
        <ContactPage />
      </Tabs2.Outlet>
      <Tabs2.Outlet show={currentTab === ConversationTab.Actions}>
        <Actions />
      </Tabs2.Outlet>
    </TabsContainer>
  );
};

export default ContactContent;

import React from "react";
import {
  UnifiedContainer,
  BreadcrumbContainer,
  ChatContainer,
  WidgetContainer,
  ChatWrapper,
  WidgetFirstColumn,
  WidgetSecondColumn,
  WidgetChatWrapper,
  CustomGenericListStyle,
} from "../BaseUnifiedProfilePage.styled";
import BaseUnifiedProfilePage from "../BaseUnifiedProfilePage";
import Assistance from "../components/Assistance/Assistance";
import GeneralInfo from "../components/Cards/GeneralInfo";
import GarageCard from "../components/Cards/GarageCard";
import Appointments from "../components/Cards/Appointments";
import Notification from "../components/Cards/Notification";
import RepairOrders from "../components/Cards/RepairOrders";
import Notes from "../components/Cards/Notes";
import { useLocation } from "react-router-dom";
import DynamicBreadcrumbs from "../../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../hooks";

const UnifiedProfilePage = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const location = useLocation();

  const getDynamicHref = (typeUrl) => {
    if (typeUrl.toLowerCase() === "vehicles" || typeUrl.toLowerCase() === "assistants" || typeUrl.toLowerCase() === "repair-orders" || typeUrl.toLowerCase() === "appointments") {
      return `/v2/${typeUrl.toLowerCase()}`;
    }
    return `/${typeUrl.toLowerCase()}`;
  };

  const contactInfo = unifiedCustomerProfileStore.contactInfo[0] || {};
  const breadcrumbItems = [
    { label: unifiedCustomerProfileStore.typeUrl, href: getDynamicHref(unifiedCustomerProfileStore.typeUrl) },
    { label: contactInfo.contact_full_name || "Loading..." },
    { label: "UCP" },
  ];

  return (
    <BaseUnifiedProfilePage>
      <BreadcrumbContainer>
        <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
      </BreadcrumbContainer>
      <UnifiedContainer>
        <WidgetChatWrapper>
          <WidgetContainer>
            <WidgetFirstColumn>
              <GeneralInfo />
              <Appointments />
              <Notification />
            </WidgetFirstColumn>
            <WidgetSecondColumn>
              <GarageCard />
              <RepairOrders />
              <Notes />
            </WidgetSecondColumn>
          </WidgetContainer>
          <ChatContainer>
            <ChatWrapper>
              <Assistance />
            </ChatWrapper>
          </ChatContainer>
        </WidgetChatWrapper>
        <CustomGenericListStyle />
      </UnifiedContainer>
    </BaseUnifiedProfilePage>
  );
});

export default UnifiedProfilePage;

import { useCallback, useEffect, useRef, useState } from "react";
import { SuccessfulModal } from "../../modals";

import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  TemplateEditorWrapper,
  EmailEditorWrapper,
  TabsWrapper,
  Backdrop,
  TemplateEditorContainer,
  TemplateEditorFooter,
  ButtonWrapper,
} from "./TemplateEditor.styled";
import GeneralTab from "./GeneralTab";
import VariablesTab from "./VariablesTab";
import { useStore } from "../../../hooks";
import Spinner from "../../../components/common/Spinner";
import EmailEditor from "react-email-editor";
import IconTabs from "../Tabs/IconTabs/IconTabs";
import { RoundedButton } from "../../button";
import { ImagePickerModal } from "./components";
import { templateEditor } from "../../../components/app/consts";
import debounce from 'lodash/debounce';

const TemplateEditor = observer(
  ({
    isOpen = true,
    onClose,
    onLoad,
    onExport,
    isPopup = true,
    isReadMode,
    exportAction,
    initialContent,
    skipTemplateLoad = false,
    design,
    onContentChange
  }) => {
    const emailEditorRef = useRef(null);
    const doneCallbackRef = useRef(null);
    const [hasContentChanged, setHasContentChanged] = useState(false);
    const { templateEditorStore } = useStore();
    const [isOpenCustomLibraryModal, setIsOpenCustomLibraryModal] =
      useState(false);
    const [currentContent, setCurrentContent] = useState(null);

    const debouncedContentChange = useCallback(
      debounce((editor) => {
        editor.exportHtml((data) => {
          const { html } = data;
          if (html) {
            onContentChange?.(html);
          }
        });
      }, 5000),
      [onContentChange]
    );

    useEffect(() => {
      const unlayer = emailEditorRef.current?.editor;
      if (unlayer) {
        const handleDesignUpdate = () => {
          debouncedContentChange(unlayer);
        };

        const handlePaste = () => {
          unlayer.exportHtml((data) => {
            const { html } = data;
            if (html) {
              onContentChange?.(html);
            }
          });
        };

        unlayer.addEventListener('design:updated', handleDesignUpdate);
        unlayer.addEventListener('design:updated:paste', handlePaste);

        return () => {
          unlayer.removeEventListener('design:updated', handleDesignUpdate);
          unlayer.removeEventListener('design:updated:paste', handlePaste);
        };
      }
    }, [emailEditorRef.current, debouncedContentChange]);

    useEffect(() => {
      onLoad?.();
      if (!skipTemplateLoad) {
        templateEditorStore.loadTemplate();
      }
    }, [skipTemplateLoad, onLoad]);

    const updateVariables = () => {
      if (
        templateEditorStore.variables.length &&
        emailEditorRef.current?.editor
      ) {
        const variablesToSet = {};
        for (const variable of templateEditorStore.variables) {
          variablesToSet[variable.variable] = {
            name: variable.name,
            value: `{{${variable.variable}}}`,
            sample: `{{${variable.variable}}}`,
          };
        }

        emailEditorRef.current.editor.setMergeTags(variablesToSet);
      }
    };

    const exportHtml = () => {
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml(async (data) => {
        const { design, html } = data;
        await templateEditorStore.saveTemplate(html, design);
        templateEditorStore.savePayload();
        onExport?.();
      });
    };

    const closeEditor = () => {
      const unlayer = emailEditorRef.current?.editor;
      if (unlayer) {
        unlayer.exportHtml((data) => {
          const { html } = data;
          if (html) {
            onContentChange?.(html);
          }
          templateEditorStore.clearTemplate();
          onClose && onClose();
        });
      } else {
        templateEditorStore.clearTemplate();
        onClose && onClose();
      }
    };

    const onReady = () => {
      updateVariables();
      exportAction?.(() => () => exportHtml());
      templateEditorStore.updateGeneralField("initialLoading", true);
      if (
        templateEditorStore.templateJson &&
        templateEditorStore.initialLoading
      ) {
        emailEditorRef.current.editor.loadDesign(
          toJS(templateEditorStore.templateJson)
        );
      } else if (initialContent) {
        try {
          const safeDesign = {
            body: {
              rows: [{
                cells: [1],
                columns: [{
                  contents: [{
                    type: "text",
                    values: {
                      text: initialContent
                    }
                  }]
                }]
              }],
              values: {
                backgroundColor: "#ffffff",
                width: "600px",
                padding: "20px"
              }
            }
          };

          emailEditorRef.current.editor.loadDesign(safeDesign);
          
          emailEditorRef.current.editor.addEventListener('design:updated', () => {
            setHasContentChanged(true);
          });
          
        } catch (error) {
          emailEditorRef.current.editor.loadDesign({
            html: initialContent
          });
        }
      }

      emailEditorRef.current.editor.registerCallback(
        "selectImage",
        function (data, done) {
          doneCallbackRef.current = done;
          setIsOpenCustomLibraryModal(true);
        }
      );

      emailEditorRef.current.editor.registerProvider(
        "userUploads",
        async (params, done) => {
          const page = params.page || 1;
          const perPage = params.perPage || 20;
          const isCount = page === 1;
          const searchText = params.searchText;

          const payload = {
            count_records: isCount,
            page_size: perPage,
            page,
            search: searchText,
          };

          await templateEditorStore.getVehicleStockImages(payload);

          if (!templateEditorStore.vehicleStockImages.vehicles) return;

          const total = templateEditorStore.vehicleStockImagesTotalRowCount;
          const hasMore = total > page * perPage;

          done(toJS(templateEditorStore.vehicleStockImages?.vehicles), {
            hasMore,
            page,
            perPage,
            total,
          });
        }
      );
    };

    const tabs = [
      {
        label: "General",
        icon: "gear",
        content: <GeneralTab />,
      },
      {
        label: "Variables",
        icon: "brackets",
        content: <VariablesTab updateVariables={updateVariables} />,
      },
    ];

    const CoreEditor = useCallback(
      () => (
        <TemplateEditorContainer>
          <TabsWrapper>
            <IconTabs tabs={tabs} />
          </TabsWrapper>
          <EmailEditorWrapper>
            {templateEditorStore.isLoading ? (
              <Spinner />
            ) : (
              <EmailEditor
                onReady={onReady}
                ref={emailEditorRef}
                options={templateEditor.options}
              />
            )}
          </EmailEditorWrapper>
        </TemplateEditorContainer>
      ),
      [templateEditorStore.initialLoading]
    );

    return (
      <>
        <ImagePickerModal
          isOpenCustomLibraryModal={isOpenCustomLibraryModal}
          doneCallbackRef={doneCallbackRef}
          setIsOpenCustomLibraryModal={setIsOpenCustomLibraryModal}
        />
        {isPopup ? (
          <Backdrop isOpen={isOpen}>
            <TemplateEditorWrapper>
              <CoreEditor />
              <TemplateEditorFooter>
                <RoundedButton
                  width="79px"
                  kind="secondary"
                  onClick={closeEditor}
                >
                  Cancel
                </RoundedButton>
                {!isReadMode && (
                  <ButtonWrapper>
                    <RoundedButton
                      width="89px"
                      onClick={exportHtml}
                      disabled={templateEditorStore.updatingTemplate}
                    >
                      {templateEditorStore.updatingTemplate
                        ? "Updating..."
                        : "Update"}
                    </RoundedButton>
                  </ButtonWrapper>
                )}
              </TemplateEditorFooter>
            </TemplateEditorWrapper>
            {templateEditorStore.isError && (
              <SuccessfulModal
                isOpen={templateEditorStore.isError}
                onClose={() => templateEditorStore.onClearError()}
                title="Oops!"
                subtitle="An error occurred."
                subtitle2="Please try again later."
                zIndex={true}
              />
            )}
          </Backdrop>
        ) : (
          <CoreEditor />
        )}
      </>
    );
  }
);

export default TemplateEditor;

import styled from "styled-components";
import { Card as MuiCard, CardActions, Box } from "@mui/material";

export const StyledCard = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  min-height: 187px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const TruncatedDescription = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.textPrimary};
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Footer = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const Actions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0;
`;

export const SemiBoldTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.textPrimary};
`;
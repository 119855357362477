import { action, flow, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import {getNotes, putNotes } from "../../graphql/queries";


class NotesStore {
  notes = [];
  categories = [];
  isLoading = false;
  isSuccessful = false;
  isError = false;
  isNotesDrawerOpen = false;
  selectedNote = {};

  constructor() {
    makeObservable(this, {
      notes: observable,
      isLoading: observable,
      isSuccessful: observable,
      isError: observable,
      isNotesDrawerOpen: observable,
      selectedNote: observable,
      categories: observable,
      openNotesDrawer: action,
      closeNotesDrawer: action,
      setCategories: action,
      setSelectedNote: action,
      setIsSuccessful: action,
      fetchNotesUCP: flow,
      updateNotes: flow,
    });
  }

  openNotesDrawer() {
    this.isNotesDrawerOpen = true;
  }

  closeNotesDrawer() {
    this.isNotesDrawerOpen = false;
    this.selectedNote = null;
  }

  setCategories(categories) {
    this.categories = categories;
  }

  setSelectedNote(note) {
    this.selectedNote = note;
  }

  *fetchNotesUCP(owner, noted) {
    try {
      this.isLoading = true;

      const response = yield API.graphql({
        query: getNotes,
        variables: {
          input: {
            owner: owner,
            noted: noted,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.notes = JSON.parse(response.data?.getNotes?.body);

    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  setIsSuccessful() {
    this.isSuccessful = false;
  }

  *updateNotes(note) {
    this.isSuccessful = false;

    try {
      const data = {
        id: note.id || "",
        category: note.category,
        tags: [],
        metadata: JSON.stringify({}),
        owner: note.owner,
        noted: note.noted,
        memos: note.memos
      };

      yield API.graphql({
        query: putNotes,
        variables: {
          input:data
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      this.isSuccessful = true;

    } catch (error) {
      this.isError = true;
    }
  }
}

export default NotesStore;

import React, { useEffect, useState } from "react";
import {observer} from "mobx-react";
import ConversationsChart from "./Metrics/Conversations";

import { Grid } from "@mui/material";
import AgentsLineChart from "./Metrics/AgentsLineChart";
import CustomChars from "./Metrics/CustomChars";
import {useStore} from "../../hooks";

const OnboardingPage = observer(() => {

  const [showChannels, setShowChannels] = useState(false);
  const [showKeys, setShowKeys] = useState(false);
  const [showCampaings, setShowCampaings] = useState(false);
  const [showAssistants, setShowAssistants] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CustomChars
          title="Conversations"
          subtitle="Amount of conversations we are having per Channel today"
        />
        <ConversationsChart />
        <CustomChars />
        {showCampaings && <p>Configure the Campaings</p>}
      </Grid>
      <Grid item xs={6}>
        <CustomChars
          title="Agents"
        />
        <AgentsLineChart />
        <CustomChars />
      </Grid>
    </Grid>
  );
});

export default OnboardingPage;

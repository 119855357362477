import React, { useRef, useState } from "react";
import { FileSelectContainer } from "../../../../../ContactsCSVReader/ContactsCSVReader.styled";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useStore } from "../../../../../../../hooks";
import {
  PhotoPreview,
  PhotoPreviewContainer,
  PhotoPreviewDescription,
  StyledInput,
  StyledLabel,
  UploadingPhoto,
  UploadingPhotoHeader,
} from "./UploadTab.styled";
import { LinearProgress } from "@mui/material";
import {
  PHOTO_UPLOAD_STAGES,
  STAGE_PERCENTAGES,
} from "../../../../../../../utils/enums";

const usePhotoUploadProgress = (photoStage) => {
  return STAGE_PERCENTAGES[photoStage] || 0;
};

const UploadTab = ({ handleFile }) => {
  const fileInputRef = useRef(null);
  const { templateEditorStore, authStore } = useStore();
  const [photoStage, setPhotoStage] = useState(PHOTO_UPLOAD_STAGES.IDLE);
  const [fileMetadata, setFileMetadata] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);

  const progress = usePhotoUploadProgress(photoStage);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) uploadFile(file);
  };

  const uploadFile = async (file) => {
    setFileMetadata(file);
    processFile(file);
    const accountId = authStore.selectedAccount.id;
    const name = file.name;
    const params = {
      account: accountId,
      display_name: name,
      gallery_name: "unlayer_editor",
      attributes: [],
      metadata: {},
    };
    setPhotoStage(PHOTO_UPLOAD_STAGES.PUTTING_FILE_NAME);
    const response = await templateEditorStore.putFileName(params);

    const file_name = response?.file_name;

    if (!file_name) return false;

    setPhotoStage(PHOTO_UPLOAD_STAGES.UPLOADING_FILE_TO_S3);
    const responseUploadS3File = await templateEditorStore.uploadFileToS3(
      file,
      file?.type,
      file_name,
      "image_gallery"
    );

    if (!responseUploadS3File) return false;

    setPhotoStage(PHOTO_UPLOAD_STAGES.GETTING_IMAGE_FROM_PHOTO_URL);
    const responseGetImage = await templateEditorStore.getImageRequest(
      accountId
    );
    const image = responseGetImage?.items.find((image) => {
      return file_name === image.file_name;
    });

    setPhotoStage(PHOTO_UPLOAD_STAGES.FINISHED_PHOTO_UPLOAD);
    setUploadedPhoto(image.photo_url);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) uploadFile(file);
    e.target.value = "";
  };

  const processFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => setPhotoPreview(reader?.result);
  };

  const onClickPhotoPreview = () => {
    const photoStageIsFinished =
      photoStage === PHOTO_UPLOAD_STAGES.FINISHED_PHOTO_UPLOAD;
    const progressIsComplete = progress === 100;

    if (photoStageIsFinished && progressIsComplete) {
      handleFile(uploadedPhoto);
      resetAll();
    }
  };

  const resetAll = () => {
    setPhotoStage(PHOTO_UPLOAD_STAGES.IDLE);
    setPhotoPreview(null);
    setUploadedPhoto(null);
    setFileMetadata(null);
  };

  return (
    <>
      <p>Upload file</p>
      {photoStage === PHOTO_UPLOAD_STAGES.IDLE && (
        <>
          <FileSelectContainer
            isDragOver={isDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <StyledInput
              id="contacts-file-input"
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
            />
            <StyledLabel htmlFor="contacts-file-input">
              <FileUploadIcon fontSize="large" />
              Drag and drop or choose a file to upload
            </StyledLabel>
          </FileSelectContainer>
        </>
      )}
      {photoStage !== PHOTO_UPLOAD_STAGES.IDLE && (
        <UploadingPhoto onClick={onClickPhotoPreview}>
          <UploadingPhotoHeader>
            <PhotoPreviewContainer>
              <PhotoPreview photo_preview={photoPreview} />
            </PhotoPreviewContainer>
            <PhotoPreviewDescription>
              <div>{fileMetadata?.name}</div>
              <div class="size">{fileMetadata?.size}</div>
            </PhotoPreviewDescription>
          </UploadingPhotoHeader>
          {progress !== 100 && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </UploadingPhoto>
      )}
    </>
  );
};

export default UploadTab;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { deleteKey, getKeys, putKey, getCustomers } from "../../graphql/queries";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import { CustomBox } from "../../theme/DefaultTheme";
import {useStore} from "../../hooks";
import {observer} from "mobx-react";

const KeyList = observer(() => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpenDelete, setDialogOpenDelete] = useState(false);
  // Loading context
  const { authStore } = useStore();
  const { isLoading, isSuperAdmin, selectedAccount } = authStore;

  const [Keys, setKeys] = useState([]);
  const [customer, setCustomer] = useState("");
  const [isLoadingKeysError, setIsLoadingKeysError] = useState(false);
  const [isFetchingKeys, setIsFetchingKeys] = useState(false);
  const [isEditingKey, setIsEditingKey] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [uuid, setUUID] = useState("")

  //fetch customers
  useEffect(() => {
    void fetchKeys(selectedAccount.id);
  }, [selectedAccount]);

  //fetch keys
  const fetchKeys = async (customer) => {
    try {
      setIsFetchingKeys(true);
      const response = await API.graphql({
        query: getKeys,
        variables: { input: { customer: customer } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      setKeys(JSON.parse(response.data?.getKeys?.body).data);
    } catch (error) {
      console.error("Error getting keys", error);
      setIsLoadingKeysError(true);
    } finally {
      setIsFetchingKeys(false);
    }
  };

  // column format
  const columns = useMemo(
    () => {
      const baseColumns = [

        {
          accessorKey: "id",
          header: "Id",
          enableEditing: false,
          size: 80,
        },
        {
          accessorKey: "customer",
          header: "Customer",
          enableEditing: false,
        },
        {
          accessorKey: "name",
          header: "Name",
          muiEditTextFieldProps: {
            type: "text",
            required: true,
            error: !!validationErrors?.name,
            helperText: validationErrors?.name,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                name: undefined,
              }),
          },
        },
        {
          accessorKey: "key",
          header: "Key",
          required: true,
          muiEditTextFieldProps: {
            type: "text",
            required: true,
            error: !!validationErrors?.key,
            helperText: validationErrors?.key,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                context: undefined,
              }),
          },
        },

      ];

      return baseColumns;
    },
    [validationErrors]
  );

  // Call to put keys funtion
  async function UsePutKey(values) {
    try {
      setIsEditingKey(true);
      console.log("values", values);
      const keyValues = {
        id: values.id,
        customer: selectedAccount.id,
        keyName: values.name,
        writeKey: values.key
      };
      const response = await API.graphql({
        query: putKey,
        variables: { input: keyValues },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      await fetchKeys(selectedAccount.id);
    } catch (error) {
      console.error("Error creating Campaign", error);
      setIsLoadingKeysError(true);
    } finally {
      setIsEditingKey(false);
    }
  }

  // Call to delete keys funtion
  async function UseDeleteCampaign(value) {
    try {
      setIsEditingKey(true);
      const response = await API.graphql({
        query: deleteKey,
        variables: { input: { id: value.id, customer: selectedAccount.id } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      await fetchKeys(selectedAccount.id);
    } catch (error) {
      console.error("Error deleting Campaign", error);
      setIsLoadingKeysError(true);
    } finally {
      setIsEditingKey(false);
    }
  }

  // Handle to create key without validations
  const handleCreateKey = async ({ values, table }) => {
    const newValidationErrors = validateKey(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await UsePutKey(values);
    table.setCreatingRow(null); //exit creating mode
  };

  // Handle to update campaigns without validations
  const handleSaveKey = async ({ values, table }) => {
    const newValidationErrors = validateKey(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await UsePutKey(values);
    table.setEditingRow(null);
  };

  // COnfirmation for delete row
  const openDeleteConfirmModal = (row) => {
    setSelectedRow(row);
    setDialogOpenDelete(true);
  };

  const handleConfirmDelete = (row) => {
    if (row) {
      UseDeleteCampaign(row.original);
    }
    setDialogOpenDelete(false);
  };

  // UUID function
  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const handleGenerateUUID = () => {
    const generatedUUID = generateUUID();
    setUUID(generatedUUID);
  };

  const table = useMaterialReactTable({

    columns,
    data: Keys,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingKeysError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateKey,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveKey,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <div>
        <DialogTitle variant="h3">Create New Key</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
          <TextField
            id="uuidField"
            label="Generated UUID"
            variant="outlined"
            value={uuid}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
          <Button variant="contained" onClick={handleGenerateUUID}>
            Generate UUID
          </Button>
        </DialogActions>
      </div>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Key</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox display="flex" justifyContent="space-between">
        <div>
          <Button
            variant="contained"
            onClick={() => {
              table.setCreatingRow(
                createRow(table, {
                  id: "",
                  customer: selectedAccount.id,
                  name: "",
                  key: ""
                })
              );
            }}
          >
            Create New Key
          </Button>
        </div>
      </CustomBox>
    ),
    state: {
      isLoading: isFetchingKeys,
      isSaving: isEditingKey,
      showAlertBanner: isLoadingKeysError,
      showProgressBars: isFetchingKeys,
    },
  });

  //function call to get list of keys
  return (
    <div>
      <MaterialReactTable table={table} />
      <ConfirmationDialog
        open={dialogOpenDelete}
        onClose={() => setDialogOpenDelete(false)}
        message="Are you sure you want to delete this key?"
        onConfirm={() => handleConfirmDelete(selectedRow)}
      />
    </div>
  );
});

export default KeyList;

const validateRequired = (value) => !!value.length;

function validateKey(key) {
  return {
    name: !validateRequired(key.name)
      ? 'Key Name is Required'
      : '',
    key: !validateRequired(key.key) ? 'Key is Required' : '',
  };
}

import React, { useState } from "react";
import { TabsContainer, ActionButtonsContainer } from "./EventName.styled";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";
import RulesView from "../RulesView";
import { RoundedButton } from "../../../../elements";
import { RightTab, rightTabs } from "./Constants/enum";
import { useMediaQueries } from "../../../../utils/mediaQueries";

export const RulesContent = ({ isCollapsed, setIsCollapsed }) => {
  const [currentRightTab, setCurrentRightTab] = useState(RightTab.Rules);
  const { isMobile } = useMediaQueries();

  return (
    <TabsContainer>
      <Tabs2 
        defaultTab={currentRightTab} 
        tabs={rightTabs} 
        onToggleTab={setCurrentRightTab} 
      />
      <Tabs2.Outlet show={currentRightTab === RightTab.Rules} showBorder={true}>
        <RulesView />
      </Tabs2.Outlet>
      <Tabs2.Outlet show={currentRightTab === RightTab.Contacts}></Tabs2.Outlet>
      
      {!isCollapsed && (
        <ActionButtonsContainer isMobile={isMobile}>
          <RoundedButton
            kind="aquamarineGreen"
            width={isMobile ? "60px" : "80px"}
            height={isMobile ? "20px" : "30px"}
            fontSize={isMobile ? "12px" : "14px"}
            onClick={() => {}}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            kind="blue"
            width={isMobile ? "60px" : "80px"}
            height={isMobile ? "20px" : "30px"}
            fontSize={isMobile ? "12px" : "14px"}
            onClick={() => {}}
          >
            Save
          </RoundedButton>
        </ActionButtonsContainer>
      )}
    </TabsContainer>
  );
};

export default RulesContent;

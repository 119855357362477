import { observer } from "mobx-react";
import { ContainerWrapper, LoadingBox } from "./Loading.styled";

const Loading = observer(() => {
  return (
    <ContainerWrapper data-testid="loading-spinner">
      <LoadingBox />
    </ContainerWrapper>
  )
});

export default Loading;

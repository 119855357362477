import { CenteredLabel } from "../UploadTab/UploadTab.styled";
import { ImageFiltered, ImagesFilteredContainer } from "./ImagesGrid.styled";

const ImagesGrid = ({ images, isLoading, onClickImage }) => (
  <>
    {images?.length && !isLoading ? (
      <ImagesFilteredContainer>
        {images.map((car) => (
          <ImageFiltered
            key={car.photo_url}
            onClick={() => onClickImage(car.photo_url)}
            srcImg={car.photo_url}
          />
        ))}
      </ImagesFilteredContainer>
    ) : (
      <CenteredLabel>
        {isLoading ? "Loading ..." : "No vehicles found"}
      </CenteredLabel>
    )}
  </>
);

export default ImagesGrid;

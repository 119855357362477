import React, {useCallback, useMemo} from "react";
import {
  NoteHeader,
  NotesDescription,
  NotesDataContainer
} from "./Widget.styled";
import { observer } from "mobx-react-lite";
import useOwnerData from "../../../../../utils/useOwnerData";
import { useDateFormat } from "../../../../../utils/useDateFormat";
import { useStore } from "../../../../../hooks";

const NoteItem = observer(({ note, index }) => {
  const { ownerName } = useOwnerData(note.owner);
  const { notesStore, authStore } = useStore();
  const isOwner = useMemo(() =>
      authStore.userData.id === note.owner,
    [authStore.userData.id, note.owner]
  );
  const formattedDate = useDateFormat(note.last_modified);


  const handleNoteClick = useCallback(() => {
    if(isOwner){
      notesStore.setSelectedNote(note);
      notesStore.openNotesDrawer();
    }
  }, [isOwner, note, notesStore]);

  return (
    <NotesDataContainer isOwner={isOwner} onClick={handleNoteClick}>
      <NoteHeader>
        <span>Note {index + 1}</span>
        <div>{ownerName}</div>
        <div>{note.category}</div>
      </NoteHeader>
      <NotesDescription>
        {note.memos.map((item) => (
          <p key={item.id}>{item.memo}</p>
        ))}
        <span>
          Last edited on {formattedDate}
        </span>
      </NotesDescription>
    </NotesDataContainer>
  );
});

export default NoteItem;

import Filter from "../../../Filters/Filters";
import InputText from "../../../../../TextInput/TextInput";
import { RoundedButton } from "../../../../../../button";
import { FiltersContainer, FiltersContent } from "./FiltersTab.styled";

const FiltersTab = ({
  filters,
  setters,
  isLoading,
  handleTextSearchDebounced,
  handleClearAllFilters,
  searchText
}) => {
  return (
    <FiltersContainer>
      <InputText
        placeholder="Search"
        value={searchText}
        onChange={handleTextSearchDebounced}
        disabled={isLoading}
      />
      <FiltersContent>
        <Filter
          value={filters.selectedMake}
          setValue={(event) => setters.setMake(event.target.value)}
          data={filters.make}
          name="Make"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedModel}
          setValue={(event) => setters.setModel(event.target.value)}
          data={filters.models}
          name="Model"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedYear}
          setValue={(event) => setters.setYear(event.target.value)}
          data={filters.years}
          name="Year"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedType}
          setValue={(event) => setters.setType(event.target.value)}
          data={filters.types}
          name="Type"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedAngle}
          setValue={(event) => setters.setAngle(event.target.value)}
          data={filters.angles}
          name="Angle"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedBackground}
          setValue={(event) => setters.setBackground(event.target.value)}
          data={filters.backgrounds}
          name="Background"
          disabled={isLoading}
        />
        <Filter
          value={filters.selectedSize}
          setValue={(event) => setters.setSize(event.target.value)}
          data={filters.sizes}
          name="Size"
          disabled={isLoading}
        />

        <RoundedButton
          onClick={handleClearAllFilters}
          width={"10%"}
          kind="blue"
          outline={true}
          disabled={isLoading}
        >
          Clear all
        </RoundedButton>
      </FiltersContent>
    </FiltersContainer>
  );
};

export default FiltersTab;

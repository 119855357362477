import styled from "styled-components";

export const TopBar = styled.div`
  top: 0;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  right: calc((100% - 800px) / 2);
`;

export const QuestionnairesContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const NewButton = styled.button`
  background: #0066ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #0052cc;
    }
`;

export const QuestionnaireCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  background: white;
  
  &:hover {
    border-color: #0066ff;
  }
`;

export const QuestionnaireHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionnaireTitleSection = styled.div`
  flex: 1;
`;

export const QuestionnaireName = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

export const QuestionCount = styled.div`
  color: black;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 400;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-right: 12px;

  svg {
    cursor: pointer;
    color: #666;
    
    &:hover {
      color: #0066ff;
    }
  }
`;

export const QuestionnaireContent = styled.div`
  margin-top: ${props => props.isExpanded ? '16px' : '0'};
  padding-top: ${props => props.isExpanded ? '16px' : '0'};
  border-top: ${props => props.isExpanded ? '1px solid #e0e0e0' : 'none'};
  display: ${props => props.isExpanded ? 'block' : 'none'};
`;

export const QuestionsTitle = styled.h4`
  margin: 0 0 16px 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;

export const QuestionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const QuestionItem = styled.li`
  padding: 12px 16px;
  color: #333;
  font-size: 14px;
  background: #f8f9fa;
  margin-bottom: 8px;
  border-radius: 4px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MoreOptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  color: #666;
  margin-right: 8px;
  
  &:hover {
    color: #0066ff;
  }

  svg {
    font-size: 20px;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 1000;
  border: 1px solid #e0e0e0;
  min-width: 120px;
  overflow: hidden;
`;

export const MenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
  
  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
  
  &:hover {
    background: #f5f5f5;
  }

  svg {
    font-size: 18px;
    color: ${props => props.isDelete ? '#DC3545' : '#0066ff'};
  }
`;

export const ExpandIcon = styled.span`
  color: #666;
  font-size: 24px;
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const BreadCrumbContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`;

import { useState } from "react";

const useFilters = () => {
  const [selectedMake, setSelectedMake] = useState([]);
  const [selectedModel, setSelectedModels] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedAngle, setSelectedAngle] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [make, setMake] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [types, setTypes] = useState([]);
  const [angles, setAngles] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  const [sizes, setSizes] = useState(["640"]);

  const filters = {
    selectedMake,
    selectedModel,
    selectedYear,
    selectedType,
    selectedAngle,
    selectedBackground,
    selectedSize,
    make,
    models,
    years,
    types,
    angles,
    backgrounds,
    sizes,
  };
  
  const setters = {
    setMake: setSelectedMake,
    setModel: setSelectedModels,
    setYear: setSelectedYear,
    setType: setSelectedType,
    setAngle: setSelectedAngle,
    setBackground: setSelectedBackground,
    setSize: setSelectedSize,
    setFiltersData: {
      setMake,
      setModels,
      setYears,
      setTypes,
      setAngles,
      setBackgrounds,
      setSizes,
    },
  };

  return { filters, setters };
};

export default useFilters;
import React, { useState } from "react";
import { IconButton } from "../..";
import {
  Container,
  LeftPanel,
  RightPanel,
  Divider,
  IconContainer,
} from "./CollapsibleComponent.styled";

const CollapsibleComponent = ({ leftContent, rightContent }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <Container>
      <LeftPanel isCollapsed={isCollapsed}>
        {React.cloneElement(leftContent, { isCollapsed })}
      </LeftPanel>
      <Divider isCollapsed={isCollapsed} />
      <IconContainer isCollapsed={isCollapsed} onClick={toggleCollapse}>
        <IconButton icon={"collapseView"} height="22px" width="22px" />
      </IconContainer>
      <RightPanel isCollapsed={isCollapsed}>
        {React.cloneElement(rightContent, { isCollapsed })}
      </RightPanel>
    </Container>
  );
};

export default CollapsibleComponent;


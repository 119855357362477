import styled from "styled-components";

export const BreadcrumbContainer = styled.nav`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.fonts.family.inter};
  gap: 8px;
`;

export const StyledLink = styled.button`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.family.inter};
  color: ${(props) => props.theme.colors.black};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledIconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.family.inter};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colors.brightNavyBlue};
  }
`;

export const StyledSeparator = styled.span`
  color: ${(props) => props.theme.colors.black};
  pointer-events: none;
  text-decoration: none;
  margin: 0 4px;
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.family.inter};
  &::before {
    content: "/";
  }
`;

export const StyledEllipsis = styled.span`
  color: ${(props) => props.theme.colors.black};
  pointer-events: none;
  font-size: 12px;
  margin: 0 4px;
  font-family: ${(props) => props.theme.fonts.family.inter};
  &::before {
    content: "...";
  }
`;

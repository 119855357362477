import styled from "styled-components";

export const Titles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 12px;
  margin-inline: 10px;
`;

export const FieldMapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  height: 56px;

  > p {
    width: 200px;
    text-align: right;
  }
`;

export const CSVTableContainer = styled.div`
  margin-block: 15px;
`;

export const FileSelectContainer = styled.div`
  position: relative;
  margin-top: 10px;

  width: 100%;
  border-radius: 12px;
  background-color: ${(props) =>
    props.isDragOver ? props.theme.colors.culturedGray : "white"};
  border: dashed 2px ${(props) => props.theme.colors.clearGray};
  padding: 90px 24px;
  font-weight: 600;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  transition: background-color 0.3s ease, border 0.3s ease;
`;

export const SelectedFileContainer = styled(FieldMapperContainer)`
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.highClearGray};

  p {
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;

    > span {
      border-radius: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }

    > div {
      font-size: 0.9em;
      > span {
        font-size: 0.8em;
        font-weight: 300;
      }
    }
  }
`;

export const SimpleSelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldMapperGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

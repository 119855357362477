import React from "react";
import { BreadCrumbContainer } from "./EventName.styled";
import DynamicBreadcrumbs from "../../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs";

export const Breadcrumbs = ({ breadcrumbItems, currentPath }) => (
  <BreadCrumbContainer>
    <DynamicBreadcrumbs items={breadcrumbItems} currentPath={currentPath} />
  </BreadCrumbContainer>
);

export default Breadcrumbs;

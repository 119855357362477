import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CardContainer, CardHeader, Label, ViewDetails, CardContent } from "./AccordionCard.styled";

const AccordionCard = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <CardContainer>
      <CardHeader onClick={toggleAccordion}>
        <Label>{title}</Label>
        <ViewDetails>
          {isOpen ? "Hide details" : "View details"}
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ViewDetails>
      </CardHeader>
      <CardContent isOpen={isOpen}>{children}</CardContent>
    </CardContainer>
  );
};

export default AccordionCard;

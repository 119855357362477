import React from 'react';
import { CardContainer, Title, Value } from './InfoCard.styles';

const InfoCard = ({ title, value }) => {
  
  const formattedValue = new Intl.NumberFormat('en-US').format(value);

  return (
    <CardContainer>
      <Title>{title}</Title>
      <Value>{formattedValue || 0}</Value>
    </CardContainer>
  );
};

export default InfoCard;

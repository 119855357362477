import { styled } from "styled-components";

import MicrosoftIcon from "./assets/logo_microsoft.png";
import GoogleIcon from "./assets/logo_google.png";
import AppleIcon from "./assets/logo_apple.png";
import UserIcon from "./assets/user.svg";
import AccountIcon from "./assets/account.svg";
import AgentsIcon from "./assets/agents.svg";
import CampaignIcon from "./assets/campaign.svg";
import ChannelIcon from "./assets/channel.svg";
import DashboardIcon from "./assets/dashboard.svg";
import ImportsIcon from "./assets/imports.png";
import IntegrationIcon from "./assets/integration.svg";
import ConciergeIcon from "./assets/concierge_floating_button.svg";
import NotificationIcon from "./assets/notification.svg";
import LogoutIcon from "./assets/logout.svg";
import ContactIcon from "./assets/contacts.svg";
import BrainImage from "./assets/brain.png";
import WarningIcon from "./assets/warning.svg";
import CloseIcon from "./assets/x_icon.svg";
import ThumbsUpIcon from "./assets/thumbs_up.svg";
import ThumbsDownIcon from "./assets/thumbs_down.svg";
import Head1Icon from "./assets/head_1.svg";
import NewChatIcon from "./assets/new_chat.svg";
import ArrowRightIcon from "./assets/arrow_right.svg";
import DeleteRedIcon from "./assets/delete_red.svg";
import ArchiveIcon from "./assets/archive.svg";
import RenameIcon from "./assets/rename.svg";
import FavoriteIcon from "./assets/favorite.svg";
import EditPencilIcon from "./assets/edit_pencil.svg";
import EditBlue from "./assets/edit_blue.svg";
import NoteIcon from "./assets/notes.svg";
import AIIcon from "./assets/ai_icon.svg";
import ConversationIcon from "./assets/conversation.svg";
import ExpandContent from "./assets/expand_content.svg";
import InstructionsEditIcon from "./assets/instructions_edit_icon.svg";
import VisibilityIcon from "./assets/visibility_icon.svg";
import ReloadInstructions from "./assets/reload_instructions.svg";
import LogoAimsByAnn from "./assets/logo_aims_by_ann.svg";
import DeleteGrayIcon from "./assets/delete_gray.svg";
import FileIcon from "./assets/file_icon.svg";
import FileV2Icon from "./assets/file_v2.svg";
import CalendarIcon from "./assets/calendar_icon.svg";
import DownloadIcon from "./assets/download_icon.svg";
import ScheduleIcon from "./assets/schedule_icon.svg";
import SMSIcon from "./assets/sms_icon.svg";
import EmailIcon from "./assets/email_icon.svg";
import CircleAddIcon from "./assets/circle_add_icon.svg";
import DefaultTeamIcon from "./assets/default_team.svg";
import ArrowBackOutlined from "./assets/arrow_back_outlined.svg";
import ArrowForwardOutlined from "./assets/arrow_forward_outlined.svg";
import AnnIsotype from "./assets/ann_isotype.svg";
import UserAdmin from "./assets/user_admin.svg";
import MainTemplate from "./assets/main_template.svg";
import PencilCard from "./assets/ic_outline-edit.svg";
import Plus from "./assets/plus.svg";
import ExpandMoreOutlined from "./assets/expand_more_outlined.svg";
import PencilDots from "./assets/pencil_dots.svg";
import CarbonUserAvatarFilled from "./assets/carbon_user_avatar_filled.svg";
import MdiCompany from "./assets/mdi_company.svg";
import EllipseIndicatorActive from "./assets/ellipse_indicator_active.svg";
import EllipseIndicatorInactive from "./assets/ellipse_indicator_inactive.svg";
import ArrowDownIcon from "./assets/thick_arrow_down.svg";
import ArrowUpIcon from "./assets/thick_arrow_up.svg";
import TuneIcon from "./assets/tune.svg";
import Magnifier from "./assets/lupa.svg";
import ExpandMoreOutlinedBlack from "./assets/expand_more_outlined_black.svg";
import BracketsIcon from "./assets/brackets.svg";
import GearIcon from "./assets/gear.svg";
import GearIconBlue from "./assets/gearBlue.svg";
import ColoredTrashCanIcon from "./assets/colored_trashcan.svg";
import PaperUpload from "./assets/paper_upload.svg";

import AIEditSpark from "./assets/ai_edit_spark.svg";
import DeleteFilled from "./assets/delete_filled.svg";
import Info from "./assets/info.svg";
import AdmirationSign from './assets/admiration_sign.svg';
import Align from "./assets/align.svg";
import Sort from "./assets/sortby.svg";
import Duplicate from "./assets/duplicate_icon.svg";
import Edit from "./assets/edit_icon.svg";
import SettingsIcon from "./assets/settings.svg";
import RoundedSquareMail from "./assets/icon_mail.svg";
import DuplicateOutline from "./assets/duplicate_outline.svg";
import EditOutline from "./assets/edit_outline.svg";
import DeleteOutline from "./assets/delete_outline.svg";
import NotificationsOutlined from "./assets/icon_notification.svg";
import DotsIcon from "./assets/icons_dots.svg";
import ArrowRightOutlined from "./assets/next_page_icon.svg";
import ArrowLeftOutlined from "./assets/back_page_icon.svg";
import CloseIconGray from "./assets/x_icon_gray.svg";
import SearchIcon from "./assets/search_icon.svg";
import CloseLight from "./assets/x_icon_light.svg";
import Building from "./assets/building.svg";
import NewLogoWhite from "../../elements/icon/assets/new_logo_white.svg";
import Settings from "./assets/SettingsIcon.svg";
import UserAuthLogo from "./assets/userAuthLogo.svg";
import ResendPassword from "./assets/resend.svg";
import DeactivateIcon from "./assets/turn-off.svg";
import Chat from "./assets/chat.svg";
import Clip from "./assets/clip.svg";
import upArrowBlackBackground from "./assets/upArrowBlackBackground.svg";
import ExpandSidebar from "./assets/expand.svg";
import CollapseSidebar from "./assets/collapse.svg";
import CollapseView from "./assets/collapse_v2.svg"
import InfoButtonOutlined from "./assets/info_button_outlined.svg";
import InfoButtonOutlinedActive from "./assets/info_button_outlined_active.svg";
import TimeButtonOutlined from "./assets/time_button_outlined.svg";
import TimeButtonOutlinedActive from "./assets/time_button_outlined_active.svg";
import SmallBuilding from "./assets/small_building.svg";
import WordLogo from "./assets/word_logo.svg";
import ExcelLogo from "./assets/excel_logo.svg";
import PinOutlined from "./assets/pin_outlined.svg";
import ArrowWhiteUp from "./assets/arrow_white_up.svg";
import ArrowWhiteDown from "./assets/arrow_white_down.svg";
import Home from "./assets/home.svg";
import Insights from "./assets/insights.svg";
import WidgetChat from "./assets/widget_chat.svg";
import Avatar from "./assets/avatars.svg";
import CloseWhite from "./assets/x_icon_white.svg";
import ArrowWhiteRight from "./assets/arrow_white_right.svg";
import LogoutAvatar from "./assets/logout_avatar.svg";
import MobileMenu from "./assets/hamburguer_menu.svg";
import ArrowBackOutlinedBlack from "./assets/arrow_back_outlined_black.svg";
import BlueArrow from "./assets/blue_arrow.svg";
import NoDataAvailable from "./assets/no_data.svg";
import SendButton from "./assets/send_button.svg";
import BackWardArrow from "./assets/arrow_backward.svg";
import ForWardArrow from "./assets/arrow_forward.svg";
import Check from "./assets/check.svg";
import Support from "./assets/support.svg";
import Padlock from "./assets/padlock.svg";
import ArrowLeftBlue from "./assets/arrow_left_blue.svg";
import Phone from "./assets/phone.svg"
import MessageIcon from "./assets/messageIcon.svg";
import ArrowRightBlue from "./assets/long_arrow_right.svg";
import VectorSelect from "./assets/vector_select.svg";
import DataIcon from "./assets/data.svg";
import ArrowWhiteUp2 from "./assets/arrow_white-up2.svg";
import CancelSquare from "./assets/cancel_square.svg";
import AgentIcon from "./assets/agent-icon.svg";
import NotesOpen from "./assets/notes_open.svg"
import NotesClose from "./assets/notes_close.svg";
import Inventory from "./assets/inventory.svg";
import NoAgents from "./assets/no_agents.svg";

import { useMemo, useState } from "react";

import IconSms from "./assets/icon_sms.svg";
import IconEmail from "./assets/icon_email.svg";
import IconLess from "./assets/icon_less.svg";
import IconUpdate from "./assets/icon_update.svg";
import IconAdd from "./assets/icon_add.svg";
import IconInformation from "./assets/icon_information.svg";
import IconColored from "./assets/icon_colored.svg"

const IconEmun = {
  admirationSign: 'admirationSign',
  microsoft: "microsoft",
  google: "google",
  apple: "apple",
  user: "user",
  account: "account",
  agents: "agents",
  campaign: "campaign",
  channel: "channel",
  dashboard: "dashboard",
  imports: "imports",
  integration: "integration",
  notification: "notification",
  contacts: "contacts",
  logout: "logout",
  concierge: "concierge",
  brain: "brain",
  warning: "warning",
  close: "close",
  thumbsUp: "thumbsUp",
  thumbsDown: "thumbsDown",
  head1: "head1",
  newChat: "newChat",
  arrowRight: "arrowRight",
  deleteRed: "deleteRed",
  archive: "archive",
  rename: "rename",
  favorite: "favorite",
  file: "file",
  editPencil: "editPencil",
  note: "note",
  ai: "ai",
  conversation: "conversation",
  expand: "expand",
  instructionsEdit: "instructionsEdit",
  visibility: "visibility",
  fileV2: "fileV2",
  calendar: "calendar",
  download: "download",
  schedule: "schedule",
  sms: "sms",
  phone: "phone",
  messageIcon: "messageIcon",
  vectorSelect: "vectorSelect",
  email: "email",
  circleAdd: "circleAdd",
  reloadInstructions: "reloadInstructions",
  aimsByAnn: "aimsByAnn",
  defaultTeam: "defaultTeam",
  arrowBackOutlined: "arrowBackOutlined",
  arrowForwardOutlined: "arrowForwardOutlined",
  deleteGray: "deleteGray",
  annIsotype: "annIsotype",
  userAdmin: "userAdmin",
  mainTemplate: "mainTemplate",
  pencilCard: "pencilCard",
  plus: "plus",
  expandMoreOutlined: "expandMoreOutlined",
  pencilDots: "pencilDots",
  carbonUserAvatarFilled: "carbonUserAvatarFilled",
  mdiCompany: "mdiCompany",
  ellipseIndicatorActive: "ellipseIndicatorActive",
  ellipseIndicatorInactive: "ellipseIndicatorInactive",
  arrowDown: "arrowDown",
  arrowUp: "arrowUp",
  tuneIcon: "tuneIcon",
  magnifier: "magnifier",
  expandMoreOutlinedBlack: "expandMoreOutlinedBlack",
  gear: "gear",
  gearBlue: "gearBlue",
  brackets: "brackets",
  coloredTrashcan: "coloredTrashcan",
  paperUpload: "paperUpload",
  aiEditSpark: "aiEditSpark",
  deleteFilled: "deleteFilled",
  info: "info",
  align: "align",
  sort: "sort",
  duplicate: "duplicate",
  edit: "edit",
  settingsIcon: "settingsIcon",
  roundedSquareMail: "roundedSquareMail",
  duplicateOutline: "duplicateOutline",
  editOutline: "editOutline",
  deleteOutline: "deleteOutline",
  notificationsOutlined: "notificationsOutlined",
  dotsIcon: "dotsIcon",
  arrowRightOutlined: "arrowRightOutlined",
  arrowRightBlue: "arrowRightBlue",
  arrowLeftOutlined: "arrowLeftOutlined",
  closeIconGray: "closeIconGray",
  searchIcon: "search",
  closeLight: "closeLight",
  editBlue: "editBlue",
  building: "building",
  newLogoWhite: "newLogoWhite",
  settings: "settings",
  userAuthLogo: "userAuthLogo",
  resend: "resend",
  deactivate: "deactivate",
  chat: "chat",
  clip: "clip",
  upArrowBlackBackground: "upArrowBlackBackground",
  expandSidebar: "expandSidebar",
  collapseSidebar: "collapseSidebar",
  collapseView: "collapseView",
  infoButtonOutlined: "infoButtonOutlined",
  infoButtonOutlinedActive: "infoButtonOutlinedActive",
  timeButtonOutlined: "timeButtonOutlined",
  timeButtonOutlinedActive: "timeButtonOutlinedActive",
  smallBuilding: "smallBuilding",
  wordLogo: "wordLogo",
  excelLogo: "excelLogo",
  pinOutlined: "pinOutlined",
  arrowWhiteUp: "arrowWhiteUp",
  arrowWhiteDown: "arrowWhiteDown",
  home: "home",
  insights: "Insights",
  widgetChat: "WidgetChat",
  iconColored: "IconColored",
  avatar: "Avatar",
  support: "Support",
  closeWhite: "CloseWhite",
  arrowWhiteRight: "ArrowWhiteRight",
  logoutAvatar: "LogoutAvatar",
  mobileMenu: "MobileMenu",
  arrowBackOutlinedBlack: "ArrowBackOutlinedBlack",
  blueArrow: "blueArrow",
  noDataAvailable: "NoDataAvailable",
  sendButton: "SendButton",
  backWardArrow: "BackWardArrow",
  forWardArrow: "ForWardArrow",
  check: "Check",
  padlock: "Padlock",
  arrowLeftBlue: "ArrowLeftBlue",
  dataIcon: "DataIcon",
  iconSms: "iconSms",
  iconEmail: "iconEmail",
  arrowWhiteUp2: "ArrowWhiteUp2",
  cancelSquare: "cancelSquare",
  agentIcon: "AgentIcon",
  inventory: "Inventory",
  noAgents: "NoAgents",
  notesOpen: "NotesOpen",
  notesClose: "NotesClose,",
  iconLess: "IconLess",
  iconUpdate: "IconUpdate",
  iconAdd: "IconAdd",
  iconInformation: "IconInformation"
};

const IconStrategy = {
  iconColored: IconColored,
  admirationSign: AdmirationSign,
  microsoft: MicrosoftIcon,
  google: GoogleIcon,
  apple: AppleIcon,
  user: UserIcon,
  account: AccountIcon,
  agents: AgentsIcon,
  campaign: CampaignIcon,
  channel: ChannelIcon,
  dashboard: DashboardIcon,
  imports: ImportsIcon,
  integration: IntegrationIcon,
  notification: NotificationIcon,
  contacts: ContactIcon,
  logout: LogoutIcon,
  concierge: ConciergeIcon,
  brain: BrainImage,
  warning: WarningIcon,
  close: CloseIcon,
  thumbsUp: ThumbsUpIcon,
  thumbsDown: ThumbsDownIcon,
  head1: Head1Icon,
  newChat: NewChatIcon,
  arrowRight: ArrowRightIcon,
  arrowRightBlue: ArrowRightBlue,
  deleteRed: DeleteRedIcon,
  archive: ArchiveIcon,
  rename: RenameIcon,
  favorite: FavoriteIcon,
  file: FileIcon,
  editPencil: EditPencilIcon,
  note: NoteIcon,
  ai: AIIcon,
  conversation: ConversationIcon,
  expand: ExpandContent,
  instructionsEdit: InstructionsEditIcon,
  visibility: VisibilityIcon,
  fileV2: FileV2Icon,
  calendar: CalendarIcon,
  download: DownloadIcon,
  schedule: ScheduleIcon,
  sms: SMSIcon,
  phone: Phone,
  messageIcon: MessageIcon,
  vectorSelect: VectorSelect,
  email: EmailIcon,
  circleAdd: CircleAddIcon,
  reloadInstructions: ReloadInstructions,
  aimsByAnn: LogoAimsByAnn,
  defaultTeam: DefaultTeamIcon,
  arrowBackOutlined: ArrowBackOutlined,
  arrowForwardOutlined: ArrowForwardOutlined,
  deleteGray: DeleteGrayIcon,
  annIsotype: AnnIsotype,
  userAdmin: UserAdmin,
  mainTemplate: MainTemplate,
  pencilCard: PencilCard,
  plus: Plus,
  expandMoreOutlined: ExpandMoreOutlined,
  pencilDots: PencilDots,
  carbonUserAvatarFilled: CarbonUserAvatarFilled,
  mdiCompany: MdiCompany,
  ellipseIndicatorActive: EllipseIndicatorActive,
  ellipseIndicatorInactive: EllipseIndicatorInactive,
  arrowDown: ArrowDownIcon,
  arrowUp: ArrowUpIcon,
  tuneIcon: TuneIcon,
  magnifier: Magnifier,
  expandMoreOutlinedBlack: ExpandMoreOutlinedBlack,
  brackets: BracketsIcon,
  gear: GearIcon,
  gearBlue: GearIconBlue,
  coloredTrashcan: ColoredTrashCanIcon,
  paperUpload: PaperUpload,
  aiEditSpark: AIEditSpark,
  deleteFilled: DeleteFilled,
  info: Info,
  align: Align,
  sort: Sort,
  duplicate: Duplicate,
  edit: Edit,
  settingsIcon: SettingsIcon,
  roundedSquareMail: RoundedSquareMail,
  duplicateOutline: DuplicateOutline,
  editOutline: EditOutline,
  deleteOutline: DeleteOutline,
  notificationsOutlined: NotificationsOutlined,
  dotsIcon: DotsIcon,
  arrowRightOutlined: ArrowRightOutlined,
  arrowLeftOutlined: ArrowLeftOutlined,
  closeIconGray: CloseIconGray,
  search: SearchIcon,
  closeLight: CloseLight,
  editBlue: EditBlue,
  building: Building,
  newLogoWhite: NewLogoWhite,
  settings: Settings,
  userAuthLogo: UserAuthLogo,
  resend: ResendPassword,
  deactivate: DeactivateIcon,
  chat: Chat,
  clip: Clip,
  upArrowBlackBackground: upArrowBlackBackground,
  expandSidebar: ExpandSidebar,
  collapseSidebar: CollapseSidebar,
  collapseView: CollapseView,
  infoButtonOutlined: InfoButtonOutlined,
  infoButtonOutlinedActive: InfoButtonOutlinedActive,
  timeButtonOutlined: TimeButtonOutlined,
  timeButtonOutlinedActive: TimeButtonOutlinedActive,
  smallBuilding: SmallBuilding,
  wordLogo: WordLogo,
  excelLogo: ExcelLogo,
  pinOutlined: PinOutlined,
  arrowWhiteUp: ArrowWhiteUp,
  arrowWhiteDown: ArrowWhiteDown,
  home: Home,
  insights: Insights,
  widgetChat: WidgetChat,
  avatar: Avatar,
  support: Support,
  closeWhite: CloseWhite,
  arrowWhiteRight: ArrowWhiteRight,
  logoutAvatar: LogoutAvatar,
  mobileMenu: MobileMenu,
  arrowBackOutlinedBlack: ArrowBackOutlinedBlack,
  blueArrow: BlueArrow,
  noDataAvailable: NoDataAvailable,
  sendButton: SendButton,
  backWardArrow: BackWardArrow,
  forWardArrow: ForWardArrow,
  check: Check,
  padlock: Padlock,
  arrowLeftBlue: ArrowLeftBlue,
  dataIcon: DataIcon,
  iconSms: IconSms,
  iconEmail: IconEmail,
  arrowWhiteUp2: ArrowWhiteUp2,
  cancelSquare: CancelSquare,
  agentIcon: AgentIcon,
  noAgents: NoAgents,
  inventory: Inventory,
  notesOpen: NotesOpen,
  notesClose: NotesClose,
  iconLess: IconLess,
  iconUpdate: IconUpdate,
  iconAdd: IconAdd,
  iconInformation: IconInformation
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "0"};

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Icon = ({name, width, height, margin, className, dataTestId }) => {
  const [icons] = useState(IconEmun);

  const onExistName = useMemo(() => {
    return Object.keys(icons).includes(name);
  }, [icons, name]);

  return (
    <IconWrapper
      width={width}
      height={height}
      margin={margin}
      className={className}
      data-testid={dataTestId}
    >
      {onExistName && <img src={IconStrategy[name]} alt={""} />}
    </IconWrapper>
  );
};

export default Icon;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { observer } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import {
  MainContainer, TabsContainer, BreadCrumbContainer, MenuContainer,
  Title, UserColumnContainer, SideColumn, MainColumn, TitleName
} from "./UsersOverview.styled";
import { RoundedButton } from '../../../../elements';
import Tabs from '../../../../elements/v2/Tabs/Tabs';
import UserGeneral from './components/UserGeneral/UserGeneral';
import DeactivateIcon from "../../../../elements/icon/assets/turn-off.svg";
import DeleteIcon from "../../../../elements/icon/assets/delete_outline.svg";
import ResendPassword from "../../../../elements/icon/assets/resend.svg";
import EmailIcon from '../../../../elements/icon/assets/ic_outline-email.svg';
import ActionSelect from './components/ActionSelect/ActionSelect';
import ConfirmationWrapper from '../../AgentsTeam/Team/Components/ConfirmationWrapper/ConfirmationWrapper';
import SuccessModal from '../../../../elements/v2/SuccessModal/SuccessModal';
import UserPermission from './components/UserPermissions/UserPermissions';
import { useStore } from '../../../../hooks';
import { dummyPermissions } from '../../UsersPage/NewUserPage/consts';
import { ConfirmationModal } from '../../../../elements/v2/modals';
import { useLocation } from "react-router-dom";
import DynamicBreadcrumbs from '../../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs';

const UsersOverview = observer(() => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { listUserStore, authStore, newUser, permissionsSetupStore } = useStore();
  const { selectedAccount } = authStore;
  const [userData, setUserData] = useState({});
  const [originalUserData, setOriginalUserData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionConfig, setActionConfig] = useState(null);
  const [successModalContent, setSuccessModalContent] = useState({ isOpen: false, title: '', message: '' });
  const [displayChangesDetected, setDisplayChangesDetected] = useState(false);
  const [currentTab, setCurrentTab] = useState(0)

  const discardHandlerRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
      listUserStore.onClear()
      listUserStore.fetchUserData(userId, selectedAccount.id);
      listUserStore.fetchPermissionsCatalogUser()
      setOriginalUserData(listUserStore.userData);
  }, []);


  const handleSave = async () => {
    try {
      if(currentTab === 0)
        await listUserStore.onPutUser();
      else if(currentTab === 1)
        listUserStore.saveAccess(permissionsSetupStore.generateSaveStructure(listUserStore.originalUserPermissions));

      showSuccessMessage('Saved!', 'User details have been saved successfully.');
    } catch (error) {
      showErrorMessage("Error saving user");
    }
  };

  const handleDiscard = () => {
    listUserStore.fetchUserData(userId, selectedAccount.id);
  };

  const handleDeactivate = async () => {
    try {
      if (userData.active) {
        await listUserStore.onDeactivateUser(userId);
        setUserData({ ...userData, active: false });
        showSuccessMessage('Success!', 'User has been deactivated successfully.');
      }
    } catch (error) {
      showErrorMessage("Error deactivating user");
    }
  };

  const handleDelete = async () => {
    try {
      await listUserStore.onDeleteUser(listUserStore.userData.customer, userId);
      showSuccessMessage('Deleted!', 'The user has been successfully deleted.');
      navigate("/v2/users");
    } catch (error) {
      showErrorMessage("Error deleting user");
    }
  };

  const handleResendInvite = async () => {
    try {
      await Auth.resendSignUp(listUserStore.userData.email);
      showSuccessMessage('Resent!', 'The invitation email has been resent.');
    } catch (error) {
      let errorMessage = "Error resending invitation";
      if (error.code === "NotAuthorizedException") {
        errorMessage = "Can't resend confirmation code for this user: " + error.message;
      }
      showErrorMessage(errorMessage);
    }
  };

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPassword(listUserStore.userData.email);
      showSuccessMessage('Great!', 'A password reset email has been sent to this user.');
    } catch (error) {
      showErrorMessage("Error sending confirmation code");
    }
  };

  const showSuccessMessage = (title, message) => {
    setSuccessModalContent({
      isOpen: true,
      title,
      message,
    });

    setTimeout(() => {
      setSuccessModalContent({ isOpen: false, title: '', message: '' });
    }, 5000);
  };

  const showErrorMessage = (message) => {
    setSuccessModalContent({
      isOpen: true,
      title: 'Error',
      message,
    });

    setTimeout(() => {
      setSuccessModalContent({ isOpen: false, title: '', message: '' });
    }, 5000);
  };

  const actionToExecute = {
    reset: () => handleResetPassword(),
    deactivate: () => openConfirmationModal({
      title: 'Deactivate User',
      message: 'Are you sure you want to deactivate this user?',
      onConfirm: handleDeactivate,
      icon: 'deactivate'
    }),
    remove: () => openConfirmationModal({
      title: 'Remove User',
      message: 'Are you sure you want to delete this user?',
      onConfirm: handleDelete,
      icon: 'deleteOutline'
    }),
    resend: () => handleResendInvite()
  };

  const handleActionSelect = (name, value) => {
    if (actionToExecute[value]) {
      actionToExecute[value]();
    } else {
      showErrorMessage(`Action '${value}' is not defined.`);
    }
  };

  const openConfirmationModal = (config) => {
    setActionConfig(config);
    setIsModalOpen(true);
  };

  const confirmAction = () => {
    if (actionConfig && actionConfig.onConfirm) {
      actionConfig.onConfirm();
    }
    setIsModalOpen(false);
  };

  const actionOptions = useMemo(() => {
    const initialOptions = [
      { name: 'Reset Password', value: 'reset', icon: ResendPassword, availableFor: ['ENABLED'] },
      // { name: 'Deactivate User', value: 'deactivate', icon: DeactivateIcon, availableFor: ['ENABLED', 'PENDING'] },
      // { name: 'Activate User', value: 'deactivate', icon: DeactivateIcon, availableFor: ['DISABLED'] },
      { name: 'Remove User', value: 'remove', icon: DeleteIcon, availableFor: ['DISABLED'] },
      { name: 'Resend Invite', value: 'resend', icon: EmailIcon, availableFor: ['PENDING'] },
    ];

    return initialOptions.filter(option => option.availableFor.includes(listUserStore.userData.status))
  }, [listUserStore.userData.status])

  const tabs = [
    {
      label: 'General',
      content: <UserGeneral/>,
    },
    {
      label: 'Access',
      content: <UserPermission />,
    },
  ];

  const blockTabToggle = () => listUserStore.changeDetected; 

  const onToggleBlocked = () => {
    setDisplayChangesDetected(true)
  }

  const onResetToggleBlocked = () => {
    listUserStore.updateDetectedChanges(false)
    setDisplayChangesDetected(false)
  }

  const breadcrumbItems = [      
    { label: "Users", href: "/v2/users" },      
    { label: listUserStore.userData?.first_name }    
  ];


  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
        </BreadCrumbContainer>
        <MenuContainer>
          <RoundedButton width='60px' height='30px' fontSize='12px' outline={true} onClick={handleDiscard}>
            Discard
          </RoundedButton>
          <RoundedButton width='80px' height='30px' fontSize='12px' color='#2173FF' onClick={handleSave}>
            Save
          </RoundedButton>
        </MenuContainer>
      </MainContainer>

      <UserColumnContainer>
        <MainColumn>
          <TabsContainer>
            <Tabs showBorder={true} tabs={tabs} blockToggle={listUserStore.changeDetected} onToggleBlocked={onToggleBlocked} onToggleTab={setCurrentTab}/>
          </TabsContainer>
        </MainColumn>
        <SideColumn>
          <ActionSelect
            placeholder="Actions"
            options={actionOptions}
            handleAction={handleActionSelect}
          />
        </SideColumn>
      </UserColumnContainer>

      <ConfirmationWrapper
        isOpen={isModalOpen}
        actionConfig={actionConfig}
        onConfirm={confirmAction}
        onClose={() => setIsModalOpen(false)}
      />

      <SuccessModal
        isOpen={successModalContent.isOpen}
        icon="check_circle"
        onClose={() => setSuccessModalContent({ isOpen: false, title: '', message: '' })}
        title={successModalContent.title}
        message={successModalContent.message}
      />
      
      <ConfirmationModal
        isOpen={displayChangesDetected}
        title="Warning"
        message="Unsaved changes may be lost when switching tabs."
        icon="deleteFilled"
        onConfirm={onResetToggleBlocked}
        onClose={onResetToggleBlocked}
        onConfirmLabel='Understood'
        displayCancel={false}
      />
    </>
  );
});

export default UsersOverview;

import { useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks";
import { RoundedButton, Icon } from "../../../elements";
import { AgentsTabs } from "./components";
import { agentTypesArr, agentStatusArr, agentModifiedArr } from "./consts";
import FilterSelector from "../../../elements/v2/FilterSelector/FilterSelector";
import LinearProgress from '@mui/material/LinearProgress';
import { commonFilterProps } from "./consts";
import { useLocation } from "react-router-dom";

import {
  SearchIcon,
  InputField,
  AgentViewContainer,
  CustomGrid,
  CustomSearchInput,
  FilterContainter,
  ProgressFilterContainer
} from "./AgentView.styled";

import DynamicBreadcrumbs from "../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs";
import { BreadcrumbContainer } from "../UnifiedProfilePage/BaseUnifiedProfilePage.styled";

const AgentView = observer(() => {
  const navigate = useNavigate();
  const { agentStore, authStore, listUserStore } = useStore();
  const { selectedAccount } = authStore;
  const { viewAgentStore } = agentStore;
  const [agentType, setAgentType] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [agentStatus, setAgentStatus] = useState([]);
  const [agentModified, setAgentModified] = useState([]);


  const handleFilterChange = useCallback((filterType) => (event) => {
    const newValue = typeof event.target.value === "string"
      ? event.target.value.split(",")
      : event.target.value;

    const filterConfigs = {
      type: {
        setState: setAgentType,
        storeAction: viewAgentStore.setScopeFilter,
      },
      owner: {
        setState: setUsersData,
      },
      status: {
        setState: setAgentStatus,
        storeAction: viewAgentStore.setStatusFilter,
      },
      modified: {
        setState: setAgentModified,
        storeAction: viewAgentStore.setModifiedFilter,
      },
    };

    const config = filterConfigs[filterType];
    config.setState(newValue);
    if (config.storeAction) {
      config.storeAction(newValue);
    }
  }, [viewAgentStore]);

  const filteredData = useMemo(() => {
    const usersList = listUserStore.users.map((user) => `${user.first_name} ${user.last_name}`);
    return ["My Agents", ...usersList];
  }, [listUserStore.users]);

  const filterSelectorsConfig = useMemo(() => [
    {
      title: "Type",
      data: agentTypesArr(),
      minWidthForm: 92,
      minWidthPaper: 224,
      value: agentType,
      filterType: 'type'
    },
    {
      title: "Owner",
      data: filteredData,
      minWidthForm: 103,
      minWidthPaper: 263,
      value: usersData,
      filterType: 'owner'
    },
    {
      title: "Status",
      data: agentStatusArr(),
      minWidthForm: 102,
      minWidthPaper: 224,
      value: agentStatus,
      filterType: 'status'
    },
    {
      title: "Modified",
      data: agentModifiedArr(),
      minWidthForm: 118,
      minWidthPaper: 224,
      value: agentModified,
      filterType: 'modified',
      type: "single",
    }
  ], [agentType, usersData, agentStatus, agentModified, filteredData]);

  const handleClearAll = useCallback(() => {
    setAgentType([]);
    setUsersData([]);
    setAgentStatus([]);
    setAgentModified([]);
    viewAgentStore.clearAllFilters();
  }, [viewAgentStore]);

  const newButtonIcon = useMemo(() => ({
    element: <Icon name="arrowWhiteDown" width="12px" height="7.41px" />,
    position: 'right'
  }), []);

  useEffect(() => {
    listUserStore.onFetchUsers(selectedAccount.id);
  }, [listUserStore, selectedAccount.id]);

  useEffect(() => {
    if (authStore.selectedAccount?.id) {
      agentStore.onChangeAccount(authStore.selectedAccount.id);
    }
  }, [authStore.selectedAccount?.id, agentStore]);

  const location = useLocation();

  const breadcrumbItems = [
    { label: "Agents", href: "/v2/assistants" }
  ];
  return (
    <>
    <BreadcrumbContainer>
      <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
    </BreadcrumbContainer>

    <AgentViewContainer>
      <CustomGrid>
        {agentStore.isLoading && (
          <ProgressFilterContainer>
            <LinearProgress />
          </ProgressFilterContainer>
        )}
        {!agentStore.isLoading && (
          <FilterContainter>
            <CustomSearchInput>
              <SearchIcon>
                <Icon name="magnifier" height="20px" width="20px" />
              </SearchIcon>
              <InputField
                type="text"
                value={viewAgentStore.searchValue}
                placeholder="Search"
                onChange={viewAgentStore.onHandleSearchChange}
              />
            </CustomSearchInput>
            {filterSelectorsConfig.map((config) => (
              <FilterSelector
                key={config.title}
                {...commonFilterProps()}
                {...config}
                handleChange={handleFilterChange(config.filterType)}
              />
            ))}

            <RoundedButton
              width="86px"
              height="40px"
              kind="confirm"
              outline={true}
              fontWeight="normal"
              fontSize="14px"
              margin="0"
              borderRadius="8px"
              onClick={handleClearAll}
            >
              Clear all
            </RoundedButton>
          </FilterContainter>
        )}

        <div>
          <RoundedButton
            width="89px"
            height="40px"
            kind="confirm"
            fontSize="14px"
            margin="0"
            borderRadius="8px"
            onClick={() => navigate("/v2/assistants/wizard/new-agent")}
            icon={newButtonIcon}
          >
            New
          </RoundedButton>
        </div>
      </CustomGrid>
      <AgentsTabs />
    </AgentViewContainer>
    </>
  );
});

export default AgentView;
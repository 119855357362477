import React, { useState, useEffect, useMemo } from "react";
import { Select, MenuItem } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  DrawerHeader,
  DrawerContainer,
  StyledFormControl,
  StyledTextArea,
  ContentContainer,
  StyledFab,
  CloseIcon,
  DrawerFooter,
  StyledDrawer,
  FromContainer,
  StyledPaper,
  StyledInputLabel,
  Overlay,
  NotesLengthWrapper,
} from "./DynamicNotes.styled";
import { useStore } from "../../../hooks";
import { observer } from "mobx-react";
import { RoundedButton } from "../../../elements";
import { Icon } from "../../../elements";
import { useMediaQueries } from "../../../utils/mediaQueries";
import WarningModal from "../../modals/WarningModal";
import SuccessModal from "../SuccessModal/SuccessModal";

const DynamicNotes = observer(
  ({ headerSlot, footerSlot, noted, maxCharacterLimit = 5000 }) => {
    const { notesStore } = useStore();
    const [category, setCategory] = useState("");
    const { authStore } = useStore();
    const { userData } = authStore;
    const [note, setNote] = useState("");
    const { categories, selectedNote } = notesStore;
    const { isMobile } = useMediaQueries();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    const [successFullMessage, setSuccessFullMessage] = useState("");

    const currentLength = note.length;

    const checkSelectedNotes = useMemo(
      () => selectedNote && selectedNote.memos,
      [selectedNote],
    );

    useEffect(() => {
      if (!selectedNote) return;
      setCategory(selectedNote.category || "");
      setNote(selectedNote.memos?.[0]?.memo || "");
    }, [selectedNote]);

    const handleDrawerOpen = () => {
      notesStore.openNotesDrawer();
    };

    useEffect(() => {
      return () => {
        setCategory("");
        setNote("");
        notesStore.setSelectedNote({});
      };
    }, []);

    const handleDrawerClose = (event, shouldClean = true) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (shouldClean) {
        setCategory("");
        setNote("");
        notesStore.setSelectedNote({});
      }
      notesStore.closeNotesDrawer();
    };

    const handleChange = (event) => {
      setCategory(event.target.value);
    };

    const handleNoteChange = (event) => {
      const text = event.target.value;
      if (text.length <= maxCharacterLimit) {
        setNote(text);
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        if (!category || !note) {
          setWarningMessage("Please fill all fields");
          setShowWarningModal(true);
          handleDrawerClose(event);
          return;
        }

        let noteData;

        if (checkSelectedNotes) {
          noteData = {
            ...selectedNote,
            category,
            memos: selectedNote.memos.map((memo) => ({
              id: memo.id,
              note: memo.note,
              sender: memo.sender,
              memo: note,
              metadata: "{}",
            })),
          };
        } else {
          noteData = {
            category,
            owner: userData.id,
            noted,
            memos: [
              {
                sender: userData.id,
                memo: note,
              },
            ],
          };
        }
        await notesStore.updateNotes(noteData);
        if (notesStore.isSuccessful) {
          handleDrawerClose(event);
          checkSelectedNotes
            ? setSuccessFullMessage("Your note has been updated successfully.")
            : setSuccessFullMessage("Your note has been saved successfully.");
        }
      } catch (error) {
        setWarningMessage("There was an error saving your note");
        handleDrawerClose(event);
        setShowWarningModal(true);
      }
    };

    const categoryOptions = useMemo(
      () =>
        categories.map((category, index) => (
          <MenuItem key={index} value={category}>
            {category}
          </MenuItem>
        )),
      [categories],
    );

    return (
      <>
        <StyledFab
          isMobile={isMobile}
          onClick={handleDrawerOpen}
          $isOpen={notesStore.isNotesDrawerOpen}
        >
          {notesStore.isNotesDrawerOpen ? (
            <>
              <CloseIcon onClick={(e) => handleDrawerClose(e, false)}>
                <Icon name="notesClose" width={"28px"} height={"28px"} />
              </CloseIcon>
              <Icon name="notesOpen" width={"13.6px"} height={"16px"} />
            </>
          ) : (
            <Icon name="notesOpen" width={"13.6px"} height={"16px"} />
          )}
          <NoteAddIcon />
        </StyledFab>

        {notesStore.isNotesDrawerOpen && (
          <Overlay onClick={handleDrawerClose} />
        )}

        <StyledDrawer
          isMobile={isMobile}
          anchor="right"
          open={notesStore.isNotesDrawerOpen}
          onClose={handleDrawerClose}
          variant="persistent"
        >
          <DrawerContainer onSubmit={handleSubmit}>
            {headerSlot || (
              <DrawerHeader>
                <div>Notes</div>
              </DrawerHeader>
            )}
            <ContentContainer>
              <FromContainer>
                From:{" "}
                <span>
                  {userData.first_name || ""} {userData.last_name || ""}
                </span>
              </FromContainer>

              <StyledFormControl fullWidth size="small">
                <StyledInputLabel>Category</StyledInputLabel>
                <Select
                  value={category}
                  onChange={handleChange}
                  label="Category"
                  MenuProps={{
                    PaperProps: {
                      component: StyledPaper,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  {categoryOptions}
                </Select>
              </StyledFormControl>

              <StyledTextArea
                maxLength={maxCharacterLimit}
                minRows={10}
                placeholder="Start typing to leave a note..."
                value={note}
                onChange={handleNoteChange}
              />
              <NotesLengthWrapper
                isMaxLength={currentLength >= maxCharacterLimit}
              >
                <span>
                  {currentLength}/{maxCharacterLimit}
                </span>
              </NotesLengthWrapper>
            </ContentContainer>
            {footerSlot || (
              <DrawerFooter>
                <RoundedButton
                  width="78px"
                  height="40px"
                  kind="edit"
                  outline={false}
                  fontWeight="normal"
                  fontSize="14px"
                  margin="0 5px"
                  borderRadius="8px"
                  onClick={handleDrawerClose}
                >
                  Cancel
                </RoundedButton>

                <RoundedButton
                  type="submit"
                  width="65px"
                  height="40px"
                  kind="confirm"
                  fontWeight="normal"
                  fontSize="14px"
                  margin="0 5px"
                  borderRadius="8px"
                  disabled={!category || !note.trim()}
                >
                  Save
                </RoundedButton>
              </DrawerFooter>
            )}
          </DrawerContainer>
        </StyledDrawer>
        <WarningModal
          isOpen={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          title="Warning"
          subtitle={warningMessage}
          height="300px"
          width="400px"
        />
        <SuccessModal
          isOpen={notesStore.isSuccessful}
          onClose={() => notesStore.setIsSuccessful(false)}
          title="Great!"
          message={successFullMessage}
          autoClose={true}
        />
      </>
    );
  },
);

export default DynamicNotes;

import styled from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  height: 720px;

  & p {
    font-weight: bold;
  }
`;

export const ModalCustomHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  p {
    padding-inline-start: 10px;
    padding-block: 0px;
  }
`;

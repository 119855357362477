import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

import { DefaultStepper } from "../../../elements";
import { ConfirmationModal } from "../../../elements/v2/modals";
import { AgentInstructionsStep, AgentSetNameStep } from './components';

import { PageContainer, StepWrapper } from './NewAgentWizardView.styled';
import { useLocation } from "react-router-dom";
import DynamicBreadcrumbs from '../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs';
import { BreadcrumbContainer } from '../UnifiedProfilePage/BaseUnifiedProfilePage.styled';


const NewAgentWizardView = observer(() => {
  const navigate = useNavigate();
  const { changeTitle, agentStore } = useStore();
  const [ showConfirmCancel, setShowConfirmCancel ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const { newAgentStore } = agentStore;

  const redirectMainPage = useCallback(() => {
    navigate('/v2/assistants');
    newAgentStore.onClearNewAgent();
  }, [navigate, newAgentStore]);

  const location = useLocation();

  const breadcrumbItems = [
    { label: "Agents", href: "/v2/assistants" },
    { label: "New Agent" }    
  ];

  const onHandleFinish = useCallback(() => {
    newAgentStore.onCompleteCreation((newAgentId) => {
      if (newAgentId) {
        navigate(`/v2/assistants/playground?agent=${newAgentId}&customer=${newAgentStore.account}`);
      } else {
        console.error('Agent creation failed. Please try again.');
        setShowError(true);
      }
      newAgentStore.onClearNewAgent();
    });
  }, [newAgentStore, navigate]);

  const onHandleCancel = () => {
    setShowConfirmCancel(true);
  };

  const onConfirmCancel = useCallback(() => {
    setShowConfirmCancel(false);
    redirectMainPage();
  }, [redirectMainPage]);

  const onErrorModalClose = useCallback(() => {
    setShowError(false);
    redirectMainPage();
  }, [redirectMainPage]);

  return (

    <PageContainer>
      
      <BreadcrumbContainer>
        <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
      </BreadcrumbContainer>
      

      <ConfirmationModal 
        isOpen={showConfirmCancel}
        title="Cancel" 
        message="Precaution You are about to exit the agent creation process. You will lose unsaved information. Are you sure about this?"
        icon="deleteFilled"
        onConfirm={onConfirmCancel}
      />
      <ConfirmationModal 
        isOpen={showError}
        title="Error" 
        message="Agent creation failed. Please try again."
        icon="error"
        onConfirm={onErrorModalClose}
        displayCancel={false}
        onConfirmLabel="OK"
      />
      <DefaultStepper
        stepperActionsWidth={"494px"}
        stepperErrorWidth={"494px"}
        onFinish={onHandleFinish}
        status={newAgentStore.isLoading ? 'loading' : 'idle'}
        onCancel={onHandleCancel}
      >
        <DefaultStepper.Step
          isComplete={() => newAgentStore.onValidateTextField('instructions')}
          validationErrorLabel="Agent instructions is required!">
          <StepWrapper><AgentInstructionsStep /></StepWrapper>
        </DefaultStepper.Step>
        <DefaultStepper.Step
        isComplete={() => newAgentStore.onValidateTextField('name')}
          validationErrorLabel="Agent name is required!">
          <StepWrapper><AgentSetNameStep /></StepWrapper>
        </DefaultStepper.Step>
      </DefaultStepper>
    </PageContainer>
  );
});

export default NewAgentWizardView;

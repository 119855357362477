import React, { useEffect } from "react";
import { useStore } from "../../../hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import DynamicNotes from "../../../elements/v2/DynamicNotes/DynamicNotes";
import { NOTE_CATEGORIES_LIST } from "../../../utils/enums";

const BaseUnifiedProfile = ({ children }) => {
  const { authStore, unifiedCustomerProfileStore, assistanceUCPStore, changeTitle, alertStore, notesStore } = useStore();
  const { selectedAccount } = authStore;
  const [searchParams, setSearchParams] = useSearchParams();
  notesStore.setCategories(NOTE_CATEGORIES_LIST);
  const selectedAccountId = selectedAccount?.id;

  useEffect(() => {
    const segmentId = searchParams.get("id");
    if (selectedAccountId && segmentId) {
      assistanceUCPStore.resetTimelineState();
      assistanceUCPStore.fetchCustomerMessages(selectedAccountId, segmentId);
    }
  }, [assistanceUCPStore, selectedAccountId, searchParams]);

  useEffect(() => {
    if (selectedAccount) {
      alertStore.setAccountId(selectedAccount.id);
    }

    if (authStore.userId) {
      alertStore.setUserId(authStore.userId);
    }
  }, [selectedAccount, authStore.userId]);

  const navigate = useNavigate();
  changeTitle(null);

  useEffect(() => {
    unifiedCustomerProfileStore.onClearUCP();
    const segmentId = searchParams.get("id");
    alertStore.setSegmentId(segmentId);

    if (!segmentId) {
      navigate("/contacts");
    }

    // Fetch contact info
    unifiedCustomerProfileStore.fetchUCPData(
      selectedAccount.id,
      segmentId,
      "card_contact_info",
    );

    // Fetch garage info
    unifiedCustomerProfileStore.fetchUCPData(
      selectedAccount.id,
      segmentId,
      "card_garage",
    );

    // Fetch orders info
    unifiedCustomerProfileStore.fetchUCPData(
      selectedAccount.id,
      segmentId,
      "card_orders",
    );

    // Fetch appointments info
    unifiedCustomerProfileStore.fetchUCPData(
      selectedAccount.id,
      segmentId,
      "card_appointments",
    );

  }, [unifiedCustomerProfileStore]);

  useEffect(() => {
    alertStore.onClearUCP();
    alertStore.fetchNotificationsUCP(alertStore.accountId, alertStore.userId, null);
  }, [alertStore]);

  return (
    <>
      <DynamicNotes noted={alertStore.segmentId}/>
      {children}
    </>
  );
};

export default BaseUnifiedProfile;

import { useState, useEffect } from 'react';
import { useStore } from "../hooks";
import { DefaultValues } from "./enums";

/**
 * Hook to get and manage the owner data of a note
 * @param {string} ownerId - ID of the note owner
 * @param {Object} authStore - Instance of the AuthStore
 * @returns {string} Name of the owner or 'Unknown' if not available
 */

const useOwnerData = (ownerId) => {
  const [ownerName, setOwnerName] = useState('');
  const { authStore } = useStore();
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchOwnerData = async () => {
      try {
        if (ownerId) {
          await authStore.fetchUserData(ownerId);
          if (isMounted) {
            setOwnerName(authStore.currentUserFirstName);
          }
        }
      } catch (error) {
        if (isMounted) {
          setError(error);
          setOwnerName(DefaultValues.UNKNOWN);
        }
      }
    };

    fetchOwnerData();

    return () => {
      isMounted = false;
    };
  }, [ownerId, authStore]);

  return {
    ownerName: ownerName || DefaultValues.UNKNOWN,
    error,
    isLoading: !ownerName && !error
  };
};

export default useOwnerData;

import React, { useCallback, useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import { StyledContainer, TableWrapper, headCellStyle, bodyCellStyle, toolbarStyle, tableContainerStyle, tablePaperStyle } from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getDensity} from "../../../utils/GetDensity/GetDensity";
import { DefaultTheme as theme } from "../../../theme";

const VehiclePage = observer(() => {
    const { dataStore, authStore, annUnifiedCustomerProfileStore, unifiedCustomerProfileStore } = useStore();
    const { selectedAccount } = authStore;
    const navigate = useNavigate();
    const { isMobile, isTablet, isDesktop } = useMediaQueries();

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'year', desc: true }]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const density = useMemo(() => getDensity(isMobile, isTablet, isDesktop), [isMobile, isTablet, isDesktop]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await dataStore.getData({
                    customer: selectedAccount.id,
                    pageIndex: pagination.pageIndex,
                    pageSize: pagination.pageSize,
                    sortBy: sorting,
                    search: globalFilter,
                    source: 'VEHICLES',
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataStore]);


    const columns = useMemo(() => [
        { accessorKey: "make", header: "Make" },
        { accessorKey: "model", header: "Model" },
        { accessorKey: "year", header: "Year" },
        { accessorKey: "vin", header: "VIN", size: 200 },
        { accessorKey: "owner", header: "Owner" },
        { accessorKey: "color", header: "Color" },
    ], []);

    const onHandleUnifiedCustomerProfile = useCallback((vehicleId) => {
        const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
        navigate({
            pathname: urlForUnified,
            search: createSearchParams({ id: vehicleId }).toString(),
        });
    }, [authStore.selectedAccount, navigate]);

    const table = useMaterialReactTable({
        columns,
        data: dataStore.data !== null ? dataStore.data : [],
        manualSorting: true,
        manualGlobalFilter: true,
        rowCount: dataStore.totalRowCount || 0,
        initialState: {
            sorting,
            density: density,
        },
        muiTableContainerProps: { sx: tableContainerStyle },
        muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
        muiTableHeadCellProps: { sx: headCellStyle },
        muiTableBodyCellProps: { sx: bodyCellStyle },
        manualPagination: true,
        enableRowSelection: false,
        enableGrouping: false,
        enableTopToolbar: true,
        muiCircularProgressProps: {
            color: 'primary',
            thickness: 5,
            size: 55,
            sx: {
                color: theme.colors.activeBlue
            }
        },
        state: {
            pagination,
            globalFilter,
            sorting,
            isLoading,
        },
        onPaginationChange: setPagination,
        onGlobalFilterChange: (filter) => {
            setGlobalFilter(filter);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        onSortingChange: (sorting) => {
            setSorting(sorting);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        onPageSizeChange: (newPageSize) => {
            setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
        },
        getRowId: (row) => row.id,

        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                const vehicleId = row.original.contact_id;
                annUnifiedCustomerProfileStore.onClearUCP();
                unifiedCustomerProfileStore.changeUrl("Vehicles")
                onHandleUnifiedCustomerProfile(vehicleId);
            },
            style: { cursor: 'pointer' }
        }),

        renderRowActions: ({ row }) => (
            <Box>
                <Tooltip title="View">
                    <IconButton onClick={() => onHandleUnifiedCustomerProfile(row.original.contact_id)}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        muiTopToolbarProps: { sx: toolbarStyle },
        muiSearchTextFieldProps: {
            variant: 'outlined',
            placeholder: 'Search',
            InputProps: { style: { fontSize: '12px' } },
        },
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnResizing: false,
    });

    return (
        <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
            <TableWrapper>
                <TableWrapper>
                    <MaterialReactTable table={table} />
                </TableWrapper>
            </TableWrapper>
            {dataStore.isError && (
                <SuccessfulModal
                    isOpen={dataStore.isError}
                    onClose={dataStore.clearError}
                    title="Oops!"
                    subtitle="An error occurred."
                    subtitle2="Please try again later."
                />
            )}
        </StyledContainer>
    );
});

export default VehiclePage;
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { observer } from "mobx-react";
import { getConversationChatAssistant } from '../../graphql/queries';
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import ChatWindowComponent from './ChatWindowComponent';
import { ChatInput } from '../../elements';
import _ from "lodash";
import { ChatBoxContainer, ChatMessagesWrapper, ChatInputWrapper } from './ChatBox.styled';
import dayjs from 'dayjs'
import { useStore } from '../../hooks';

const ChatBox = observer(({ customerSelected, agentId, agentDomain, inputWidth, showNewChatButton, metadata = {}, initialMessage = null, handleMetadata, agentData = {} }) => {
    const { authStore, conversationsStore } = useStore()
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedAgent, setSelectedAgent] = useState("");
    const [selectedAgentData, setSelectedAgentData] = useState({});
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: ""
    });
    const [attachment, setAttachment] = useState("");
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [conversationID, setConversationID] = useState("");
    const [showStopButton, setShowStopButton] = useState(false);
    const subscriptionRef = useRef(null);
    const bottomRef = useRef(null);
    const textAreaRef = useRef();
    customerSelected && conversationsStore.setCustomerId(customerSelected);

    useEffect(() => {
        if (agentId) {
            setSelectedAgent({ id: agentId, domain: agentDomain });
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: agentId
            }));
        }
    }, [agentId])

    useEffect(() => {
        if (Object.keys(agentData).length) {
            setSelectedAgentData(agentData)
        }
    }, [agentData])

    useEffect(() => {
        if (agent_params.agent) {
            handleSendMessage(initialMessage, true)
        }
    }, [agent_params.agent])

    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
            const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]

            textArea.style.height = "0px";
            const scrollHeight = textArea.scrollHeight;

            textArea.style.height = scrollHeight + "px";
        }
    }, [newMessage]);

    const handleCleanThread = () => {
        setMessages([]);
        setAgentIsTyping(false);
        setConversationID("");
        setIsSendMessageClicked(false);
        setAttachment("");
        setShowStopButton(false);
    }

    const setErrorInChat = () => {
        setAgentIsTyping(false);
        setMessages([
            ...messages,
            {
                message:
                    "Oops! Something went wrong. Please try again.",
                type: "received",
                time: dayjs().format('HH:mm'),
            },
        ]);
        setShowStopButton(false);
    }

    const handleSendMessage = async (sentMessage, hideMessage = false) => {
        if (!sentMessage && newMessage.trim() === "") return;

        setIsSendMessageClicked(true);

        let local_msgs = []
        local_msgs = _.union(messages, [])

        setNewMessage("");

        const params = { ...agent_params,
            message: sentMessage || newMessage,
            customer_id: customerSelected,
            attachment,
            conversation_id: conversationID,
            metadata: JSON.stringify(metadata)
        };

        let openAiAnswer = null;
        try {
            let resultConversationOpenAi = await API.graphql({
                query: getConversationChatAssistant,
                variables: { input: params },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            if (!resultConversationOpenAi.errors?.length) {
                setAgentIsTyping(true);
                const apiResponse = resultConversationOpenAi.data.getConversationChatAssistant;

                const parseResponseBody = (responseBody) => JSON.parse(responseBody);
                if (!hideMessage) {
                    local_msgs = _.union(local_msgs, [{ message: sentMessage || newMessage, type: "sent", time: dayjs().format('HH:mm') }])
                }
                let tmpMsg = "";
                let initialMessages = [...local_msgs];
                let isStreaming = false;
                setMessages(local_msgs);
                setAttachment("");

                // Subscribe to chat assistant response
                subscriptionRef.current = API.graphql({
                    ...graphqlOperation(onChatAssistantResponse, { id: apiResponse.id }),
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).subscribe({
                    next: ({ provider, value }) => {
                        const subApiResponse = value.data.onChatAssistantResponse;
                        const body = parseResponseBody(
                            subApiResponse.body
                        )
                        conversationsStore.setThreadId(body.thread_id);
                        conversationsStore.setRunId(body.run_id);
                        openAiAnswer = body.answer;
                        setAgentIsTyping(false);
                        setConversationID(body.conversation_id)
                        setShowStopButton(true);
                        const { status } = subApiResponse;
                        if (status === 'done' || status === 'guardrail') {
                            setShowStopButton(false);
                            if (!isStreaming) {
                                const newMessages = [...initialMessages, { message: openAiAnswer, type: "received", time: dayjs().format('HH:mm') }];
                                setMessages(newMessages)
                                initialMessages = [...newMessages];
                            }
                            subscriptionRef.current.unsubscribe();
                            tmpMsg = '';
                            initialMessages = [...messages];
                            isStreaming = false;
                            setIsSendMessageClicked(false);
                        } else if (status == 'stream') {
                            isStreaming = true;
                            tmpMsg += openAiAnswer
                            setMessages([...initialMessages, { message: tmpMsg, type: "received", time: dayjs().format('HH:mm') }])
                        } else if (status == 'metadata') {
                            handleMetadata && handleMetadata(JSON.parse(body.metadata))
                        }
                    },
                    error: (error) => {
                        console.error("Subscription error:", error);
                        setAgentIsTyping(false);
                        setShowStopButton(false);
                        setIsSendMessageClicked(false);
                        setErrorInChat();
                    }
                });
            } else {
                console.error("GraphQL errors:", resultConversationOpenAi.errors);
                setIsSendMessageClicked(false);
                setErrorInChat();
            }
        } catch (err) {
            console.error("Error in handleSendMessage:", err);
            setIsSendMessageClicked(false);
            setErrorInChat();
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    const handleStopClick = () => {
        if (subscriptionRef.current && (conversationsStore.runId && conversationsStore.threadId && conversationsStore.customerId)) {
            conversationsStore.stopResponse();
            subscriptionRef.current.unsubscribe();
            setAgentIsTyping(false);
            setShowStopButton(false);
            setIsSendMessageClicked(false);
        }
    };

    return (
        <ChatBoxContainer>
            <ChatMessagesWrapper>
                <ChatWindowComponent
                    selectedAgent={selectedAgentData}
                    agent={selectedAgent}
                    messages={messages}
                    bottomRef={bottomRef}
                    width={'inherit'} height={'inherit'} agentIsTyping={agentIsTyping} backgroundColor={'#FAFAFA'} />
            </ChatMessagesWrapper>

            <ChatInputWrapper>
                <ChatInput
                    customerId={authStore.selectedAccount.id}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e, callback) => {
                        if (e.key === 'Enter' && !e.shiftKey && !agentIsTyping) {
                            e.preventDefault();
                            if (!isSendMessageClicked) {
                                handleSendMessage();
                                callback('')
                            }
                        }
                    }}
                    newMessage={newMessage}
                    handleSendMessage={handleSendMessage}
                    onFileUploaded={setAttachment}
                    textAreaRef={textAreaRef}
                    disableSend={isSendMessageClicked}
                    onCleanThread={handleCleanThread}
                    width={inputWidth || "80%"}
                    showNewChatButton={showNewChatButton}
                    stop={showStopButton}
                    onStopClick={handleStopClick}
                />
            </ChatInputWrapper>
        </ChatBoxContainer>
    );
});

export default ChatBox;

import styled from "styled-components";

const RuleEngineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  gap: 16px;
  padding: 10px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.family.inter};

  &.segment-variant {
    background-color: ${(props) => props.theme.colors.lightBackground};
    margin-bottom: 16px;
  }
`;

const RuleEngineRow = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.isMobile || props.isTablet ? "column" : "row"};
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;

  &.segment-variant {
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const RuleSelectWrapper = styled.div`
  flex: 1;
  min-width: 150px;
  max-width: calc(25% - 16px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 12px;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: calc(33% - 12px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 8px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 8px;
  height: 24px;
  color: ${(props) => props.theme.colors.activeBlue};  

  a {
    color: ${(props) => props.theme.colors.activeBlue};
    font-size: 13px;
  }

  &.segment-variant {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    right: 0;
    top: -8px;

    a {
      font-size: 12px;
    }
  }
`;

const ConditionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 16px 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.clearGray};
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    position: relative;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: ${(props) => props.theme.colors.clearGray};
    top: 50%;
  }

  &::before {
    width: 50%;
    right: calc(50% + 10px);
  }

  &::after {
    width: 48%;
    right: 0;
    left: calc(50% + 10px);
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    margin: 8px 0;

    div {
      font-size: 10px;
      padding: 2px 8px;
    }

    &::before,
    &::after {
      width: 40%;
    }
  }
`;

const AddConditionButton = styled.button`
  background-color: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.darkGray};
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
    color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

export {
  RuleEngineWrapper,
  RuleEngineRow,
  RuleSelectWrapper,
  CloseButtonWrapper,
  ConditionWrapper,
  AddConditionButton,
};

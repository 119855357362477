import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useStore } from "../../hooks";
import { SuccessfulModal } from "../../elements";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import { observer } from "mobx-react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { CustomGenericListStyle } from './ContactForm.styled'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StatusWrapper } from "../v2/Conversations/components/TriggersTab/TriggerTab.styled";
import { getDensity } from "../../utils/GetDensity/GetDensity";
import { useMediaQueries } from "../../utils/mediaQueries";
import { TopToolbar } from "../../elements/sharedStyles/TopToolbar";
import { MediaQuery } from "../../elements/sharedStyles/MediaQuery";

function sortContacts(contacts) {
  const sortedContacts = [...contacts];
  
  const isEmpty = (value) => {
    return value === null || 
           value === undefined || 
           value.toString().trim() === '';
  };

  const compareContacts = (a, b) => {
    const nameA = (a.contact_name || '').toLowerCase().trim();
    const nameB = (b.contact_name || '').toLowerCase().trim();
    
    // Check if either name is empty
    const aEmpty = isEmpty(nameA);
    const bEmpty = isEmpty(nameB);
    
    // If one is empty and the other isn't, empty goes last
    if (aEmpty && !bEmpty) return 1;
    if (!aEmpty && bEmpty) return -1;
    
    // If both are empty, maintain their relative position
    if (aEmpty && bEmpty) return 0;
    
    // Check if either starts with a number
    const aStartsWithNumber = /^\d/.test(nameA);
    const bStartsWithNumber = /^\d/.test(nameB);
    
    // Numbers should go after letters
    if (aStartsWithNumber && !bStartsWithNumber) return 1;
    if (!aStartsWithNumber && bStartsWithNumber) return -1;
    
    // Regular alphanumeric sorting for everything else
    return nameA.localeCompare(nameB);
  };

  return sortedContacts.sort(compareContacts);
}

const ContactForm = observer(() => {
  const { authStore, contactStore, annUnifiedCustomerProfileStore, unifiedCustomerProfileStore } = useStore();
  const navigate = useNavigate();

  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const { selectedAccount } = authStore;
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'contact_name', desc: false }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const density = useMemo(() => getDensity(isMobile, isTablet, isDesktop), [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    const loadContacts = async () => {
      if (selectedAccount) {
        setIsLoading(true);
        try {
          contactStore.getContacts({
            customer: selectedAccount,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortBy: sorting,
            search: globalFilter
          });
        } catch (error) {
          contactStore.setError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadContacts();
  }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, contactStore]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "contact_name",
        header: "Name",
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },
      {
        accessorKey: "contact_company_name",
        header: "Company",
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },
      {
        accessorKey: "contact_address",
        header: "Address",
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },
      {
        accessorKey: "contact_city",
        header: "City",
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },
      {
        accessorKey: "contact_postal_code",
        header: "Postal code",
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },
      {
        accessorKey: "contact_phone",
        header: "Phone",
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontSize: '12px'
          }
        },
      },

      {
        accessorKey: "contact_status",
        header: "Status",
        Cell: ({ cell }) => (
          <StatusWrapper className={cell.getValue()?.toLowerCase()}>
            {cell.getValue()}
          </StatusWrapper>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: contactStore.contacts !== null ? sortContacts(contactStore.contacts) : [],
    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: contactStore.totalRowCount || 0,
    initialState: {
      sorting,
      density: density,
    },
    showGlobalFilter: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    enableGrouping: false,
    enableTopToolbar: true,

    renderTopToolbarCustomActions: () => null,

    positionToolbarAlertBanner: 'none',

    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading: isLoading || isRefetching,
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        enableColumnVisibility: false,
      },
      'mrt-row-select': {
        enableColumnVisibility: false,
      },
      'mrt-row-numbers': {
        enableColumnVisibility: false,
      },
    },

    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        '& .MuiCheckbox-root': {
          transform: 'scale(0.6)',
        },
      },
      onClick: () => {
        annUnifiedCustomerProfileStore.onClearUCP();
        onHandleUnifiedCustomerProfile(row.original.id);
      },
    }),

    muiTableHeadCellProps: {
      sx: {
        '& .MuiCheckbox-root': {
          transform: 'scale(0.6)',
        },
      },
    },

    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
    },
    getRowId: (row) => row.id,
    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="View">
          <IconButton onClick={() => onHandleUnifiedCustomerProfile(row.id)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    muiTopToolbarProps: {
      component: TopToolbar,
    },
    muiTableContainerProps: {
      sx: MediaQuery
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
      InputProps: {
        style: { color: 'white' },
      },
    },
  });

  const onHandleUnifiedCustomerProfile = useCallback((segment) => {
    const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
    unifiedCustomerProfileStore.changeUrl("Contacts")
    navigate({
      pathname: urlForUnified,
      search: createSearchParams({ id: segment }).toString(),
    });
  }, [authStore.selectedAccount, navigate]);

  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <MaterialReactTable table={table} />
      {contactStore.isError && (
        <SuccessfulModal
          isOpen={contactStore.isError}
          onClose={() => contactStore.onClearError()}
          imageSrc={AdmirationSign}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />
      )}
    </Box>
    <CustomGenericListStyle />
    </>
  );
});

export default ContactForm;

import styled from "styled-components";

export const CardContainer = styled.div`
  background: ${(props) => props.theme.colors.primaryWhite };
  border: 1px solid ${(props) => props.theme.colors.highClearGray };
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const ViewDetails = styled.span`
  color: ${(props) => props.theme.colors.activeBlue };
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    font-size: 1.2rem;
  }
`;

export const CardContent = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding-top: 0.5rem;
`;

import React, { useState } from "react";
import { observer } from "mobx-react";
import { ContactsCSVReader, RoundedButton, SuccessfulModal } from "../../../../../elements";
import { HeaderContainer, ErrorSubTitles, ErrorList } from "./SpreadsheetContactsMapping.styled";
import { useStore } from "../../../../../hooks";
import { useContactValidation } from "./hooks/useContactValidation";
import { VALIDATION_MESSAGES } from "../../../../../utils/enums";

const SpreadsheetContactsMapping = observer(({showSendButton=false, onFileUpload}) => {
  const [isSaved, setIsSaved] = useState(false);
  const { contactSegmentStore } = useStore();
  const {
    validationErrors,
    setValidationErrors,
    validateFields,
    clearValidationErrors
  } = useContactValidation(contactSegmentStore);

  const formatErrorsToHTML = (errors) => {
    return (
      <ErrorList role="alert" aria-live="polite">
        {errors.map((error, index) => {
          if (typeof error === 'string') {
            return (
              <li key={index}>
                <ErrorSubTitles>{error}</ErrorSubTitles>
              </li>
            );
          }
          if (error.type === 'required') {
            return (
              <li key={index}>
                <ErrorSubTitles>
                  The following fields are required: {' '}
                  <span aria-label="Required fields">{error.fields.join(', ')}</span>
                </ErrorSubTitles>
              </li>
            );
          }
          if (error.type === 'invalid') {
            return (
              <li key={index}>
                <ErrorSubTitles>
                  The following fields contain invalid data:
                </ErrorSubTitles>
                <ul aria-label="Invalid fields">
                  {error.fields.map((field, i) => (
                    <li key={i}>{field.name}: {field.error}</li>
                  ))}
                </ul>
              </li>
            );
          }
          return null;
        })}
      </ErrorList>
    );
  };

  const onSave = () => {
    const errors = validateFields();
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }

    if (!isSaved) {
      contactSegmentStore.setHeaderMapping();
      setIsSaved(true);
    }
  };

  const onSend = async () => {
    try {
      await contactSegmentStore.uploadFileToS3();
      onFileUpload?.();
    } catch (error) {
      setValidationErrors([VALIDATION_MESSAGES.UPLOAD_ERROR(error.message)]);
    }
  };

  const handleCloseValidationModal = () => {
    clearValidationErrors();
  };

  return (
    <>
      <HeaderContainer role="banner">
        <b>Make sure your files are ready to upload before importing</b>

        {!isSaved && (
          <RoundedButton
            onClick={onSave}
            width="200px"
            disabled={!contactSegmentStore?.parsedContactList.length}
            aria-label="Save contact mapping"
          >
            Save
          </RoundedButton>
        )}

        {showSendButton && isSaved && (
          <RoundedButton
            onClick={onSend}
            width="200px"
            disabled={!contactSegmentStore?.parsedContactList.length}
            aria-label="Send contact data"
          >
            Send
          </RoundedButton>
        )}
      </HeaderContainer>

      <ContactsCSVReader
        isSaved={isSaved}
        setIsSaved={setIsSaved}
        aria-label="CSV file reader"
      />

      <SuccessfulModal
        isOpen={validationErrors.length > 0}
        onClose={handleCloseValidationModal}
        title={VALIDATION_MESSAGES.VALIDATION_MODAL.TITLE}
        subtitle={VALIDATION_MESSAGES.VALIDATION_MODAL.SUBTITLE}
        subtitle2={formatErrorsToHTML(validationErrors)}
        height="auto"
        width="500px"
        zIndex={true}
        role="dialog"
        aria-modal="true"
        aria-labelledby="validation-modal-title"
      />
    </>
  );
});

export default SpreadsheetContactsMapping;

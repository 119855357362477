import React, {useCallback, useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import {
  StyledContainer,
  TableWrapper,
  headCellStyle,
  bodyCellStyle,
  toolbarStyle,
  tableContainerStyle,
  tablePaperStyle
} from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Spinner from "../../../components/common/Spinner";
import {getDensity} from "../../../utils/GetDensity/GetDensity";
import { DefaultTheme as theme } from "../../../theme";

const AppointmentsPage = observer(() => {
  const { dataStore, authStore, unifiedCustomerProfileStore } = useStore();
  const navigate = useNavigate();
  const { selectedAccount } = authStore;

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'year', desc: true }]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const [isLoading, setIsLoading] = useState(false);
  const density = useMemo(() => getDensity(isMobile, isTablet, isDesktop), [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dataStore.getData({
          customer: selectedAccount.id,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
          sortBy: sorting,
          search: globalFilter,
          source: 'APPOINTMENTS',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataStore]);

  const columns = useMemo(() => [
    { accessorKey: "serviceType", header: "Service type" },
    { accessorKey: "appointmentDay", header: "Appointment day" },
    { accessorKey: "appointmentTime", header: "Appointment time" },
    { accessorKey: "description", header: "Description" },
    { accessorKey: "customer", header: "Customer" },
  ], []);

  const onHandleCustomerProfile = useCallback((appointmentId) => {
    const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
    navigate({
      pathname: urlForUnified,
      search: createSearchParams({ id: appointmentId }).toString(),
    });
  }, [authStore.selectedAccount, navigate]);

  const table = useMaterialReactTable({
    columns,
    data: dataStore.data !== null ? dataStore.data : [],
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: dataStore.totalRowCount || 0,
    initialState: {
      sorting,
      density: density,
    },
    muiTableContainerProps: { sx: tableContainerStyle },
    muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
    muiTableHeadCellProps: { sx: headCellStyle },
    muiTableBodyCellProps: { sx: bodyCellStyle },
    manualPagination: true,
    enableRowSelection: false,
    enableGrouping: false,
    enableTopToolbar: true,
    muiCircularProgressProps: {
      color: 'primary',
      thickness: 5,
      size: 55,
      sx: {
        color: theme.colors.activeBlue
      }
    },
    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading,
    },

    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
    },
    getRowId: (row) => row.id,


    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const appointmentId = row.original.contact_id;
        unifiedCustomerProfileStore.changeUrl("Appointments")
        onHandleCustomerProfile(appointmentId);
      },
      style: { cursor: 'pointer' }
    }),

    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="View">
          <IconButton onClick={() => onHandleCustomerProfile(row.original.contact_id)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    muiTopToolbarProps: { sx: toolbarStyle },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
      InputProps: { style: { fontSize: '12px' } },
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: false,
  });

  return (
    <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
      <TableWrapper>
          <TableWrapper>
            <MaterialReactTable table={table} />
          </TableWrapper>
      </TableWrapper>
      {dataStore.isError && (
        <SuccessfulModal
          isOpen={dataStore.isError}
          onClose={dataStore.clearError}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />
      )}
    </StyledContainer>
  );
});

export default AppointmentsPage
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ErrorList = styled.ul`
  font-size: 0.875rem;
  text-align: left;
  padding: 3% 0 0 0;
`

export const ErrorSubTitles = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin:0;

  & > span {
    font-weight: normal;
  }
`

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BreadcrumbContainer,
  StyledLink,
  StyledIconButton,
  StyledSeparator,
  StyledEllipsis,
} from "./DynamicBreadcrumbs.styled";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const formatLabel = (label) => {
  if (!label) {
    return '';
  }

  let formattedLabel = label.replace(/[-_]+/g, " ");
  
  formattedLabel = formattedLabel
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());

  if (label === label.toUpperCase()) {
    formattedLabel = formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
  }

  return formattedLabel;
};


const getBreadcrumbTitle = (item) => {
  return item.source ? `${item.label} (${item.source})` : item.label;
};

const DynamicBreadcrumbs = ({ items = [], currentPath, onNavigate, loading = false }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (href) => {
    if (onNavigate) {
      onNavigate(href);
    }
    navigate(href);
  };

  const renderBreadcrumbs = () => {
    if (loading) {
      return <StyledEllipsis aria-label="Loading">Loading...</StyledEllipsis>;
    }

    const formattedItems = items.map((item) => ({
      ...item,
      label: formatLabel(item.label),
    }));

    if (formattedItems.length <= 4) {
      return formattedItems.map((item, index) => (
        <React.Fragment key={index}>
          <StyledLink
            as="button"
            onClick={() => handleNavigation(item.href || "#")}
            isCurrent={item.href === currentPath}
            title={getBreadcrumbTitle(item)}
          >
            {item.label}
          </StyledLink>
          {index < formattedItems.length - 1 && <StyledSeparator />}
        </React.Fragment>
      ));
    }

    const [first, ...rest] = formattedItems;
    const lastTwo = rest.slice(-2);
    const middleItems = rest.slice(0, -2);

    return (
      <>
        <StyledLink
          as="button"
          onClick={() => handleNavigation(first.href || "#")}
          isCurrent={first.href === currentPath}
          title={getBreadcrumbTitle(first)}
        >
          {first.label}
        </StyledLink>
        <StyledSeparator />
        <StyledIconButton aria-label="Show more breadcrumbs" onClick={handleClick}>
          <MoreHorizIcon />
        </StyledIconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {middleItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleNavigation(item.href || "#")}>
              <StyledLink
                as="button"
                isCurrent={item.href === currentPath}
                title={getBreadcrumbTitle(item)}
              >
                {item.label}
              </StyledLink>
            </MenuItem>
          ))}
        </Menu>
        {lastTwo.map((item, index) => (
          <React.Fragment key={index}>
            <StyledSeparator />
            <StyledLink
              as="button"
              onClick={() => handleNavigation(item.href || "#")}
              isCurrent={item.href === currentPath}
              title={getBreadcrumbTitle(item)}
            >
              {item.label}
            </StyledLink>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <BreadcrumbContainer role="navigation" aria-label="Breadcrumb">
      {renderBreadcrumbs()}
    </BreadcrumbContainer>
  );
};

export default DynamicBreadcrumbs;

import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import {
  BreadCrumb,
  MarketingContainer,
  MarketingWrapper,
  MarketingWrapperHeader,
  CustomGenericListStyleDesktop,
  MessageActionsWrapper,
  TabButtonContainer,
  TabContainer,
  UploadFilesActionsWrapper,
  SegmentsActionsWrapper,
} from "./Marketing.styled";
import { RoundedButton } from "../../../../elements/button";
import Segments from "./Segments";
import Overview from "./Overview";
import Message from "./Message";
import { Icon, SuccessfulModal } from "../../../../elements";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { useSearchParams, useNavigate } from "react-router-dom";
import Spinner from "../../../../components/common/Spinner";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";
import IconSelect from "../../../../elements/v2/Selects/IconSelect/IconSelect";
import SuccessModal from '../../../../elements/v2/SuccessModal/SuccessModal';
import { ConfirmationModal } from "../../../../elements/v2/modals";
import AuditLogs from "./AuditLogs";
import { MarketingStatus } from "../../../../utils/enums";
import { useLocation } from "react-router-dom";
import DynamicBreadcrumbs from "../../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs";

const tabs = [
  {
    label: "Message",
  },
  {
    label: "Segments",
  },
  {
    label: "Overview",
  },
  {
    label: "Audit logs",
  },
];

export const MarketingTab = {
  Message: 0,
  Segments: 1,
  Overview: 2,
  AuditLogs: 3,
};

const Marketing = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { changeTitle, authStore, marketingStore, templateEditorStore, contactSegmentStore } = useStore();
  const [currentTab, setCurrentTab] = useState(MarketingTab.Message);
  const [clickedOption, setClickedOption] = useState(null);
  const { rulesStore } = contactSegmentStore;
  const { isMobile } = useMediaQueries();

  const location = useLocation();

  const actionsOptions = useMemo(() => [
    {
      name: "Cancel", 
      title: "Cancel", 
      icon: "cancelSquare", 
      onClick: () => {
        marketingStore.setMarketingAttribute("confirmationDialogMessage", "You're about to end the upcoming conversation. Do you want to continue?")
      },
      onConfirm: () => {
        marketingStore.clearConfirmationDialog();
        marketingStore.cancel(authStore.selectedAccount.id);
      },
      displayOnStatus: [MarketingStatus.ACTIVE]
    },
    {
      name: "Duplicate", 
      title: "Duplicate Conversation", 
      icon: "duplicateOutline", 
      onClick: () => {
        marketingStore.setMarketingAttribute("confirmationDialogMessage", `You are about to duplicate this conversation. This action will create an exact copy of the conversation. Are you sure you want to proceed?`)
      },
      onConfirm: () => {
        marketingStore.duplicate(marketingStore.marketingConversation)
      },
      displayOnStatus: [MarketingStatus.DRAFT, MarketingStatus.ACTIVE, MarketingStatus.CANCELLED]
    }
  ], [marketingStore])

  const breadcrumbItems = [
    { label: "Conversations", href: "/v2/conversations" },
    { label: "Marketing", href: "/v2/conversations" },
    { label: marketingStore?.nameRead }
  ];

  useEffect(() => {
    authStore.fetchAgents();
  }, [authStore]);

  useEffect(() => {
    marketingStore.clear();
    templateEditorStore.clearTemplate();
    contactSegmentStore.clear();

    const marketingId = searchParams.get("id");

    if (!marketingId) {
      navigate("/v2/conversations");
    }

    const id = `${marketingId}/${marketingId}`.replaceAll('#', "");
    templateEditorStore.setId(id);
    contactSegmentStore.setFileId(`${id}_contacts`);
    contactSegmentStore.init(authStore.selectedAccount.id)
    templateEditorStore.updateGeneralField("customer", authStore.selectedAccount.id, );
    templateEditorStore.updateGeneralField("s3FileRoute", "email_templates");
    contactSegmentStore.updateGeneralField("s3FileRoute", "marketing_conversations");
    templateEditorStore.updateGeneralField("to", "{{To}}")
    marketingStore.getMarketingConversations(authStore.selectedAccount.id, marketingId)
  }, []);

  useEffect(() => {
		templateEditorStore.updateGeneralField('sourceList', contactSegmentStore.emailTemplateEventSourceList)
	}, [contactSegmentStore.emailTemplateEventSourceList[0].fields.length])

  useEffect(() => {
    const actions = marketingStore.marketingConversation?.metadata?.actions
    const rules = marketingStore.marketingConversation?.metadata?.filters
    if(actions?.length){
      templateEditorStore.updateGeneralField(
        "customer",
        authStore.selectedAccount.id
      );
      templateEditorStore.setDefaultVariables();
      templateEditorStore.loadPayload(actions[0]);
      templateEditorStore.setId(`${searchParams.get("id")}/${searchParams.get("id")}`.replaceAll("#", ""));
      templateEditorStore.loadTemplate();
    }

    if(rules?.length && !Object.keys(contactSegmentStore.rulesStore?.rulesGroups).length){
      contactSegmentStore.rulesStore.loadExistingRules(rules)
      contactSegmentStore.updateAdvancedFilter()
    }
  }, [marketingStore.marketingConversation])

  useEffect(() => {
    let alertTimeout;
    if(marketingStore.successfulAlert.isOpen){
      alertTimeout = setTimeout(() => {
        marketingStore.setMarketingAttribute('successfulAlert', { isOpen: false, title: '', message: '' })
      }, 3000);
    }

    return () => {
      clearTimeout(alertTimeout)
    }
  }, [marketingStore.successfulAlert.isOpen])

  const onTabChange = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const actionsButtons = (
    <>
      {![MarketingStatus.ACTIVE, MarketingStatus.CANCELLED].includes(marketingStore.status) && <>
      <RoundedButton
        width="80px"
        height="40px"
        fontSize="0.875rem"
        kind={"publish"}
        disabled={!marketingStore.emailTemplateExportAction}
        onClick={() => {
          marketingStore.setMarketingAttribute("status", MarketingStatus.DRAFT)
          marketingStore.emailTemplateExportAction()
        }}
      >
        Draft
      </RoundedButton>
      <RoundedButton
        width="65px"
        height="40px"
        fontSize="0.875rem"
        kind={"blue"}
        disabled={!marketingStore.emailTemplateExportAction}
        onClick={() => {
          marketingStore.setMarketingAttribute("status", MarketingStatus.ACTIVE)
          marketingStore.emailTemplateExportAction()
      }}
      >
        Publish
      </RoundedButton>
      </>}
    </>
  );

  const getCorrectPaddingRight = () => {
    const isSegmentTab = currentTab === MarketingTab.Segments;
    if (isSegmentTab) {
      return rulesStore?.showRightPreview ? "470px" : "120px";
    }
    return "5px";
  };

  const onCloseConfirmationModal = () => {
    marketingStore.clearConfirmationDialog()
    setClickedOption(null)
  }

  return (
    <MarketingContainer isMobile={isMobile}>
      <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
      {!marketingStore.isLoading && !templateEditorStore.updatingTemplate  ? <MarketingWrapper paddingRight={getCorrectPaddingRight()}>
        <MarketingWrapperHeader>
          <TabContainer>
            <Tabs2
              tabs={tabs}
              onToggleTab={onTabChange}
              defaultTab={currentTab}
            ></Tabs2>
            <TabButtonContainer>
              {currentTab === MarketingTab.Message && (
                <MessageActionsWrapper>{actionsButtons}</MessageActionsWrapper>
              )}
              {currentTab === MarketingTab.Segments && (
                <SegmentsActionsWrapper>
                  {actionsButtons}
                </SegmentsActionsWrapper>
              )}
              {currentTab === MarketingTab.Overview && (
                <UploadFilesActionsWrapper>
                  <IconSelect 
                    placeholder="Actions" 
                    options={actionsOptions.filter(action => action.displayOnStatus.includes(marketingStore.status))}
                    setClickedOption={option => setClickedOption(option)}
                  />
                  {actionsButtons}
                </UploadFilesActionsWrapper>
              )}
            </TabButtonContainer>
          </TabContainer>

          <Tabs2.Outlet show={currentTab === MarketingTab.Message}>
            <Message />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === MarketingTab.Segments}>
            <Segments />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === MarketingTab.Overview}>
            <Overview setCurrentTab={setCurrentTab} />
          </Tabs2.Outlet>
          
          <Tabs2.Outlet show={currentTab === MarketingTab.AuditLogs}>
            <AuditLogs />
          </Tabs2.Outlet>

        </MarketingWrapperHeader>
      </MarketingWrapper>  : <Spinner/>}
      <CustomGenericListStyleDesktop />

      <SuccessfulModal
        isOpen={marketingStore.isError}
        onClose={() => marketingStore.onClearError()}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2="Please try again later."
        height='300px'
        zIndex={true}
      />

      <SuccessfulModal
        isOpen={marketingStore.isSuccessful}
        onClose={() => marketingStore.setMarketingAttribute('isSuccessful', false)}
        title="Success!"
        subtitle="Campaign has been saved successfully."
        height='200px'
        width='500px'
        zIndex={true}
        
      />

      <ConfirmationModal
        isOpen={clickedOption}
        title={clickedOption?.title}
        message={marketingStore.confirmationDialogMessage}
        icon={clickedOption?.icon}
        onConfirm={() => {
          clickedOption?.onConfirm?.()
          setClickedOption(null)
        }}
        onClose={onCloseConfirmationModal}
        onCancel={onCloseConfirmationModal}
        onConfirmLabel="Continue"
      />
      
      <SuccessModal
        isOpen={marketingStore.successfulAlert.isOpen}
        icon="check_circle"
        onClose={() => marketingStore.setMarketingAttribute('successfulAlert', { isOpen: false, title: '', message: '' })}
        title={marketingStore.successfulAlert.title}
        message={marketingStore.successfulAlert.message}
      />
    </MarketingContainer>
  );
});

export default Marketing;

import AgentTeamIndicatorItem from './AgentTeamIndicatorItem';

import {
  SelectorContentIndicators,
  SelectorContentIndicatorSeparator
} from "./AgentTeamSelector.styled";

const AgentTeamIndicators = ({status, company, scope}) => {
  return (
    <SelectorContentIndicators>
      <AgentTeamIndicatorItem
        icon={{
          name: status ? "ellipseIndicatorActive" : "ellipseIndicatorInactive",
          width: "7px",
          height: "7px"
        }}
        value={ status ? 'Active' : '------'}
      />
      <SelectorContentIndicatorSeparator />
      <AgentTeamIndicatorItem
       icon={{
        name: scope ? "ellipseIndicatorInactive" : "mdiCompany",
        width: scope ? "7px" : "15px",
        height: scope ? "7px" : "15px",
      }}
       value={scope ? scope.charAt(0).toUpperCase() + scope.slice(1) : company}
      />
    </SelectorContentIndicators>
  );
}

export default AgentTeamIndicators;

import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: 80% auto;
  margin-top: 20px;
`;

export const PaginationEntriesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 20px;

  & span {
    color: ${props => props.theme.colors.brilliantSilver};
    font-size: 14px;
    font-weight: 400;
  }

  & .showing-metadata {
    font-size: 12px;
    color: ${props => props.theme.colors.asphaltBlue};
  }
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  padding-inline-end: 10px;
`;

import React, { useState, useEffect } from 'react';
import {
    TabsContainer,
    TabsHeader,
    Tab,
    TabContent,
    TabSearchContainer,
    TabWrapper,
    ResponsiveButtonsContainer // Add this import
} from './CustomizedHomeChatTabs.styled';
import {
    ButtonsContainer,
    InfoContainer,
    TimeContainer
} from "../HomePage.styled";
import { Icon } from "../../../../elements";
import styled from 'styled-components';

const CustomizedHomeChatTabs = ({
                                    tabs,
                                    onToggleTab,
                                    showBorder,
                                    activeTab: controlledActiveTab,
                                    handleInfoTimeButtonClick,
                                    isSidebarDetailsHistoryExpanded,
                                    sidebarDetailsHistoryActiveTab,
                                    searchActive,
                                    isMobile
                                }) => {
    const [activeTab, setActiveTab] = useState(controlledActiveTab || 0);

    useEffect(() => {
        if (controlledActiveTab !== undefined) {
            setActiveTab(controlledActiveTab);
        }
    }, [controlledActiveTab]);

    const handleTabClick = (index) => {
        setActiveTab(index);
        onToggleTab && onToggleTab(index);
    };

    return (
        <TabsContainer>
            <TabsHeader showBorder={showBorder}>
                <TabWrapper>
                    {tabs.map((tab, index) => (
                        <Tab key={index} isActive={activeTab === index} onClick={() => handleTabClick(index)}>
                            {tab.label}
                        </Tab>
                    ))}
                </TabWrapper>
                {searchActive && (
                    <TabSearchContainer>
                        <ResponsiveButtonsContainer>
                            <InfoContainer data-testid="details-button" onClick={() => handleInfoTimeButtonClick(0)}>
                                {
                                    !isSidebarDetailsHistoryExpanded ?
                                        (<Icon name={"infoButtonOutlined"} height={'45px'} width={'45px'} />) :
                                        (sidebarDetailsHistoryActiveTab === 0 ? <Icon name={"infoButtonOutlinedActive"} height={'45px'} width={'45px'} /> : <Icon name={"infoButtonOutlined"} height={'45px'} width={'45px'} />)
                                }
                                <Icon name={""} height={'45px'} width={'45px'} />
                            </InfoContainer>

                            <TimeContainer     data-testid="details-history-button"onClick={() => handleInfoTimeButtonClick(1)}>
                                {
                                    !isSidebarDetailsHistoryExpanded ?
                                        (<Icon name={"timeButtonOutlined"} height={'45px'} width={'45px'} />) :
                                        (sidebarDetailsHistoryActiveTab === 1 ? <Icon name={"timeButtonOutlinedActive"} height={'45px'} width={'45px'} /> : <Icon name={"timeButtonOutlined"} height={'45px'} width={'45px'} />)
                                }
                            </TimeContainer>
                        </ResponsiveButtonsContainer>
                    </TabSearchContainer>
                )}
            </TabsHeader>
            <TabContent>
                {tabs.map((tab, index) => (activeTab === index && tab.content))}
            </TabContent>
        </TabsContainer>
    );
};

export default CustomizedHomeChatTabs;
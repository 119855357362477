import styled, { createGlobalStyle } from "styled-components";

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isMobile, isTablet, isDesktop }) =>
    isMobile ? "70vh" : isTablet ? "70vh" : isDesktop ? "80vh" : "80vh"};
  padding: 15px;
`;


export {
  CustomGenericListStyle,
  StyledBox
};

import React, { useEffect, useState } from "react";
import { useStore } from "../../../../hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { MainContainer, MenuContainer, ButtonsContainer } from "./EventName.styled";
import CollapsibleComponent from "../../../../elements/v2/Collapsible/CollapsibleComponent";
import { ContactContent } from "./ContactContent";
import { RulesContent } from "./RulesContent";
import { Breadcrumbs } from "./Breadcrumbs";

import { ConfirmationModal } from "../../../../elements/v2/modals";
import { RoundedButton, SuccessfulModal } from "../../../../elements";

export const ConversationTab = {
  General: 0,
  Rules: 1,
  Actions: 2,
};

const EventName = observer(() => {
  const { contactStore, viewConversationStore, authStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(ConversationTab.General);
  const [searchParams] = useSearchParams();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    viewConversationStore.clear();

    const triggerId = searchParams.get("id");
    const tabId = parseInt(searchParams.get("tabId"), 10);

    if (!isNaN(tabId)) setCurrentTab(tabId);

    if (!triggerId) {
      navigate("/v2/conversations");
      return;
    }

    viewConversationStore.setId(triggerId, authStore.selectedAccount?.id);
    viewConversationStore.getEventsCatalog();
  }, [searchParams, viewConversationStore, authStore, navigate]);

  const breadcrumbItems = [
    { label: "Conversations", href: "/v2/conversations" },
    { label: "Triggers", href: "/v2/conversations" },
    { label: viewConversationStore.eventNameRead },
  ];

  return (
    <>
      <MainContainer>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} currentPath={location.pathname} />
        <MenuContainer>
          {!viewConversationStore.isReadMode() && (
            <ButtonsContainer>
              <RoundedButton
                height="20px"
                width="79px"
                kind="secondary"
                onClick={() => viewConversationStore.draft()}
                disabled={viewConversationStore.isUpdating}
              >
                Draft
              </RoundedButton>
              <RoundedButton
                height="20px"
                width="89px"
                onClick={() => viewConversationStore.publish()}
                disabled={viewConversationStore.isUpdating}
              >
                Publish
              </RoundedButton>
            </ButtonsContainer>
          )}
        </MenuContainer>
      </MainContainer>

      <CollapsibleComponent
        leftContent={
          <ContactContent
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            viewConversationStore={viewConversationStore}
          />
        }
        rightContent={<RulesContent isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}  />}
      />

      <ConfirmationModal
        isOpen={viewConversationStore.warningMessage}
        title="Warning"
        message={viewConversationStore.warningMessage}
        icon="edit"
        onConfirm={() => viewConversationStore.clearWarning()}
        onClose={() => viewConversationStore.clearWarning()}
        onConfirmLabel="Understood"
        displayCancel={false}
      />

      {viewConversationStore.isError && (
        <SuccessfulModal
          isOpen={viewConversationStore.isError}
          onClose={() => viewConversationStore.onClearError()}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
          zIndex={true}
        />
      )}
    </>
  );
});

export default EventName;

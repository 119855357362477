import dayjs from 'dayjs';

export const DATE_FORMATS = {
  DEFAULT: 'DD/MM/YYYY',
  WITH_TIME: 'DD/MM/YYYY HH:mm',
  SHORT: 'DD/MM/YY',
  FULL: 'DD/MM/YYYY HH:mm:ss',
  MONTH_YEAR: 'MM/YYYY',
};

export const useDateFormat = (
  date,
  format = DATE_FORMATS.DEFAULT,
  invalidText = 'Invalid date'
) => {
  return dayjs(date).isValid()
    ? dayjs(date).format(format)
    : invalidText;
};
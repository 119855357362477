import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useStore } from "../../../../../../hooks";
import { SuccessfulModal } from "../../../../../../elements";
import AdmirationSign from '../../../../../../elements/icon/assets/admiration_sign.svg';
import { observer } from "mobx-react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { CustomGenericListStyle, StyledBox } from './ContactTable.styled';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { getDensity } from "../../../../../../utils/GetDensity/GetDensity";
import { useMediaQueries } from "../../../../../../utils/mediaQueries";
import { MediaQuery } from "../../../../../../elements/sharedStyles/MediaQueryCollapsible";
import { columns } from './columns';
import InfoCard from "../../../../../../elements/v2/InfoCard/InfoCard";

const ContactTable = observer(() => {
  const { authStore, contactStore, annUnifiedCustomerProfileStore, unifiedCustomerProfileStore } = useStore();
  const navigate = useNavigate();

  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const { selectedAccount } = authStore;
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'contact_name', desc: false }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const density = useMemo(() => getDensity(isMobile, isTablet, isDesktop), [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    const loadContacts = async () => {
      if (selectedAccount) {
        setIsLoading(true);
        try {
          contactStore.getContacts({
            customer: selectedAccount,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortBy: sorting,
            search: globalFilter
          });
        } catch (error) {
          contactStore.setError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadContacts();
  }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, contactStore]);

  const table = useMaterialReactTable({
    columns,
    data: contactStore.contacts ?? [],
    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: contactStore.totalRowCount ?? 0,
    initialState: {
      sorting,
      density: 'compact'
    },
    showGlobalFilter: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    enableGrouping: false,
    enableTopToolbar: true,

    positionToolbarAlertBanner: 'none',

    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading: isLoading || isRefetching,
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        enableColumnVisibility: false,
      },
      'mrt-row-select': {
        enableColumnVisibility: false,
      },
      'mrt-row-numbers': {
        enableColumnVisibility: false,
      },
    },

    muiTableContainerProps: {
      sx: {
        height: "100%",
        overflowY: "auto",
      },
    },
  

    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        '& .MuiCheckbox-root': {
          transform: 'scale(0.6)',
        },
      },
      onClick: () => {
        annUnifiedCustomerProfileStore.onClearUCP();
        onHandleUnifiedCustomerProfile(row.original.id);
      },
    }),

    muiTableHeadCellProps: {
      sx: {
        '& .MuiCheckbox-root': {
          transform: 'scale(0.6)',
        },
      },
    },

    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
    },
    getRowId: (row) => row.id,
    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="View">
          <IconButton onClick={() => onHandleUnifiedCustomerProfile(row.id)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTablePaperProps: {
      sx: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
    },
  });

  const onHandleUnifiedCustomerProfile = useCallback((segment) => {
    const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
    unifiedCustomerProfileStore.changeUrl("Contacts");
    navigate({
      pathname: urlForUnified,
      search: createSearchParams({ id: segment }).toString(),
    });
  }, [authStore.selectedAccount, navigate]);

  return (
    <>
      <StyledBox isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
        <InfoCard title="Contacts" value={contactStore.totalRowCount} />
        <MaterialReactTable table={table} />
        {contactStore.isError && (
          <SuccessfulModal
            isOpen={contactStore.isError}
            onClose={() => contactStore.onClearError()}
            imageSrc={AdmirationSign}
            title="Oops!"
            subtitle="An error occurred."
            subtitle2="Please try again later."
          />
        )}
      </StyledBox>
      <CustomGenericListStyle />
    </>
  );
});

export default ContactTable;

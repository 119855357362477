import styled from "styled-components";


export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const CenteredLabel = styled.div`
  display: grid;
  place-content: center;
  height: 425px;
  color: ${(props) => props.theme.colors.dhusarGray};
`;

export const UploadingPhoto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${props => props.theme.colors.highClearGray};
  padding-inline: 12px;
  padding-block: 16px;
  border-radius: 8px;
  cursor: pointer;
`

export const UploadingPhotoHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`


export const PhotoPreviewContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
`

export const PhotoPreview = styled.div`
  background-image: url(${props => props.photo_preview});
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
`

export const PhotoPreviewDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  & p {
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
  }
  .size {
    font-size: 10px;
    line-height: 12.1px;
    font-weight: 300;
    color: ${props => props.theme.colors.darkBlueGray}
  }
`


import { css, styled } from "styled-components";

export const DropdownContainer = styled.div`
    position: absolute;
    top: ${props => props.top};
    left: ${props => props.left};
    border: 1px solid ${props => props.theme.colors.clearGray};
    background-color: ${props => props.theme.colors.primaryWhite};
    max-width: 180px;
    border-radius: 8px;
    padding-block: 5px;
`;

export const DropdownItem = styled.div`
    padding-inline: 10px;
    padding-block: 5px;
    ${props => props.divisor && css`
        border-bottom: 1px solid ${props => props.theme.colors.clearGray};
    `};
    cursor: pointer;
    &:hover {
        font-weight: 500;
    }
`;
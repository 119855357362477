import { useEffect, useRef, useState } from "react";
import { DropdownContainer, DropdownItem } from "./Dropdown.styled";

const Dropdown = (props) => {
  const { items, show, setShow, children } = props;
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const dropdownRef = useRef(null);
  const childrenRef = useRef(null);

  useEffect(() => {
    const detectClickOutside = (e) => {
      if (
        show &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        childrenRef.current &&
        !childrenRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };

    const handleResize = () => {
      if (show) {
        setShow(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("click", detectClickOutside);
    return () => {
      window.removeEventListener("click", detectClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [show]);

  useEffect(() => {
    if (show && childrenRef.current) {
      const rect = childrenRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left - 140,
        top: rect.top - 200,
      });
    }
  }, [show]);

  return (
    <div ref={dropdownRef}>
      <div ref={childrenRef}>{children}</div>
      <div id="dropdown_container">
        {show && (
          <DropdownContainer
            left={position.left}
            top={position.top}
            ref={dropdownRef}
          >
            {items.map((item) => {
              return (
                <DropdownItem
                  data-type="dropdown_container_item"
                  divisor={item.divisor}
                  onClick={() => {
                    item.onClick();
                    setShow(false);
                  }}
                >
                  {item.value}
                </DropdownItem>
              );
            })}
          </DropdownContainer>
        )}
      </div>
    </div>
  );
};

export default Dropdown;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Spinner({ className, dataTestId }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={className} data-testid={dataTestId}>
      <CircularProgress />
    </div>
  );
}

export default Spinner;

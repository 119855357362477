import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { deleteAgent } from "../../../graphql/queries";

class ViewAgentStore {
  searchValue = '';
  statusFilter = [];
  scopeFilter = [];
  modifiedFilter = [];
  isLoading = false;
  isError = false;
  isConfirm = false;
  itemsPerPage = 9;
  currentPage = 1;
  availablePageSizes = [3, 6, 9, 12];

  constructor() {
    makeObservable(this, {
      searchValue: observable,
      statusFilter: observable,
      scopeFilter: observable,
      modifiedFilter: observable,
      currentPage: observable,
      isLoading: observable,
      isError: observable,
      isConfirm: observable,
      itemsPerPage: observable,
      onHandleSearchChange: action,
      onChangePage: action,
      onClearError: action,
      setStatusFilter: action,
      setScopeFilter: action,
      setModifiedFilter: action,
      handleConfirmDelete: flow,
      onChangeItemsPerPage: action,
      clearAllFilters: action,
});
  }

  onHandleSearchChange = (e) => {
    this.searchValue = e.target.value;
    this.currentPage = 1;
  }

  onChangePage = (_, page) => {
    this.currentPage = page;
  }

  onChangeItemsPerPage = (newSize) => {
    this.itemsPerPage = newSize;
    this.currentPage = 1;
  };

  setStatusFilter = (status) => {
    this.statusFilter = status;
  };

  setScopeFilter = (scope) => {
    this.scopeFilter = scope;
  };

  setModifiedFilter = (filter) => {
    this.modifiedFilter = filter;
  };

  clearAllFilters =() => {
    this.searchValue = '';
    this.statusFilter = [];
    this.scopeFilter = [];
    this.modifiedFilter = [];
    this.currentPage = 1; // Opcional: reiniciar también la página
  };

  onClearNewAgent() {
    this.isLoading = false;
    this.isError = false;
  }

  *handleConfirmDelete(assistant) {
    this.isLoading = true;
    this.isConfirm = false;
    if (assistant) {
      try {
        yield API.graphql({
          query: deleteAgent,
          variables: {
            input: {
              customer_id: assistant.customer_id,
              id: assistant.id
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        yield this.fetchAssistants();
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }

  onDeleteConfirmation = (value) => {
    this.isConfirm = value;
  }

  onClearError = () => {
    this.isError = false;
  };


}

export default ViewAgentStore;

import React from 'react';
import { TabsContainer, TabsHeader, Tab, TabContent, IconHeader } from './UnifiedProfileTabs.styled';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';
import { Icon } from '../../../../../elements';

const UnifiedProfileTabs = observer(({
  tabs,
  onTabChange,
  showBorder,
  width,
  blockToggle = false,
  onToggleBlocked,
  activeTab
}) => {
  const { authStore } = useStore();

  const handleTabClick = (tabLabel) => {
    if (!blockToggle) {
      onTabChange(tabLabel);
    } else {
      onToggleBlocked?.();
    }
  };

  return (
    <TabsContainer>
      <TabsHeader showBorder={showBorder} width={width} id="test1">
        {tabs.map((tab, index) => (
          (!tab.permission || (tab.permission && authStore.hasPermission(tab.permission.code, tab.permission.level))) &&
          <Tab
            key={index}
            isActive={activeTab === tab.label}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </Tab>
        ))}
        <IconHeader>
          <Icon name={"magnifier"} height={'18px'} width={'18px'} />
        </IconHeader>
      </TabsHeader>
      <TabContent id="test2">
        {tabs.map((tab, index) => (
          activeTab === tab.label &&
          (!tab.permission || (tab.permission && authStore.hasPermission(tab.permission.code, tab.permission.level))) &&
          <React.Fragment key={index}>
            {tab.content}
          </React.Fragment>
        ))}
      </TabContent>
    </TabsContainer>
  );
});

export default UnifiedProfileTabs;

import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  background: ${(props) => props.theme.colors.primaryWhite};
  padding: 8px 24px;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  overflow: hidden;
  max-height: 1.75rem;
  font-size: 0.625em;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 8px 16px;
    font-size: 0.75em;
  }
`;

const TabsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  padding: 5px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;


const BreadCrumbContainer = styled.div`
  order: 1;
  flex: 0 1 auto;
  align-self: auto;

  @media (max-width: 768px) {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  button:nth-child(2) {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.clearBlue};
    font-weight: 400;
  }

  @media (max-width: 768px) {
    gap: 8px;
    flex-wrap: wrap;

    button {
      flex: 1 0 auto;
      padding: 6px 12px;
    }
  }
`;

const MenuContainer = styled.div`
  order: 1;
  flex: 0 1 auto;
  align-self: auto;

  @media (max-width: 768px) {
    flex: 1 0 100%;
  }
`;

const Title = styled.h3`
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${(props) => (props.isMobile ? "auto" : "10px")};
  margin-top: ${(props) => (props.isMobile ? "50px" : "0px")};
  margin-left: ${(props) => (props.isMobile ? "20px" : "0px")};
  margin-bottom: ${(props) => (props.isMobile ? "20px" : "0px")};
  gap: 5px;

  button {
    flex: ${(props) => (props.isMobile ? "1 1 auto" : "0 0 auto")};
    
    font-size: 12px;
  }
`;

export {
  MainContainer,
  TabsContainer,
  BreadCrumbContainer,
  MenuContainer,
  Title,
  ButtonsContainer,
  ActionButtonsContainer
}
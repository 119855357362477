// Import necessary dependencies and components
import {API, graphqlOperation, Hub} from "aws-amplify";
import {CONNECTION_STATE_CHANGE} from '@aws-amplify/pubsub';
import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import {observer} from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

// Import custom components and utilities
import ChatWindowComponent from '../Chatbox/components/ChatWindowComponent';
import ChatQuestionsSection from './components/ChatQuestionsSection';
import ChatInput from '../../elements/input/ChatInput';
import { CustomSelect, RoundedButton } from "../../elements";
import Spinner from "../../components/common/Spinner";
import {getAgent, getConversationChatAssistant} from "../../graphql/queries";
import {onChatAssistantResponse} from "../../graphql/subscriptions";
import {useStore} from "../../hooks";
import { CustomizeQButton, HomeChatWrapper, ChatWindowWrapper, ChatInputWrapper, Separator } from "./HomeChatPage.styled";
import ChatHistory from "./components/ChatHistory";
import ChatEditDefaultQuestions from "./components/ChatEditDefaultQuestions";
import { BreadCrumbContainer, MainContainer, Title, MenuContainer } from "../v2/Conversations/EventName/EventName.styled";
import dayjs from 'dayjs'
import withPermission from '../../store/WithPermission';

// Listen for API connection state changes
Hub.listen('api', (data) => {
    const {payload} = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState
        // You can handle connection state changes here if needed
    }
});

// Main HomeChatPage component
const HomeChatPage = observer(() => {
    // Hooks for navigation and accessing global store
    const navigate = useNavigate()
    const { authStore, changeTitle, viewAgentStore } = useStore();
    const [searchParams] = useSearchParams();
    changeTitle(null);

    // State variables for managing chat UI and functionality
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [isChatQuestionsVisible, setIsChatQuestionsVisible] = useState(true);
    const [isChatQuestionEditVisible, setIsChatQuestionEditVisible] = useState(false);
    const [isLoadingConcierge, setIsLoadingConcierge] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: "",
    });
    const [attachment, setAttachment] = useState();
    const [chatWindowHeight, setChatWindowHeight] = useState(70);
    const [conversationID, setConversationID] = useState("");
    const [conciergeAgent, setConciergeAgent] = useState("");
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState('');
    const [selectedAgentData, setSelectedAgentData] = useState({});

    // Default conversation starter questions
    const defaultConversationStarters = [
        'What is your main purpose?',
        'How can you assist me?',
        'Tell me about your capabilities.',
        'What specific knowledge do you have available?',
    ]
    const [agentQuestions, setAgentQuestions] = useState(defaultConversationStarters);

    // Function to set conversation starters based on selected agent
    const setConversationStarters = (selectedAgent) => {
        if(selectedAgent?.conversation_starters.length && !selectedAgent?.conversation_starters.every(el => !el)){
            setAgentQuestions(selectedAgent.conversation_starters)
        }else{
            setAgentQuestions(defaultConversationStarters)
        }
    }

    // Function to fetch concierge agent
    const fetchConciergeAgent = async (customer) => {
        try {
            setIsLoadingConcierge(true);
            const response = await API.graphql({
                query: getAgent,
                variables: {input: {customer_id: customer, domain: "concierge" }},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            const agents = JSON.parse(response.data.getAgent.body).filter(agent => agent.provider === 'openai');
            const conciergeAgent = agents[0];
            setConciergeAgent(conciergeAgent)
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: conciergeAgent?.id,
            }));
            setConversationStarters(conciergeAgent)
        } catch (error) {
            console.error("Error getting Agents", error);
            console.log('Error fetching agents');
        } finally {
            setIsLoadingConcierge(false);
        }
    };

    // Handler for selecting a question
    const handleSelectQuestion = (selectedQuestion) => {
        handleSendMessage(selectedQuestion);
    };

    // Handler for selecting an agent
    const handleAgentSelect = (name, agent_id) => {
        const selectedAgent = authStore.agents.find(agent => agent.id === agent_id);
        setSelectedAgentName(name);
        setSelectedAgentData(selectedAgent);
        setAgent_params((prevParams) => ({
            ...prevParams,
            agent: selectedAgent?.id,
        }));
        setConversationStarters(selectedAgent)
    };

    // Refs for managing scroll and textarea
    const bottomRef = useRef(null);
    const textAreaRef = useRef();

    // Effect to fetch agents when selected account changes
    useEffect(() => {
        if(authStore.selectedAccount && authStore.selectedAccount.id){
            setSelectedCustomer(authStore.selectedAccount.id)
            authStore.fetchAgents();
        }
    }, [authStore.selectedAccount])

    // Effect to fetch concierge agent when selected customer changes
    useEffect(() => {
        if (selectedCustomer) {
            fetchConciergeAgent(selectedCustomer);
        }
    }, [selectedCustomer])

    // Effect to adjust chat window height based on textarea content
    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
          const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
    
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;

          setChatWindowHeight(Math.max(58, 74 - (100 * scrollHeight / window.innerHeight)))
          textArea.style.height = scrollHeight + "px";
        }
      }, [newMessage]);

    // Effect to handle agent selection from URL params
    useEffect(() => {
        const agentIdFromUrl = searchParams.get("agent");
        const selectedAgent = authStore.agents.find(agent => agent.id === agentIdFromUrl);
        const conciergeAgent = authStore.agents.find(agent => agent.domain === 'concierge');
        if (selectedAgent) {
            handleAgentSelect(selectedAgent.name, selectedAgent.id);
        } else if (conciergeAgent) {
            handleAgentSelect(conciergeAgent.name, conciergeAgent.id);
        }
    }, [searchParams, authStore.agents]);

    // Handler for sending messages
    const handleSendMessage = async (sentMessage) => {
        // ... (message sending logic)
    };

    // Handler for cleaning the chat thread
    const handleCleanThread = () => {
        if (!isChatQuestionEditVisible) {
            setMessages([]);
            setIsChatQuestionsVisible(true);
            setAgentIsTyping(false);
            setConversationID("");
        }
    }

    // Effect to scroll to bottom when messages change
    useEffect(() => {
        if(messages.length){
            scrollToBottom();
        }
    }, [messages]);

    // Function to scroll to bottom of chat
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    // Function to check permissions
    const checkPermission = (code, action = 'VIEW') => {
        const hasPermission = authStore.hasPermission(code, action);
        console.log(`Permission check: ${code}, ${action} - ${hasPermission ? 'Granted' : 'Denied'}`);
        return hasPermission;
    };

    // Protected components using withPermission HOC
    const ProtectedButton = withPermission(
        ({ onClick, children, ...props }) => <RoundedButton onClick={onClick} {...props}>{children}</RoundedButton>,
        'BUTTON_PERMISSION'
    );

    const ProtectedCustomizeQButton = withPermission(
        ({ onClick, children }) => <CustomizeQButton onClick={onClick}>{children}</CustomizeQButton>,
        'CUSTOMIZE_QUESTIONS_PERMISSION'
    );

    const ProtectedChatInput = withPermission(
        ({ ...props }) => <ChatInput {...props} />,
        'CHAT_INPUT_PERMISSION'
    );

    const SomeProtectedComponent = ({ someProp }) => (
        <div>This is a protected component. Prop value: {someProp}</div>
    );

    const ProtectedComponent = withPermission(SomeProtectedComponent, 'SOME_PERMISSION_CODE');

    // Render the component
    return (
        <>
        <MainContainer>
            <BreadCrumbContainer>
                <Title>{`Home - ${toJS(authStore?.selectedAccount)?.name}`}</Title>
            </BreadCrumbContainer>
            {authStore.isGroupSuperAdmin && checkPermission('SWITCH_TO_V2_PERMISSION') && (
                <MenuContainer>
                    <ProtectedButton width='150px' onClick={() => navigate('/home')}>Switch to V2</ProtectedButton>
                </MenuContainer>
            )}
        </MainContainer>
        <HomeChatWrapper>
            <CustomSelect
                selectedDefault={selectedAgentName}
                placeholder="Select Agent"
                width='20vw'
                margin='15px 0 15px 0'
                options={authStore.agents.map(a => ({ name: a.name, value: a.id }))}
                handleSelect={handleAgentSelect} />

            {isChatQuestionsVisible && (
                <ChatQuestionsSection onSelectQuestion={handleSelectQuestion} isLoading={!conciergeAgent} loadedQuestions={agentQuestions}/>
            )}

            {isChatQuestionEditVisible && (
                <ChatEditDefaultQuestions goBack={() => {setIsChatQuestionEditVisible(false); setIsChatQuestionsVisible(true)}}/>
            )}
            
            <ChatWindowWrapper>
                {!isChatQuestionsVisible && !isChatQuestionEditVisible && (
                    <ChatWindowComponent
                        selectedAgent={selectedAgentData}
                        showSpinner={showSpinner}
                        messages={messages}
                        bottomRef={bottomRef}
                        height={`${chatWindowHeight}vh`}
                        agentIsTyping={agentIsTyping}
                    />
                )}
            </ChatWindowWrapper>
                
            {!isChatQuestionEditVisible && <ChatInputWrapper>
                {conciergeAgent ? (
                   <>
                        <Separator>
                            {showSpinner && <Spinner className="spinner"/>}
                        </Separator>
                        <ProtectedChatInput
                            customerId={authStore.selectedAccount.id}
                            textAreaRef={textAreaRef}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={(e, callback) => {
                                if (e.key === 'Enter' && !e.shiftKey && !showSpinner) {
                                    e.preventDefault();
                                    handleSendMessage();
                                    callback('')
                                }
                            }}
                            showSpinner={showSpinner}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            onFileUploaded={setAttachment}
                            disableSend={isSendMessageClicked}
                            onCleanThread={handleCleanThread}
                        />
                        {checkPermission('CUSTOMIZE_QUESTIONS_PERMISSION') && (
                            <ProtectedCustomizeQButton onClick={() => {setIsChatQuestionsVisible(false); setIsChatQuestionEditVisible(!isChatQuestionEditVisible)}}>
                                Customize <br/> default questions
                            </ProtectedCustomizeQButton>
                        )}
                    </> 
                ):
                    <p>Concierge agent is not created yet.</p>
                }
            </ChatInputWrapper>}
        </HomeChatWrapper>
        
        {/* Permission check examples */}
        <div style={{ margin: '20px', padding: '20px', border: '1px solid #ccc' }}>
          <h3>Permission Check Examples:</h3>
          <p>BUTTON_PERMISSION: {checkPermission('BUTTON_PERMISSION') ? 'Granted' : 'Denied'}</p>
          <ProtectedButton onClick={() => alert('Button clicked!')}>
            Protected Button
          </ProtectedButton>

          <p>CHAT_INPUT_PERMISSION: {checkPermission('CHAT_INPUT_PERMISSION') ? 'Granted' : 'Denied'}</p>
          
          <p>CUSTOMIZE_QUESTIONS_PERMISSION: {checkPermission('CUSTOMIZE_QUESTIONS_PERMISSION') ? 'Granted' : 'Denied'}</p>
          
          <p>SWITCH_TO_V2_PERMISSION: {checkPermission('SWITCH_TO_V2_PERMISSION') ? 'Granted' : 'Denied'}</p>
        </div>
        </>
    );
});

export default HomeChatPage;

export const AgentDomain = {
  CONCIERGE: "concierge",
};

export const ApiResponseStatus = {
  DONE: "done",
  GUARDRAIL: "guardrail",
  STREAM: "stream",
  METADATA: "metadata",
};

export const MessaggeType = {
  SENT: "sent",
  RECEIVED: "received",
};

export const MarketingStatus = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  CANCELLED: "CANCELLED",
};

export const PermissionCodes = {
  USER_PERSONA_TAB: "USER_PERSONA_TAB",
  USER_PERSONA_FIELD: "USER_PERSONA_FIELD",
  USER_PERSONA_BUILDER: "USER_PERSONA_BUILDER",
};

export const PermissionLevels = {
  VIEW: "v",
  CREATE: "c",
  UPDATE: "u",
  DELETE: "d",
};

export const DefaultValues = {
  UNKNOWN: "Unknown",
};

export const NoteCategories = {
  GENERAL: "General",
  FOLLOW_UP: "Follow-Up",
  OPPORTUNITY: "Opportunity",
  URGENT: "Urgent",
  MANAGER_REVIEW: "Manager Review",
};

export const PHOTO_UPLOAD_STAGES = {
  IDLE: 0,
  PUTTING_FILE_NAME: 1,
  UPLOADING_FILE_TO_S3: 2,
  GETTING_IMAGE_FROM_PHOTO_URL: 3,
  FINISHED_PHOTO_UPLOAD: 4,
};

export const STAGE_PERCENTAGES = {
  [PHOTO_UPLOAD_STAGES.IDLE]: 0,
  [PHOTO_UPLOAD_STAGES.PUTTING_FILE_NAME]: 33,
  [PHOTO_UPLOAD_STAGES.UPLOADING_FILE_TO_S3]: 66,
  [PHOTO_UPLOAD_STAGES.GETTING_IMAGE_FROM_PHOTO_URL]: 90,
  [PHOTO_UPLOAD_STAGES.FINISHED_PHOTO_UPLOAD]: 100,
};

export const NotificationTab = {
  UnreadTab: 0,
  ReadTab: 1,
};

export const ItemStatus = {
  unread: "UNREAD",
  read: "READ",
};

export const AGENT_ROLES = ["assistant", "agent", "annaims"];

export const NOTE_CATEGORIES_LIST = Object.values(NoteCategories);

export const OriginVariables = {
  FIXED: 'FIXED',
  EVENT: 'EVENT'
}

export const DefaultFieldVariables = {
  TO: 'To',
  AGENT_NAME: 'agent_name',
  AGENT_POSITION: 'agent_position',
  COMPANY_NAME: 'company_name',
  CUSTOMER_NAME: 'customer_name'
};

export const DefaultVariables = [
  { name: "To", origin: OriginVariables.EVENT, variable: DefaultFieldVariables.TO, field: 'primary_email' },
  { name: "Agent Name", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.AGENT_NAME },
  { name: "Agent Position", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.AGENT_POSITION },
  { name: "Company Name", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.COMPANY_NAME },
  { name: "Customer Name", origin: OriginVariables.EVENT, variable: DefaultFieldVariables.CUSTOMER_NAME, field: 'first_name' },
];

export const FIELD_VALIDATIONS = {
  first_name: {},
  last_name: {},
  email: {
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    error: "Please enter a valid email address",
    required: true
  },
  company_name: {
    pattern: /^[\w\s\-&.]+$/,
    error: "Company Name contains invalid characters",
    required: false
  },
  address: {
    pattern: /^[\w\s\-,#.]+$/,
    error: "Address contains invalid characters",
    required: false
  },
  city: {
    pattern: /^[A-Za-z\s]+$/,
    error: "City should only contain letters",
    required: false
  },
  state: {
    pattern: /^[A-Za-z\s]+$/,
    error: "State should only contain letters",
    required: false
  },
  postal_code: {
    pattern: /^\d{5}(-\d{4})?$/,
    error: "Invalid postal code format",
    required: false
  },
  phone: {
    pattern: /^\+?(?:[\d()\s-]*\d[\d()\s-]*){10,}$/,
    error: "Phone number must have only numbers and at least 10 digits.",
    required: false
  }
};

export const VALIDATION_MESSAGES = {
  NO_MAPPINGS: "No field mappings defined. Please map at least one field.",
  NO_DATA: "No data to validate. Please upload a file with data.",
  EMAIL_REQUIRED: "Email is a required.",
  UPLOAD_ERROR: (message) => `Error uploading file: ${message}`,
  VALIDATION_MODAL: {
    TITLE: "Validation Errors",
    SUBTITLE: "There are issues in your file:",
  }
};

import React, { useEffect, useMemo } from "react";
import Widget from "./Widget";
import { NotesWrapper } from "./Widget.styled";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import Spinner from "../../../../../components/common/Spinner";
import NoDataAvailable from "./NoDataAvailable";
import NoteItem from "./NoteItem";
import dayjs from "dayjs";

const Notes = observer(() => {
  const { notesStore, authStore, alertStore } = useStore();

  const getHeight = (length) => {
    const heights = {
      0: "auto",
      1: "230px",
      2: "320px",
    };
    return heights[length] || "434px";
  };

  // Load notes on mount and when isSuccessful changes
  useEffect(() => {
    notesStore.fetchNotesUCP(authStore.userData.id, alertStore.segmentId);
  }, [notesStore, authStore.userData.id, alertStore.segmentId, notesStore.isSuccessful]);

  // Memoized filtered and sorted notes
  const filteredNotes = useMemo(() => {
    if (notesStore.notes?.length > 0) {
      return notesStore.notes
        .filter((note) => note.owner === authStore.userData.id)
        .sort((a, b) => dayjs(b.last_modified).valueOf() - dayjs(a.last_modified).valueOf());
    }
    return [];
  }, [notesStore.notes, authStore.userData.id]);

  const renderContent = () => {
    if (notesStore.isLoading) {
      return <Spinner />;
    }

    if (filteredNotes.length === 0) {
      return <NoDataAvailable />;
    }

    return (
      <NotesWrapper>
        {filteredNotes.map((note, index) => (
          <NoteItem key={note.id || index} note={note} index={index} />
        ))}
      </NotesWrapper>
    );
  };

  return (
    <Widget
      title="Notes"
      height={getHeight(filteredNotes.length)}
      maxheight="434px"
      cardType="notes"
    >
      {renderContent()}
    </Widget>
  );
});

export default Notes;

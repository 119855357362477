import styled, { createGlobalStyle } from "styled-components";
import { InputText } from "../../../elements";
import Modal from "../../../elements/v2/SuccessModal/SuccessModal";
import { Avatar } from "@mui/material";
import InstructionsBox from "../../../elements/v2/InstructionsBox/InstructionsBox";


const UserContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 1rem;
  flex-direction: column;
`;

const UserDetails = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const UserActions = styled.div`
  flex: 1;
`;

const UserLabel = styled.label`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  font-weight: 600;
`;

const UserColumnContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  span {
    flex: 1;
  }
`;

const UserColumn = styled.div`
  margin-top: 20px;
`;

const UserPhone = styled.div`
  flex: 1;
`;

const InstructionsContainer = styled.div`
  width: 800px;
`;

const CustomInstructionsBox = styled(InstructionsBox)`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  color: ${(props) => props.theme.colors.darkGray};
  border: 1px solid ${(props) => props.theme.colors.clearGray};
`;

const ProfileInputText = styled(InputText)`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  color: ${(props) => props.theme.colors.darkGray};
  border: 1px solid ${(props) => props.theme.colors.clearGray};

  &:disabled {
    color: ${(props) => props.theme.colors.clearGray};
    background-color: ${(props) => props.theme.colors.primaryWhite};
    border: 1px solid ${(props) => props.theme.colors.clearGray};
  }
`;

const PhoneInput = styled(ProfileInputText)`
  background-color: ${(props) => props.theme.colors.white};
`;

const DisplayPhotoLabel = styled.label`
  background-color: ${(props) => props.theme.colors.highClearGray};
  height: 80px;
  width: 80px;
  display: flex;
  border-radius: 50%;
  border: 2px dashed ${(props) => props.theme.colors.black};
  cursor: pointer;
  justify-content: center;
`;

const DisplayPhotoInput = styled.input`
  display: none;
`;

const DisplayPhotoTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const DisplayPhotoSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const DisplayPhotoContainer = styled.div`
  margin: 1rem 0;
`;

const DisplayPhotoImageContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const BreadCrumb = styled.div`
  padding: 0.5rem 1rem;
  font-size: 0.625rem;
  font-weight: bold;

  & > span {
    color: ${(props) => props.theme.colors.activeBlue};
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 1rem;
`;

const SuccessModal = styled(Modal)`
  margin: 3rem 0;
`;

const CustomAvatar = styled(Avatar)`
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > div > div > div {
    gap: 1rem;
    font-size: 14px;
  }

  & > div > div > div > div {
    padding:  0.5625rem 1rem;
  }
`;

const CustomGenericListStyle = createGlobalStyle`
    #root > div > div:nth-child(3){
        padding: 1rem 0;
        margin-left: 0;
    }

    #root > div > div:nth-child(3) > div{
        margin-left: 1rem;
    }
`;

const UserPersonaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.875rem;
  margin-top: 1rem;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const TextArea = styled.textarea`
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.philippineSilver};
  border-radius: 0.5rem;
  height: 23.25rem;
  width: ${(props)=> props.isMobile ? '100%' : props.isTablet ? '80%' : '50%'};
  padding: 0.5625rem 1rem;
  resize: none;
`;

const Button = styled.button`
  width: 7.875rem;
  height: 2rem;
  border: 1px solid ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  border-radius: 0.5rem;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1700;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 85%;
  height: 753px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1510;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 14px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 17px;
  cursor: pointer;
  padding: 5px;
  color: ${(props) => props.theme.colors.clearGray};
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isMobile ? 'column' : 'row'};
  justify-content: space-between;
  ${(props) => props.isMobile && 'gap: 1rem'}
`

const ChatBoxContainer = styled.div`
  height: 95%;

   & > div > div:first-child {
     background: ${(props) => props.theme.colors.white};
     border: 1px solid ${(props) => props.theme.colors.clearGray};
     border-radius: 0.5rem;
   }

  & > div > div:first-child > div{
    background: transparent;
  }

  & > div > div:nth-child(2) {
    padding: 0.75rem 0;
  }

  & > div > div:nth-child(2) > div {
     width: 100%;
  }

  & > div > div:nth-child(2) > div > div > div:first-child {
    display: none;
  }
`

export {
  UserContent,
  UserWrapper,
  UserDetails,
  UserActions,
  UserLabel,
  UserColumnContainer,
  UserColumn,
  UserPhone,
  InstructionsContainer,
  ProfileInputText,
  DisplayPhotoInput,
  DisplayPhotoLabel,
  DisplayPhotoSubtitle,
  DisplayPhotoTitle,
  DisplayPhotoContainer,
  DisplayPhotoImageContainer,
  BreadCrumb,
  ActionButtonsContainer,
  SuccessModal,
  CustomAvatar,
  PhoneInput,
  CustomInstructionsBox,
  ProfileContainer,
  UserPersonaContainer,
  TextArea,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ChatBoxContainer,
  TabContainer,
  CustomGenericListStyle,
};

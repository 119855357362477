import { action, makeObservable, observable, flow } from "mobx";
import dayjs from "dayjs";
import { API, graphqlOperation } from "aws-amplify";
import {
  getAgent,
  getConversationChatAssistant,
  getCustomerMessages,
} from "../../graphql/queries";
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import { timeLineMockData2 } from "../../pages/v2/UnifiedProfilePage/components/Assistance/consts";

class AssistanceUCPStore {
  // Chat properties
  conversationHistory = [];
  newMessage = "";
  agentIsTyping = false;
  showStopButton = false;
  showSpinner = false;
  isSendMessageClicked = false;
  conversationID = "";
  attachment = "";
  isLoadingMessages = false;
  isLoadingConcierge = false;
  conciergeAgent = null;
  agentParams = {
    message: "",
    campaign_id: "",
    campaign_version: "",
    agent: "",
  };
  isError = false;
  agentResponseSubscription = null;
  runID = "";
  threadID = "";
  customer = "";

  // Timeline properties
  timelineMessages = [];
  loading = false;
  lastEvaluatedKey = null;

  // Scroll properties
  scrollPosition = 0;
  containerHeight = 0;
  containerScrollHeight = 0;

  // Shared properties
  selectedTab = "Chat";

  // Add these new properties
  showSuggestions = false;
  currentCardType = null;

  isCustomerMessagesError = false;
  constructor() {
    makeObservable(this, {
      // Chat observables
      conversationHistory: observable,
      newMessage: observable,
      agentIsTyping: observable,
      showStopButton: observable,
      showSpinner: observable,
      isSendMessageClicked: observable,
      conversationID: observable,
      attachment: observable,
      isLoadingConcierge: observable,
      isLoadingMessages: observable,
      conciergeAgent: observable,
      agentParams: observable,
      isError: observable,
      timelineMessages: observable,
      loading: observable,
      lastEvaluatedKey: observable,
      runID: observable,
      threadID: observable,
      customer: observable,
      // Scroll observables
      scrollPosition: observable,
      containerHeight: observable,
      containerScrollHeight: observable,
      isCustomerMessagesError: observable,

      // Shared observables
      selectedTab: observable,

      // Add these new observables
      showSuggestions: observable,
      currentCardType: observable,

      // Chat actions
      setConversationHistory: action,
      addMessage: action,
      setNewMessage: action,
      setAgentIsTyping: action,
      setShowStopButton: action,
      setShowSpinner: action,
      setIsSendMessageClicked: action,
      setConversationID: action,
      setAttachment: action,
      setIsLoadingConcierge: action,
      setConciergeAgent: action,
      setAgentParams: action,
      setIsError: action,
      setCustomer: action,
      cleanupConversation: action,
      resetTimelineState: action,

      // Timeline actions
      setTimelineMessages: action,
      setLoading: action,
      setLastEvaluatedKey: action,

      // Scroll actions
      setScrollPosition: action,
      setContainerDimensions: action,
      updateScroll: action,

      // Shared actions
      setSelectedTab: action,

      // Add these new actions
      setShowSuggestions: action,
      setCurrentCardType: action,

      // Flows
      fetchConciergeAgent: flow,
      handleSendMessage: flow,
      fetchCustomerMessages: flow,
    });
  }

  cleanupConversation = () => {
    this.setConversationHistory([]);
    this.setNewMessage("");
    this.setAgentIsTyping(false);
    this.setShowStopButton(false);
    this.setShowSpinner(false);
    this.setIsSendMessageClicked(false);
    this.setConversationID("");
    this.setAttachment("");
    this.runID = "";
    this.threadID = "";
    this.setShowSuggestions(false);
    this.setCurrentCardType(null);

    if (this.agentResponseSubscription) {
      this.agentResponseSubscription.unsubscribe();
      this.agentResponseSubscription = null;
    }
  };

  // Chat methods
  setConversationHistory(history) {
    this.conversationHistory = history;
  }

  addMessage(message) {
    this.conversationHistory.push(message);
  }

  setNewMessage(message) {
    this.newMessage = message;
  }

  setAgentIsTyping(isTyping) {
    this.agentIsTyping = isTyping;
  }

  setShowStopButton(isShow) {
    this.showStopButton = isShow;
  }

  setShowSpinner(show) {
    this.showSpinner = show;
  }

  setIsSendMessageClicked(clicked) {
    this.isSendMessageClicked = clicked;
  }

  setConversationID(id) {
    this.conversationID = id;
  }

  setAttachment(attachment) {
    this.attachment = attachment;
  }

  setIsLoadingConcierge(isLoading) {
    this.isLoadingConcierge = isLoading;
  }

  setConciergeAgent(agent) {
    this.conciergeAgent = agent;
  }

  setAgentParams(params) {
    this.agentParams = { ...this.agentParams, ...params };
  }

  setIsError(isError) {
    this.isError = isError;
  }

  setTimelineAgentIsTyping(isTyping) {
    this.timelineAgentIsTyping = isTyping;
  }

  // Shared methods
  setSelectedTab(tab) {
    this.selectedTab = tab;
  }

  resetTimelineState = () => {
    this.timelineMessages = [];
    this.lastEvaluatedKey = null;
    this.isCustomerMessagesError = false;
    this.isLoadingMessages = false;
  };

  *fetchConciergeAgent(customer) {
    try {
      this.setIsLoadingConcierge(true);
      const response = yield API.graphql({
        query: getAgent,
        variables: { input: { customer_id: customer, domain: "ucp" } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const agents = JSON.parse(response.data.getAgent.body).filter(
        (agent) => agent.provider === "openai",
      );
      const conciergeAgent = agents[0];

      if (conciergeAgent) {
        this.setConciergeAgent(conciergeAgent);
        this.setAgentParams({ agent: conciergeAgent?.id });
      } else {
        this.setConversationHistory([
          {
            message: "Error: UCP agent for this account does not exist.",
            type: "received",
            time: dayjs().format("HH:mm"),
          },
        ]);
      }
    } catch (error) {
      this.setIsError(true);
    } finally {
      this.setIsLoadingConcierge(false);
    }
  }

  *handleSendMessage(message, contactInfo, selectedAccount, isAnnUCP, ucpData) {
    const messageToSend = message || this.newMessage;
    if (messageToSend.trim() === "") return;
    this.setIsSendMessageClicked(true);

    this.addMessage({
      message: messageToSend,
      type: "sent",
      time: dayjs().format("HH:mm"),
    });

    this.setNewMessage("");

    const params = {
      ...this.agentParams,
      message: `Context information: customer = ${
        contactInfo.contact_full_name
      }, ${
        !isAnnUCP ? `, VIN = ${ucpData.selectedCar?.vin_no}` : ""
      }. This is a question based only on context customer information (do not query information or answer questions unrelated to it): ${messageToSend}.`,
      metadata: JSON.stringify({
        segment_id: isAnnUCP ? ucpData.ucp.segment_id : contactInfo.contact_id,
        customer_name: isAnnUCP
          ? ucpData.ucp.name
          : contactInfo.contact_full_name,
        vin: isAnnUCP ? ucpData.selectedCar?.vin : ucpData.selectedCar?.vin_no,
      }),
      customer_id: selectedAccount.id,
      attachment: this.attachment,
      conversation_id: this.conversationID,
    };

    try {
      let openAiAnswer = null;
      let resultConversationOpenAi = null;
      try {
        resultConversationOpenAi = yield API.graphql({
          query: getConversationChatAssistant,
          variables: { input: params },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (!resultConversationOpenAi.errors?.length) {
          this.setAgentIsTyping(true);
          const apiResponse =
            resultConversationOpenAi.data.getConversationChatAssistant;

          const parseResponseBody = (responseBody) => JSON.parse(responseBody);

          this.setAttachment("");

          this.agentResponseSubscription = API.graphql({
            ...graphqlOperation(onChatAssistantResponse, {
              id: apiResponse.id,
            }),
            authMode: "AMAZON_COGNITO_USER_POOLS",
          }).subscribe({
            next: ({ value }) => {
              const subApiResponse = value.data.onChatAssistantResponse;
              const body = parseResponseBody(subApiResponse.body);
              openAiAnswer = body.answer;
              this.setShowSpinner(false);
              this.setAgentIsTyping(false);
              this.setConversationID(body.conversation_id);
              this.threadID = body.thread_id;
              this.runID = body.run_id;
              this.setShowStopButton(true);
              if (subApiResponse.status === "done") {
                this.setShowStopButton(false);
                this.addMessage({
                  message: openAiAnswer,
                  type: "received",
                  time: dayjs().format("HH:mm"),
                });
                this.agentResponseSubscription.unsubscribe();
                this.agentResponseSubscription = null;
                this.setIsSendMessageClicked(false);
              } else if (subApiResponse.status === "guardrail") {
                this.addMessage({
                  message: openAiAnswer,
                  type: "received",
                  time: dayjs().format("HH:mm"),
                });
                this.agentResponseSubscription.unsubscribe();
                this.agentResponseSubscription = null;
                this.setIsSendMessageClicked(false);
              } else {
                const currentMessages = this.conversationHistory;
                const lastMessage = currentMessages[currentMessages.length - 1];
                if (lastMessage.type === "received") {
                  this.setConversationHistory([
                    ...currentMessages.slice(0, -1),
                    {
                      ...lastMessage,
                      message: lastMessage.message + openAiAnswer,
                    },
                  ]);
                } else {
                  this.addMessage({
                    message: openAiAnswer,
                    type: "received",
                    time: dayjs().format("HH:mm"),
                  });
                }
              }
            },
            error: (error) => {
              this.isError = true;
              this.setShowSpinner(false);
              this.setAgentIsTyping(false);
              this.addMessage({
                message: "Oops! Something went wrong. Please try again.",
                type: "received",
                time: dayjs().format("HH:mm"),
              });
            },
          });
        }
      } catch (err) {
        this.isError = true;
        this.addMessage({
          message: "Oops! Something went wrong. Please try again.",
          type: "received",
          time: dayjs().format("HH:mm"),
        });
      }
    } catch (error) {
      this.isError = true;
      if (
        error.errors &&
        error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
      ) {
        this.addMessage({
          message:
            "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. Meanwhile, let's continue with other questions you have?",
          type: "received",
          time: dayjs().format("HH:mm"),
        });
      }
    }
  }

  setTimelineMessages(messages) {
    this.timelineMessages = messages;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setLastEvaluatedKey(key) {
    this.lastEvaluatedKey = key;
  }

  // Scroll methods
  setScrollPosition = (position) => {
    this.scrollPosition = position;
  };

  setContainerDimensions = (height, scrollHeight) => {
    this.containerHeight = height;
    this.containerScrollHeight = scrollHeight;
  };

  updateScroll = (containerRef) => {
    if (containerRef) {
      const scrollTop = this.containerScrollHeight - this.scrollPosition;
      containerRef.scrollTop = scrollTop > 0 ? scrollTop : 0;
    }
  };

  setCustomer = (customer) => {
    this.customer = customer;
  };

  // Add these new methods
  setShowSuggestions(value) {
    this.showSuggestions = value;
  }

  setCurrentCardType(type) {
    this.currentCardType = type;
  }

  /**
   * Fetches customer messages. If there are no messages, it uses mock data.
   * @param {string} customer_id - The id of the customer
   * @param {string} contact_id - The id of the contact
   * @param {number} [last_evaluated_item=0] - The last evaluated item to start fetching from
   * @returns {Promise<void>}
   */
  *fetchCustomerMessages(customer_id, contact_id, last_evaluated_item = 0) {
    try {
      // Start loading process
      this.isLoadingMessages = true;
      this.isCustomerMessagesError = false;

      // Make a GraphQL request to fetch customer messages
      const response = yield API.graphql({
        query: getCustomerMessages,
        variables: {
          input: {
            customer_id, // Customer ID parameter
            contact_id, // Contact ID parameter
            last_evaluated_item, // Last evaluated item parameter
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS", // Authentication mode
      });

      // Parse the response data
      const data = JSON.parse(response.data?.getCustomerMessages?.body);
      // Get mock data in case of no real messages

      // Determine which messages to use, empty or real
      const messages = data?.messages || [];

      // Update timeline messages, appending if not the first request
      this.setTimelineMessages(
        !last_evaluated_item
          ? messages
          : [...this.timelineMessages, ...messages]
      );

      this.setLastEvaluatedKey(data?.last_evaluated_item);
    } catch (error) {
      this.isCustomerMessagesError = true;
    } finally {
      this.isLoadingMessages = false;
    }
  }
}

export default AssistanceUCPStore;

import React from 'react';
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks";
import { MainContainer, TabsContainer, BreadCrumbContainer, MenuContainer, Title } from "./Team.styled";
import Tabs from '../../../../elements/v2/Tabs/Tabs';
import TeamsTab from './Components/TeamsTab/TeamsTab';
import { RoundedButton } from '../../../../elements';
import AgentsTreePage from '../AgentsTree/AgentsTreePage';

const Team = observer(() => {

  const tabs = [
    {
      label: 'Agent Chart',
      content: (
        <AgentsTreePage/>
      ),
    },
    {
      label: 'Chat',
      content: (
        <TeamsTab />
      ),
    },
  ];

  const handleSave = () => {
    console.log('Save button clicked');
  };

  const handlePublish = () => {
    console.log('Publish button clicked');
  };

  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <Title>Agents > Teams</Title>
        </BreadCrumbContainer>
        <MenuContainer>
          <RoundedButton width='60px' fontSize='15px' outline={true} onClick={handleSave}>
            Save
          </RoundedButton>
          <RoundedButton width='80px' fontSize='15px' color='#2173FF' onClick={handlePublish}>
            Publish
          </RoundedButton>
        </MenuContainer>
      </MainContainer>
      <TabsContainer>
        <Tabs
          showBorder={true}
          tabs={tabs}
        />
      </TabsContainer>
    </>
  );
});

export default Team;

import styled from "styled-components";
import { InputText } from "../../../../../elements";

export const CapitalizeFirstLetter = styled.p`
    font-size: 1em;
    font-weight: 500;
    margin: 0;
    line-height: normal;
    &::first-letter { text-transform: uppercase; 
    }
`;

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 5rem 0;
`;

export const FormWrapper = styled.div`
    background-color: ${props => props.theme.colors.silverGrayThree};
    border-radius: 8px;
    width: 100%;
    max-width: 791px;
    margin: 2rem 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HorizontalFormWrapper = styled.div`
    background-color: ${props => props.theme.colors.silverGrayThree};
    border-radius: 8px;
    width: 791px;
    margin: 2rem 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const HorizontalInputsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    justify-content: center;
`;

export const LogoImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
`;

export const UserInput = styled(InputText)`
    border: 1px solid ${props => props.theme.colors.silverGrayTwo};
    width: ${props => props.width || '380px'};
    height: 40px;
    padding: 9px 16px;
    font-size: 14px;
    border-radius: 8px 1 0 0;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.silverGrayThree};
    box-shadow: none;
    opacity: 1;
    &:focus {
        outline: none;
        border-color: ${props => props.theme.colors.primary};
    }
`;

export const AgentsTeamDescriptionInput = styled.textarea`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '46px'};
    padding: 9px 16px;
    margin: 1rem 0;
    border-radius: 7px;
    ${(props) => props.theme.fonts.variant.light};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    border: 1px solid ${props => props.theme.colors.silverGrayTwo};
    height: 120px;
    font-size: 14px;
    resize: none;
`;

export const TitleStep = styled.div`
    ${props => props.theme.fonts.variant.semiBold};
    font-size: ${props => props.theme.fonts.size.large};
    color: ${props => props.theme.colors.primary};
    text-align: center;
    margin-bottom: 1rem;
`;

export const ErrorDiv = styled.div`
  color: ${props => props.theme.colors.red};
`;
import React, { useState, useMemo, useEffect } from "react";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { SuccessfulModal } from "../../../../elements";
import { TopToolbar } from "../../../../elements/sharedStyles/TopToolbar";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { getDensity } from "../../../../utils/GetDensity/GetDensity";
import { StyledButton, CompletedText, StyledContainer, StyledText, TableBodyRow, TablePaper, TableContainer, TableBodyCell, TableHeadCell } from "./AlertTab.styled";
import dayjs from "dayjs";

const AlertTab = observer(({ filters }) => {
  const { alertStore, authStore } = useStore();
  const { userId, selectedAccount } = authStore;
  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);

  const density = useMemo(
    () => getDensity(isMobile, isTablet, isDesktop),
    [isMobile, isTablet, isDesktop]
  );

  useEffect(() => {
    const loadAlerts = () => {
      
      if (selectedAccount.id && userId) {
        setIsLoading(true);
        try {
          alertStore.getAlerts({
            customerId: selectedAccount.id,  
            assignee: "",        
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortBy: sorting,
            search: globalFilter,
            filters: filters.length > 0 ? { status: filters } : {},
          });
        } catch (error) {
          alertStore.setError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    loadAlerts();
  }, [selectedAccount, userId, pagination, sorting, globalFilter, alertStore, filters]);
  

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ row, cell }) => (
          <StyledText isCompleted={row.original.status === "resolved"}>
            {cell.getValue()}
          </StyledText>
        ),
      },
      {
        accessorKey: "message",
        header: "Message",
        Cell: ({ row, cell }) => (
          <StyledText isCompleted={row.original.status === "resolved"}>
            {cell.getValue()}
          </StyledText>
        ),
      },
      {
        accessorKey: "created_date",
        header: "Creation Date",
        Cell: ({ row, cell }) => (
          <StyledText isCompleted={row.original.status === "resolved"}>
            {dayjs(cell.getValue()).format("MM/DD/YYYY")}
          </StyledText>
        ),
      },
      {
        header: "Status",
        Cell: ({ row }) => {
          const { status, id } = row.original;

          return status === "resolved" ? (
            <CompletedText>Completed</CompletedText>
          ) : (
            <StyledButton onClick={() => alertStore.setResolved(id)}>
              Mark as completed
            </StyledButton>
          );
        },
      },
    ],
    [alertStore]
  );

  const table = useMaterialReactTable({
    columns,
    data: alertStore.filteredAlerts || [],
    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: alertStore.totalRowCount || 0,
    initialState: { sorting, density },
    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading,
    },
    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({
        ...prev,
        pageSize: newPageSize,
        pageIndex: 0,
      }));
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.original.status === "resolved" ? 'white' : '#F2F7FF',
      }
    }),
    muiTablePaperProps: {
      component: TablePaper,
    },
    muiTopToolbarProps: {
      component: TopToolbar,
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
      InputProps: { style: { color: 'white' } },
    },
    muiTableContainerProps: {
      component: TableContainer,
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '12px'
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '12px'
      }
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
  });

  return (
    <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
      <MaterialReactTable table={table} />
      {alertStore.isError && (
        <SuccessfulModal
          isOpen={alertStore.isError}
          onClose={alertStore.onClearError}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />
      )}
    </StyledContainer>
  );
});

export default AlertTab;

import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: ${(props) => props.theme.colors.highClearGray};
  border-radius: 8px;
  padding: 2px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 120px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fonts.family.inter};
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const Value = styled.div`
  font-size: 12px;
`;

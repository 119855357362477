import styled from "styled-components";

export const TimelineWrapper = styled.div`
  overflow-y: auto;
  height: 100vh;
  padding: 1rem;
  font-family: ${(props) => props.theme.fonts.family.inter};
  font-size: 12px;
  max-width: 42rem;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  font-size: 12px;
  color: ${(props) => props.theme.colors.taupeGray};
  font-family: ${(props) => props.theme.fonts.family.inter};
  position: relative;

  span {
    background-color: ${(props) => props.theme.colors.white};
    padding: 0 0.5rem;
    z-index: 1;
  }

  &::before,
  &::after {
    content: "";
    height: 1px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    position: absolute;
    top: 50%;
    width: 45%;
  }

  &::before {
    left: 0;
    margin-right: 10px;
  }

  &::after {
    right: 0;
    margin-left: 10px;
  }
`;


export const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isAgent ? "flex-start" : "flex-end")};
  align-items: flex-start;
  margin: 1.5rem 0;
  position: relative;
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.family.inter};

  .assistant-avatar {
    width: 32px;
    height: 32px;
    margin-top: ${(props) => (props.isEmail ? "30px" : "10px")};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.taupeGray};
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    font-weight: bold;
    display: ${(props) => (props.isAgent ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .message-content {
    max-width: 65%;
    background-color: ${(props) => (props.isAgent ? props.theme.colors.white : props.theme.colors.culturedGray)};
    color: ${(props) => props.theme.colors.darkCharcoal};
    padding: 0.75rem 1rem;
    border-radius: 6px;
    font-size: 12px;
    display: flex;
    flex-direction: column;

    .message-header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .icon {
        margin: 0 8px;
        flex-shrink: 0;
      }

      .sender-name {
        font-size: 12px;
        font-weight: bold;
        position: relative;
      }

      ${(props) => props.isAgent && `
        &:hover::after {
          content: attr(data-tooltip);
          position: absolute;
          top: -30px;
          left: 15%;
          transform: translateX(-50%);
          background-color: ${props.theme.colors.black};
          color: ${props.theme.colors.white};
          font-size: 10px;
          padding: 4px 8px;
          border-radius: 4px;
          white-space: nowrap;
          z-index: 10;
        }
      `}
    }

    .message-body {
      font-size: 12px;
      color: ${(props) => props.theme.colors.darkCharcoal};
    }

    .time-stamp {
      font-size: 10px;
      color: ${(props) => props.theme.colors.taupeGray};
      text-align: right;
      margin-top: 0.5rem;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.taupeGray};
    color: ${(props) => props.theme.colors.white};
    font-size: 12px;
    font-weight: bold;
    display: ${(props) => (props.isAgent ? "none" : "flex")};
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
`;

export const CollapsableWrapper = styled.div`
  margin: 1rem 0;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  font-family: ${(props) => props.theme.fonts.family.inter};
  width: 100%;

  .email-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    cursor: pointer;

    .icon {
      margin-right: 8px;
      flex-shrink: 0;
    }

    .email-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: ${(props) => props.theme.colors.white};
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      flex-shrink: 0;
    }

    &:hover::after {
      content: attr(data-tooltip);
      position: absolute;
      top: -10%;
      left: 10%;
      background-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};
      font-size: 10px;
      padding: 4px 8px;
      border-radius: 4px;
      white-space: nowrap;
      z-index: 10;
    }

    .email-icon {
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }

    .sender-info {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: ${(props) => props.theme.colors.darkCharcoal};
    }

    .email-date {
      font-size: 12px;
      color: ${(props) => props.theme.colors.taupeGray};
      margin-left: 20%;
    }

    button {
      background: none;
      border: none;
      font-size: 12px;
      cursor: pointer;
      color: ${(props) => props.theme.colors.darkCharcoal};
    }
  }

  .email-body {
    padding: 1rem;
    font-size: 12px;
    color: ${(props) => props.theme.colors.darkCharcoal};
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    line-height: 1.4;

    &.collapsed {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }

    &.collapsed::after {
      content: "";
      display: block;
      height: 1.4em;
      background: ${(props) => props.theme.colors.white};
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
`;

export const ErrorMessage = styled.div`
  & p{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
`;

export const DateSenderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

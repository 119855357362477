
  
export const RightTab = {
  Rules: 0,
  Contacts: 1,
};

export const tabs = [
  { label: "General" },
  { label: "Rules & Contacts" },
  { label: "Actions" },
];

export const rightTabs = [
  { label: "Rules" },
  { label: "Contacts" },
];

export const options = [
  { name: "Contacts", value: "contacts" },
  { name: "Vehicles", value: "vehicles" },
  { name: "TCC", value: "TCC" },
  { name: "CDK", value: "CDK" },
];
  